import { Row, Col, Select } from "antd";
import {
  paginationLeftIcon,
  paginationRightIcon,
} from "../svg/paginationIcons";
import "../pagination/pagination.css";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import {
  setCurrentPage,
  setItemsPerPage,
} from "../../../redux/reducers/tableSlice";

const ITEMS_PER_PAGE = "Items per page:";
const PaginationComponent = () => {
  const dispatch = useAppDispatch();
  const {
    itemsPerPage,
    pageOptions,
    data,
    currentPage,
    itemsPerPageOptions,
    allData,
  } = useAppSelector((state: any) => state.tableDetails) || {};

  const handleItemsPerPageChange = (value: any) => {
    dispatch(setItemsPerPage(value));
  };
  const total = data.length;

  const displayedItemCount = Math.min(
    itemsPerPage * parseInt(currentPage),
    total
  );
  const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;

  const handlePageChange = (value: any) => {
    dispatch(setCurrentPage(value));
  };

  const goToPreviousPage = () => {
    const previousPage = Number(currentPage) - 1;
    if (previousPage >= 1) {
      handlePageChange(previousPage);
    }
  };

  const goToNextPage = () => {
    const nextPage = Number(currentPage) + 1;
    if (nextPage <= pageOptions.length) {
      handlePageChange(nextPage);
    }
  };

  return (
    <div className="pagination-bg">
      <Row>
        <Col span={8}>
          {ITEMS_PER_PAGE}
          &nbsp;
          <Select
            value={itemsPerPage}
            options={itemsPerPageOptions.map((value: any) => ({ value }))}
            onChange={handleItemsPerPageChange}
          />
          &nbsp;
          {`${firstItemIndex} - ${displayedItemCount} of ${total} items`}
        </Col>
        <Col span={4} offset={12}>
          <Select
            value={currentPage}
            options={pageOptions.map((value: any) => ({ value }))}
            onChange={handlePageChange}
          />
          &nbsp;
          {`of ${Math.ceil(allData.length / itemsPerPage)} pages`}
          <span className="parent_pagination_icons">
            <span
              className={`pagination_left_icon ${
                Math.ceil(currentPage) <= 1 ? "disabled" : ""
              }`}
              onClick={goToPreviousPage}
            >
              {paginationLeftIcon()}
            </span>
            <span
              className={`pagination_right_icon ${
                currentPage >= pageOptions.length ? "disabled" : ""
              }`}
              onClick={goToNextPage}
            >
              {paginationRightIcon()}
            </span>
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default PaginationComponent;
