import { useNavigate } from "react-router-dom";
import "./index.css";
import FillButton from "../../common/button/FillButton";
import CalenderBlank from "../../../themes/assets/images/performance/ASPForm/CalendarBlank.svg";
import YearUser from "../../../themes/assets/images/performance/ASPForm/User.svg";
import YearPath from "../../../themes/assets/images/performance/ASPForm/Path.svg";
import {
  ASP_EVALUATION_YEAR_CURRENTLYWITH,
  ASP_EVALUATION_YEAR_CURRENTSTEP,
  ASP_EVALUATION_YEAR_DURATION,
} from "../../../constants";
import amplitude from "amplitude-js";

const GoalPreview = (props) => {
  let navigate = useNavigate();
  return (
    <div className="form_year-detail-inn">
      <h1>{props?.title}</h1>
      <div className="year_detail-cont">
        <img alt="CalenderBlank" src={CalenderBlank} />
        <div className="year_detail-data">
          <span>{ASP_EVALUATION_YEAR_DURATION}</span>
          <p>{`${props?.startDate} to ${props?.endDate}`}</p>
        </div>
      </div>
      <div className="year_detail-cont">
        <img alt="YearUser" src={YearUser} />
        <div className="year_detail-data">
          <span>{ASP_EVALUATION_YEAR_CURRENTLYWITH}</span>
          <p>{props?.currentlyWith}</p>
        </div>
      </div>
      <div className="year_detail-cont">
        <img alt="YearPath" src={YearPath} />
        <div className="year_detail-data">
          <span>{ASP_EVALUATION_YEAR_CURRENTSTEP}</span>
          <p>{props?.currentStep}</p>
        </div>
      </div>

      <FillButton
        fillBtnStyles={"custom-fill-btn"}
        fillBtnAction={() => {
          if (props?.currentStep === "Completed") {
            if (props?.isManager) {
              amplitude
                .getInstance()
                .logEvent(`Viewed Employee's ${props?.currentStep} Form`);
            } else {
              amplitude
                .getInstance()
                .logEvent(`Viewed ${props?.currentStep} Form`);
            }
          } else {
            amplitude.getInstance().logEvent(`Clicked ${props?.currentStep}`);
          }
          navigate(props?.navigationUrl);
        }}
        fillBtnTxt={props?.buttonText}
        disabled={props?.disabled}
      />
    </div>
  );
};

export default GoalPreview;
