import React, { useState } from "react";
import "./RichTextEditor.css";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

import ColorActive from "../../../themes/assets/images/svg/active/Color.svg";
import ColorInactive from "../../../themes/assets/images/svg/inactive/Color.svg";
import BoldActive from "../../../themes/assets/images/svg/active/Bold.svg";
import BoldInactive from "../../../themes/assets/images/svg/inactive/Bold.svg";
import UnderlineActive from "../../../themes/assets/images/svg/active/TextUnderline.svg";
import UnderlineInactive from "../../../themes/assets/images/svg/inactive/TextUnderline.svg";
import OrderedListActive from "../../../themes/assets/images/svg/active/OrderedList.svg";
import OrderedListInactive from "../../../themes/assets/images/svg/inactive/OrderedList.svg";
import UnorderedListActive from "../../../themes/assets/images/svg/active/UnorderedList.svg";
import UnorderedListInactive from "../../../themes/assets/images/svg/inactive/UnorderedList.svg";
import { Dropdown } from "antd";
import { CHARACTER_COUNT } from "../../../constants";

function RichTextEditor(props: any) {
  const reactQuillRef = React.useRef<any>();
  const checkCharacterCount = (event: any) => {
    const unprivilegedEditor = reactQuillRef?.current?.unprivilegedEditor;
    // if (
    //   unprivilegedEditor.getLength() > CHARACTER_COUNT &&
    //   event.key !== "Backspace"
    // )
    //   event.preventDefault();
    props?.onTextChange && props?.onTextChange(unprivilegedEditor.getLength());
  };

  const modules = {
    toolbar: [
      [
        {
          color: [
            "#000000",
            "#2B5DA9",
            "#910000",
            "#10666B",
            "#B54901",
            "#746704",
          ],
        },
      ],
      ["bold", "underline"],
      [{ list: "bullet" }, { list: "ordered" }],
    ],
  };

  return (
    <div className="text-editor">
      <ReactQuill
        theme="snow"
        modules={modules}
        onChange={props.onChange}
        value={props.value}
        placeholder="Please add your comments"
        onKeyDown={checkCharacterCount}
        ref={reactQuillRef}
      />
    </div>
  );
}

export default RichTextEditor;
