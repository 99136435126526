import React, { useState } from "react";
import { Table, Input, Button, Modal, Row, Col, Form, Select} from "antd";
import "../../common/table/table.css";
import TextArea from "antd/es/input/TextArea";
import { getContentDetailsType } from "../../../constants";


const ContentTablecard = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [order,setOrder] = useState('');
    const [body,setBody] =useState('');
    const [type,setType] =useState('');


const showModal = () => {
  setIsModalOpen(true);
};

const handleOk = () => {
  handleInput();
  setIsModalOpen(false);
};

const handleCancel = () => {
  setIsModalOpen(false);
};

const dataSource = [
  {
      key: '1',
      Order: 'John',
      Type: 42,
      ContentBody: '10 Downing Street',
      Status: 'completed'
  },
  {
    key: '2',
    Order: 'John',
    Type: 42,
    ContentBody: '10 Downing Street',
    Status: 'completed'
  },
];

const [contentList, setcontentList] = useState<any []>(dataSource);

  const columns = [
    {
      title: 'Order',
      dataIndex: 'Order',
      key: 'Order',
    },
    {
      title: 'Type',
      dataIndex: 'Type',
      key: 'Type',
    },
    {
      title: 'Content Body',
      dataIndex: 'ContentBody',
      key: 'ContentBody',
    },
    {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
      },
      {
        title: <Button type="primary" onClick={showModal}>ADD NEW</Button>,
        dataIndex: 'Status',
        key: 'Status',
      },
  ];
  const [form] = Form.useForm();
  const onChange = (value: string) => {
    // switch (value) {
    //   case 'male':
    //     form.setFieldsValue({ note: 'Hi, man!' });
    //     break;
    //   case 'female':
    //     form.setFieldsValue({ note: 'Hi, lady!' });
    //     break;
    //   case 'other':
    //     form.setFieldsValue({ note: 'Hi there!' });
    //     break;
    //   default:
    // }
    setType(value);
    console.log(`selected ${value}`);

  };

  const onFinish = (values: any) => {
    console.log(values);
  };

  const handleInput = () => {
   const newContent = {
      key: contentList.length+1,
      Order: order,
      Type: type,
      ContentBody: body,
      Status: 'Inprogress'
  }
    setcontentList([...contentList, newContent])
  };

    return (
    <>
    <Table dataSource={contentList} columns={columns} />
    <Modal title="Content Details Form" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
    <div className="modal-spacing">
      <Form
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      className='myspace_content'
    >
    <Row>
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
    <div className='custom-form-group'>
    <Form.Item name="type" label="Type" rules={[{ required: true }]}>
        <Select
          placeholder="Select a type"
          onChange={onChange}
          options={getContentDetailsType()}
          allowClear
        >
        </Select>
      </Form.Item>
      </div>
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
    <div className='custom-form-group'>
    <Form.Item name="Order" label="Order"  rules={[{ required: true }]}>
        <Input onChange={e => setOrder(e.target.value)} />
      </Form.Item>
      </div>
    </Col>
    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
    <div className='custom-text-area custom-form-group'>
    <Form.Item name="Body" label="Body" rules={[{ required: true }]}>
    <TextArea rows={36} onChange={e => setBody(e.target.value)} />
      </Form.Item>
      </div>
    </Col>
    </Row>
    </Form>
    </div>
      </Modal>
      </>
    )};

export default ContentTablecard;
