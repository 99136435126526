import React, { useEffect, useState, useRef } from "react";
import { Form, Input, DatePicker, Select, Popconfirm, Upload, Tag } from "antd";
import dayjs from "dayjs";
import "./Certification.css";
import { InfoCircleOutlined } from "@ant-design/icons";
import { CANCEL, CERTIFICATION, CERTIFICATION_DESCRIPTION_LABEL, CERTIFICATION_DATE_FORMAT, CERTIFICATION_DESCRIPTION_PLACEHOLDER, CERTIFICATION_DESCRIPTION_TOOLTIP, CERTIFICATION_ERROR_MESSAGE, CERTIFICATION_LABEL, CERTIFICATION_LABEL_TOOLTIP, CERTIFICATION_PLACEHOLDER, DELETE, DELETE_MESSAGE, DELETE_TITLE, EFFECTIVE_DATE_LABEL, EFFECTIVE_DATE_TOOLTIP, EXPIRY_DATE_LABEL, EXPIRY_DATE_TOOLTIP, INSTITUTION_LABEL, INSTITUTION_LABEL_TOOLTIP,SKILL_TOOLTIP, INSTITUTION_PLACEHOLDER, DOCUMENT_UPLOAD, CERTIFICATION_SKILLS, FILE_SUPPORTED_FORMATS, SUPPORTED_FILE, THOUSAND_TWENTY_FOUR, FILE_MB, FILE_NOTSUPPORTED_ERROR_MESSAGE, CLEARANCE_SIZE_ERROR_MESSAGE, MAX_COUNT, FILE_TEN_MB, CERTIFICATION_SUPPORTED_FILE, CERTIFICATION_SIZE_ERROR_MESSAGE, CERTIFICATE_FILE_SUPPORTED_FORMATS  } from "../../constants";
import Delete from "../../themes/assets/images/svg/Delete.svg";
import UploadIcon from "../../themes/assets/images/svg/UploadSimple.svg";
import { useAppDispatch } from "../../redux/store/redux-hooks";
import {showRemove,showDownload} from '../common/svg/svg'
import { download } from "../../redux/reducers/certification";

const { TextArea } = Input;

const RenderCertificateForm = (props: any) => {
  const { index,addcertifications,listskills} = props
  const selectRef = useRef<any>();
  const [startDate, setDate] = useState('')
  const [skillValue, setSkillValue] = useState([])
  const [fileList, setFileList] = useState<any>([]);
  const [error, setError] = useState('')
  const dispatch = useAppDispatch();


  const handleChange = (selectedSkills:any) => {
    props.fieldChange(selectedSkills,index,'skill')
    setSkillValue(selectedSkills);
    selectRef.current.blur();
  };

  const handleDeselect = (deselectedSkill: any) => {
    const updatedSkills = skillValue.filter((skill:any) => skill.title !== deselectedSkill.key);
    props.fieldChange(updatedSkills,index,'removeSkill')
    setSkillValue(updatedSkills);
  };

  const handleCategoryChange = (e: any) => {    
    props.dateChange(e,index)
    setDate(e)
  }

  useEffect(()=>{
    setDate(addcertifications[index]?.startDate)
    setSkillValue(addcertifications[index]?.skill||[])
    setFileList(addcertifications[index]?.upload||[])
  },[addcertifications])

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <img alt="UploadIcon" src={UploadIcon} />
      <div className="upload-dis" style={{ marginTop: 10 }}>Click <span>Browse</span> to upload certificates</div>
      <div className="upload-margin">Max. 10 MB, Supported formats: PDF, Doc, Docx, PNG, GIF, JPG, JPEG</div>
    </button>
  );

  const beforeUploadHandler = (file:any,index:number) =>{
    const isPNG = CERTIFICATION_SUPPORTED_FILE.indexOf(file.type) > -1;
    const isGt10MB = file.size / THOUSAND_TWENTY_FOUR / THOUSAND_TWENTY_FOUR > FILE_TEN_MB;
    setError("")
    if (!isPNG) {
      setError(FILE_NOTSUPPORTED_ERROR_MESSAGE) 
      return false;
    }        
    else if (isGt10MB) {
      setError(CERTIFICATION_SIZE_ERROR_MESSAGE)  
      return false;
    }        
    return true
  }

  const onCustomRequestHandler = async (options:any,index:number) => {     
    const { file } = options;            
    const {name:fileName,type}= file
    const isPNG = CERTIFICATION_SUPPORTED_FILE.indexOf(file.type) > -1;    
    setError("")
    let reader = new FileReader();
    reader.onload = (e:any) => {
      const base64 = e.target.result
      const split = base64.split(',') 
      const isGt10MB = split[1].length / THOUSAND_TWENTY_FOUR / THOUSAND_TWENTY_FOUR > FILE_TEN_MB;
      if(!isPNG){
        setError(FILE_NOTSUPPORTED_ERROR_MESSAGE)
        props.fieldChange(file,index,'addimage',split[1],fileName,type,true) 
      } 
      else if(isGt10MB){
        props.fieldChange(file,index,'addimage',split[1],fileName,type,true)
        setError(CLEARANCE_SIZE_ERROR_MESSAGE)          
      } 
      else{
        props.fieldChange(file,index,'addimage',split[1],fileName,type,false)
        setFileList([file]);      
      }
      
    };
    reader.readAsDataURL(file); 
  }

const onRemoveHandler = (file:any,index:number) =>{
  props.fieldChange([],index,'removeimage','','') 
  setFileList([])
}

const onDownloadHandler = (attachment:any) => {
 dispatch(download(attachment))

}
  return (
    <div className="certify_main-otr-sec">
      <div className="certify_otr-field" key={props?.index}>
        <div className="head_title">
          <h4>
            {CERTIFICATION} {props?.index + 1}:
          </h4>
          <Popconfirm
            title={DELETE_TITLE}
            description={DELETE_MESSAGE}
            okText={DELETE}
            cancelText={CANCEL}
            onCancel={props?.onCancel}
            onConfirm={() => {
              props?.remove(props?.name)
            }}
          >
            {index !== 0 && (<button type="button">
              <img alt="deleteicon" src={Delete} />
            </button>)}
          </Popconfirm>
        </div>
        <Form.Item
          {...props?.restField}
          label={CERTIFICATION_LABEL}
          name={[props?.name, "name"]}
          rules={[
            {
              message: CERTIFICATION_ERROR_MESSAGE,
              required: true,
            },
          ]}
          tooltip={{
            title: CERTIFICATION_LABEL_TOOLTIP,
            icon: <InfoCircleOutlined />,
            overlayClassName: 'custom-tooltip'
          }}
        >
          <Input placeholder={CERTIFICATION_PLACEHOLDER} />
        </Form.Item>
        <Form.Item
          label={CERTIFICATION_DESCRIPTION_LABEL}
          name={[props?.name, "description"]}
          tooltip={{
            title: CERTIFICATION_DESCRIPTION_TOOLTIP,
            icon: <InfoCircleOutlined />,
            overlayClassName: 'second-custom-tooltip'
          }}
        >
          <TextArea rows={4} placeholder={CERTIFICATION_DESCRIPTION_PLACEHOLDER} />
        </Form.Item>
        <Form.Item
          {...props?.restField}
          label={INSTITUTION_LABEL}
          className="instruct_name"
          name={[props?.name, "institution"]}
          tooltip={{
            title: INSTITUTION_LABEL_TOOLTIP,
            icon: <InfoCircleOutlined />,
            overlayClassName: 'third-custom-tooltip'
          }}
        >
          <Input placeholder={INSTITUTION_PLACEHOLDER} />
        </Form.Item>
        
      <div className="skill_dropdown-otr">
        <Form.Item
        {...props?.restField}
        label={CERTIFICATION_SKILLS}
        className="cerify_skills"
        name={[props?.name, "skill"]}
        tooltip={{
          title: SKILL_TOOLTIP,
          icon: <InfoCircleOutlined />,
          overlayClassName: 'second-custom-tooltip'
        }}
      >
        <Select
          mode="multiple"          
          maxCount={MAX_COUNT}
          style={{ width: "100%" }}
          options={listskills}
          placeholder="Search and add"
          onChange={handleChange}
          onDeselect={handleDeselect}
          value={skillValue} // Set the selected value
          notFoundContent="No Result Found"
          placement="topLeft"
          ref={selectRef}
          labelInValue
        >
        </Select>
      </Form.Item>
        {skillValue.map((skill:any) => (
          <Tag
            key={skill}
            closable
            onClose={() => {handleDeselect(skill);}}
            style={{ marginBottom: '4px' }}
          >
            {skill?.label}
          </Tag>
        ))}
      </div>
        <Form.Item
          label={EFFECTIVE_DATE_LABEL}
          className="effective-date"
          {...props?.restField}
          name={[props?.name, "startDate"]}
          tooltip={{
            title: EFFECTIVE_DATE_TOOLTIP,
            icon: <InfoCircleOutlined />,
          }}
        >
          <DatePicker
            format={CERTIFICATION_DATE_FORMAT}
            disabledDate={(current:any) => {
              let customDate = dayjs().format(CERTIFICATION_DATE_FORMAT);
              return current && current > dayjs(customDate, CERTIFICATION_DATE_FORMAT);
            }}
            onChange={(e: any) => handleCategoryChange(e)}
            inputReadOnly
          />
        </Form.Item>
        <Form.Item
          label={EXPIRY_DATE_LABEL}
          className="expirydate"
          {...props?.restField}
          name={[props?.name, "endDate"]}
          tooltip={{
            title: EXPIRY_DATE_TOOLTIP,
            icon: <InfoCircleOutlined />
          }}
        >
          <DatePicker format={CERTIFICATION_DATE_FORMAT}
            disabled={!startDate}
            disabledDate={(current:any) => {
              const customDate = startDate;
              return current && current < dayjs(customDate, CERTIFICATION_DATE_FORMAT);
            }}
            inputReadOnly
             />
        </Form.Item>
        <Form.Item
          label={DOCUMENT_UPLOAD}
          className="document-upload"
          name={[props?.name, "upload"]}
        >
        <Upload
        listType="picture-card"
        fileList={fileList}
        // beforeUpload={(file) =>beforeUploadHandler(file, index)}
        // onChange={(file) =>handleChangeHandler(file, index)}
        onRemove={(file) =>onRemoveHandler(file,index)}        
        onDownload={(file) =>onDownloadHandler(addcertifications[index])}
        maxCount={1}
        accept={CERTIFICATE_FILE_SUPPORTED_FORMATS}
        defaultFileList={fileList}
        showUploadList={{showDownloadIcon:true,showRemoveIcon: true,removeIcon:showRemove,downloadIcon:showDownload}}
        customRequest={(file)=>onCustomRequestHandler(file,index)}
      >
        {uploadButton}
      </Upload>        
      </Form.Item>
        {error && (<p className="file_error">{error}</p>)}        
        </div>
    </div>
  );
};

export default RenderCertificateForm;
