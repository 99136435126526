import { useEffect, useRef, useState } from "react";
import "./LeaderBoardScrollable.css";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store/redux-hooks";

import {
  getFilters,
  getLeaderBoardActivityHistoryFilter,
  getLeaderboardList,
} from "../../../../../redux/actions/gamification";
import AvatarProfile from "../../../../Avatar/AvatarProfile";
import {
  GAMIFICATION_API_COUNT,
  GAMIFICATION_API_COUNT_INCREASE,
  LEADERBOARD_START,
  NO_MATCH,
} from "../../../../../constants";
import AVATARIMG from "../../../../../themes/assets/images/svg/gamification_profile-pic.svg";
import coins from "../../../../../themes/assets/images/gamification/svg/Group 1000005020.svg";
import RUBY from "../../../../../themes/assets/images/gamification/png/RUBY.png";
import AMBER from "../../../../../themes/assets/images/gamification/png/AMBER.png";
import JADE from "../../../../../themes/assets/images/gamification/png/JADE.png";
import TURQUIOSE from "../../../../../themes/assets/images/gamification/png/TURQUIOSE.png";
import SAPPHIRE from "../../../../../themes/assets/images/gamification/png/SAPPHIRE.png";
import LeaderTile from "../LeaderTile";
import useOnScreen from "../../../../common/useOnScreen";
import {
  setEmptyLeaderboardData,
  setLeaderBoardSelectedUSer,
} from "../../../../../redux/reducers/gamification";
import { LeaderBoardDetailSkeleton } from "./Profile";
import NoDataFound from "../../../../../themes/assets/images/not-found.png";

const LeaderBoardCard = ({
  data,
  userCard,
  onClickCard,
  selected,
  id = "empty",
}) => {
  const Club_Images = {
    "Ruby Club": RUBY,
    "Jade Club": JADE,
    "Amber Club": AMBER,
    "Turquoise Club": TURQUIOSE,
    "Sapphire Club": SAPPHIRE,
  };

  const specialClubStyling = {
    "Ruby Club": "ruby-background",
    "Jade Club": "jade-background",
    "Amber Club": "amber-background",
    "Turquoise Club": "turquiose-background",
    "Sapphire Club": "sapphire-background",
  };

  const cardBackground =
    userCard && data?.levels?.[0]?.levelName
      ? `leaderBoardCardContainer ${
          specialClubStyling[data?.levels?.[0]?.levelName]
        }`
      : userCard
      ? `leaderBoardCardContainer no-club-background`
      : selected
      ? "leaderBoardCardContainer non-user-card selected-card"
      : "leaderBoardCardContainer non-user-card";

  return (
    <div
      id={id === "empty" ? data?.userId : id}
      onClick={() => onClickCard(data)}
      className={cardBackground}
    >
      <div className={userCard ? "serial-num special-card-font" : "serial-num"}>
        {data?.displayRank}
      </div>
      <div className="user-name">
        <div className="avatar-container">
          <AvatarProfile
            profilePic={data?.mediaUrl}
            name={data?.displayName}
            size={40}
          />
          {data?.flagUrl && (
            <img src={data?.flagUrl} alt="" className="country-flag" />
          )}
        </div>

        <div
          className={
            userCard ? "user-name-text  special-card-font" : "user-name-text"
          }
        >
          {data?.displayName}
        </div>
      </div>
      <div className="club-data-wrapper">
        {data?.levels?.[0]?.levelName && (
          <div
            className={userCard ? "club-data club-container-sp" : "club-data"}
          >
            {data?.levels?.[0] && (
              <>
                <div
                  className={
                    userCard ? "club-name  special-card-font" : "club-name"
                  }
                >
                  {data?.levels?.[0]?.levelName}
                </div>
                <img
                  src={Club_Images?.[data?.levels?.[0]?.levelName]}
                  alt=""
                  className="club-icon"
                />
              </>
            )}
          </div>
        )}
      </div>
      <div
        className={
          userCard
            ? "points-container border-color-special"
            : "points-container"
        }
      >
        <div
          className={userCard ? "point-text  special-card-font" : "point-text"}
        >
          {data?.displayPoints}
        </div>
        <img src={coins} alt="" className="point-icon" />
      </div>
    </div>
  );
};

const LeaderboardScrollable = () => {
  let start = 0;
  let element: any = "";
  const dispatch = useAppDispatch();
  const observerRef = useRef<IntersectionObserver | null>(null);

  const {
    userCardData,
    leaderboardData,
    loadingProfile,
    loadingList,
    loadingMoreList,
    leaderBoardSelectedUser,
    leaderBoardFilterBody,
    loadingLearderBoardFilter,
  } = useAppSelector((state: any) => state.gamification);

  const userData = userCardData?.data?.[0];

  const [showUserCard, setShowUserCard] = useState("bottom");

  let hasNextPage = true; //flag to call next page api

  const onClickCard = (data = {}) => {
    dispatch(setLeaderBoardSelectedUSer(data));
  };

  const isScrolledIntoView = (parentElement: any) => {
    const el: any = document?.getElementById(userData?.userId);
    var rect = el?.getBoundingClientRect();

    var elemTop = rect?.top;
    var elemBottom = rect?.bottom;
    if (elemTop < parentElement?.getBoundingClientRect()?.top) {
      setShowUserCard("top");
    } else if (elemBottom > parentElement?.getBoundingClientRect()?.bottom) {
      setShowUserCard("bottom");
    } else if (rect === undefined) {
      setShowUserCard("bottom");
    } else {
      setShowUserCard("hide");
    }
  };

  useEffect(() => {
    element = document?.getElementById("leader-board-section");

    element?.addEventListener("scroll", () => {
      isScrolledIntoView(element);
    });
  }, [loadingList, loadingProfile, loadingLearderBoardFilter]);

  useEffect(() => {
    element && isScrolledIntoView(element);
  }, [element, loadingLearderBoardFilter, loadingList, loadingProfile]);

  useEffect(() => {
    start = 0;
    setShowUserCard("bottom");

    dispatch(setEmptyLeaderboardData({}));
    hasNextPage = true;

    observerRef.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (
            leaderBoardFilterBody?.account?.length > 0 ||
            leaderBoardFilterBody?.clubs?.length > 0 ||
            leaderBoardFilterBody?.country?.length > 0 ||
            leaderBoardFilterBody?.location?.length > 0 ||
            leaderBoardFilterBody?.key?.length > 0
          ) {
            hasNextPage &&
              dispatch(
                getLeaderBoardActivityHistoryFilter({
                  start: start,
                  payload: leaderBoardFilterBody,
                  count: GAMIFICATION_API_COUNT,
                })
              )
                ?.unwrap()
                ?.then((success: any) => {
                  start += GAMIFICATION_API_COUNT_INCREASE;

                  if (success?.data?.length <= 0) {
                    hasNextPage = false;
                  }
                });
          } else {
            dispatch(
              getLeaderboardList({
                start: start,
                count: GAMIFICATION_API_COUNT,
              })
            )
              ?.unwrap()
              ?.then((success: any) => {
                start += GAMIFICATION_API_COUNT_INCREASE;
                if (success?.data?.length <= 0) {
                  hasNextPage = false;
                }
              });
          }
        }
      },
      { threshold: 1.0 }
    );
  }, [leaderBoardFilterBody]);

  useEffect(() => {
    const observer = observerRef?.current;
    const elementbottom = document.querySelector("#end-of-list");
    if (elementbottom) {
      observer?.observe(elementbottom);
    }
    return () => {
      if (elementbottom) {
        observer?.unobserve(elementbottom);
      }
    };
  }, [
    loadingList,
    loadingMoreList,
    loadingProfile,
    leaderBoardFilterBody,
    loadingLearderBoardFilter,
  ]);

  if (loadingProfile || loadingList) {
    return <LeaderBoardDetailSkeleton loading={true} />;
  }

  if (loadingLearderBoardFilter) {
    return <LeaderBoardDetailSkeleton loading={true} />;
  }

  return (
    <div className="leader-board-section" id="leader-board-section">
      {showUserCard === "top" && (
        <div className="user-card-container-top">
          <LeaderBoardCard
            id="top-card"
            data={userData}
            userCard={true}
            onClickCard={onClickCard}
            selected={leaderBoardSelectedUser?.userId === userData?.userId}
          />
        </div>
      )}

      {leaderboardData?.data?.length > 0 ? (
        leaderboardData?.data?.map((item, index) => {
          return (
            <>
              <div className="card-list">
                <LeaderBoardCard
                  key={index}
                  data={item}
                  userCard={userData?.userId === item?.userId}
                  onClickCard={onClickCard}
                  selected={leaderBoardSelectedUser?.userId === item?.userId}
                />
              </div>
            </>
          );
        })
      ) : (
        <div className="not-found-container">
          <img className="not-found-img" src={NoDataFound} />
          <div className="no-matching-detail">{NO_MATCH}</div>
        </div>
      )}
      {!loadingMoreList && <div id="end-of-list" className="identifying-end" />}

      {showUserCard === "bottom" && (
        <div className="user-card-container-bottom">
          <LeaderBoardCard
            id="bottom-card"
            data={userData}
            userCard={true}
            onClickCard={onClickCard}
            selected={leaderBoardSelectedUser?.userId === userData?.userId}
          />
        </div>
      )}
    </div>
  );
};

export default LeaderboardScrollable;
