import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import n from "../../../navigation/Route.Names";
import EmptyGoal from "../../../../themes/assets/images/svg/empty-goals.svg";
import type { ColumnsType, TableProps } from "antd/es/table";
import {
  Button,
  Dropdown,
  Space,
  Select,
  Table,
  Modal,
  Checkbox,
  Input,
  notification,
  Spin,
  MenuProps,
} from "antd";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store/redux-hooks";
import MenuIcon from "../../../../themes/assets/images/svg/menu-icon.svg";
import CascadingPopImg from "../../../../themes/assets/images/performance/managerCascadinggoals.png";
import { isChecked } from "../../../utils/util";
import {
  GOAL_ACTIVITY_SUB_TITLE,
  GOAL_MAIN_PAGE_SUBTITLE1,
  GOAL_MAIN_PAGE_SUBTITLE2,
  WARNING,
  NOTIFICATION_DURATION,
  TEAMS_PERFORMANCE,
  TEAM_PERFORMANCE,
  TEAMS_GOAL,
  CASCADE_SEARCH_TXT,
  GOAL_MAIN_PAGE_MANDATORY_GOAL,
} from "../../../../constants";
import BreadcrumbComponent from "../../../common/breadcrumb/breadcrumb";
import SearchImg from "../../../../themes/assets/images/performance/Search.png";
import FillButton from "../../../common/button/FillButton";
import {
  cascadeToEmployeeList,
  getDropDownYears,
  postCasecadeGoalCommentManager,
} from "../../../../redux/actions/performance";
import { SearchOutlined } from "@ant-design/icons";
import { postCascadeToEmployeeList } from "../../../../redux/actions/performance";
import {
  notificationAction,
  previewAction,
  setActiveTeamGoalYear,
} from "../../../../redux/reducers/performance";
import {
  CloseIconManagerCascadeView,
  ToastSuccessIcon,
} from "../../../common/svg/svg";
import ExpandMore from "../../../../themes/assets/images/svg/Expand_more.svg";
import AvatarProfile from "../../../Avatar/AvatarProfile";
import amplitude from "amplitude-js";

interface DataType {
  key: number;
  name: any;
  goalName: any;
  goalDescription: string;
  startDate: string;
  endDate: string;
  outcome: string;
  weight: string;
  status: any;
}

const CascadeGoals = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { configuration, isLoading } =
    useAppSelector((state: any) => state.performance) || {};
  const goalsList =
    useAppSelector(
      (state: any) => state.performance.casecadeGoalCommentManager
    ) || [];
  const goalsEmployeeList =
    useAppSelector(
      (state: any) => state.performance.casecadeToEmployeeDetailList
    ) || [];
  const param = useParams();
  const managerView = param?.page === TEAM_PERFORMANCE;

  const [api, contextHolder] = notification?.useNotification({
    top: 0,
    maxCount: 1,
  });

  const renderStatusButton = (goalStatus, record) => {
    const { goalStatusColor, goalTextColor } = record || {};
    return (
      <Button
        style={{ backgroundColor: goalStatusColor, color: goalTextColor }}
      >
        {goalStatus}
      </Button>
    );
  };

  const [dropdownlistOptions, setDropdownListOption] = useState<any>([]);
  const [containerlistOptions, setContainerListOption] = useState<any>([]);
  const [businessselectedRowKeys, setBusinessSelectedRowKeys] = useState<
    React.Key[]
  >([]);
  const [technologyselectedRowKeys, setTechnolgySelectedRowKeys] = useState<
    React.Key[]
  >([]);
  const [peopleselectedRowKeys, setPeopleSelectedRowKeys] = useState<
    React.Key[]
  >([]);

  const [searchValue, setSearchValue] = useState<any>([]);
  const [allChecked, setAllChecked] = useState<any>(false);
  const [isCascadeOpen, setIsCascadeOpen] = useState(false);

  const { dropDownYears, teamGoalActiveYear: year } = useAppSelector(
    (state: any) => state?.performance
  );

  const handleSearch = (selectedSkills: any) => {
    setAllChecked(selectedSkills.length === dropdownlistOptions.length);
    setSearchValue(selectedSkills);
  };

  const searchHandler = (search: any) => {
    const filterList = dropdownlistOptions.filter((ele: any) =>
      ele?.CascadingCheckBoxTitle?.toLowerCase().includes(search)
    );
    if (search) {
      setContainerListOption(filterList);
    } else {
      setContainerListOption(dropdownlistOptions);
    }
  };

  const onBlurHandler = (search: any) => {
    setContainerListOption(dropdownlistOptions);
  };

  const onChange = (item: any) => {
    amplitude.getInstance().logEvent("Selected Employee");
    const filterData = searchValue.filter(
      (ele) => ele.label === item.CascadingCheckBoxTitle
    );
    const filterDataFilter = searchValue.filter(
      (ele) => ele.label !== item.CascadingCheckBoxTitle
    );
    if (filterData.length === 0) {
      setSearchValue([
        ...searchValue,
        {
          label: item.CascadingCheckBoxTitle,
          value: item.CascadingCheckboxReporteeId,
        },
      ]);
      setAllChecked(searchValue.length + 1 === dropdownlistOptions.length);
    } else {
      setAllChecked(searchValue.length - 1 === dropdownlistOptions.length);
      setSearchValue(filterDataFilter);
    }
  };

  const showCascadeModal = () => {
    amplitude.getInstance().logEvent("Clicked Cascade To Employee");
    setIsCascadeOpen(true);
  };

  const handleCascadeCancel = () => {
    setIsCascadeOpen(false);
    setSearchValue([]);
    setContainerListOption(dropdownlistOptions);
    setAllChecked(false);
  };

  const showSuccessToast = () => {
    amplitude.getInstance().logEvent("Submit Cascade Goal");
    dispatch(
      postCascadeToEmployeeList({
        year: year,
        request: overallValues,
      })
    )
      .unwrap()
      .then(() =>
        navigate(`/${n.START}/${n.MYWORK_URL}/${n.TEAM_PERFORMANCE}`)
      );
    setIsCascadeOpen(false);
  };

  const onPeopleSelectChange = (newSelectedRowKeys: React.Key[]) => {
    amplitude.getInstance().logEvent("Selected Goal to Cascade");
    setPeopleSelectedRowKeys(newSelectedRowKeys);
  };

  const onBusinessSelectChange = (newSelectedRowKeys: React.Key[]) => {
    amplitude.getInstance().logEvent("Selected Goal to Cascade");
    setBusinessSelectedRowKeys(newSelectedRowKeys);
  };

  const onTechnolgySelectChange = (newSelectedRowKeys: React.Key[]) => {
    amplitude.getInstance().logEvent("Selected Goal to Cascade");
    setTechnolgySelectedRowKeys(newSelectedRowKeys);
  };

  const finalSelectedRowKeys = [
    ...businessselectedRowKeys,
    ...technologyselectedRowKeys,
    ...peopleselectedRowKeys,
  ];

  const reporteeId = searchValue?.map((item) => {
    return item?.value;
  });

  const overallValues = {
    reporteeIds: reporteeId,
    goalIds: finalSelectedRowKeys,
  };

  const businessRowSelection = {
    ...businessselectedRowKeys,
    onChange: onBusinessSelectChange,
  };

  const peopleRowSelection = {
    ...peopleselectedRowKeys,
    onChange: onPeopleSelectChange,
  };

  const technologyRowSelection = {
    ...technologyselectedRowKeys,
    onChange: onTechnolgySelectChange,
  };

  const setGoal = (e, key) => {
    e.preventDefault();
  };

  const handleMoreClick = (e: any) => {
    if (e["key"] === "1") {
      navigate(`previewgoal/${year}`, {
        state: {
          isPreview: true,
          manager: managerView,
          isNew: true,
          goalsTabKey: null,
          reporteeId,
        },
      });
    } else {
      // navigate(`/${n.PERFORMANCE_URL}/${n.GOAL_PREVIEW_URL}`, {});
    }
  };

  useEffect(() => {
    const listOptionsList = goalsEmployeeList.map((employee) => ({
      CascadingCheckBoxImg: employee?.profilePictureImage,
      CascadingCheckBoxTitle: employee?.userName,
      CascadingCheckDes: employee?.designation,
      CascadingCheckboxReporteeId: employee?.reporteeId,
      CascadingCheckboxPictureType: employee?.profilePictureContentType,
    }));
    setDropdownListOption(listOptionsList);
    setContainerListOption(listOptionsList);
  }, [goalsEmployeeList]);

  const items = [{ key: "1", label: "View Goals" }];
  const yearData: MenuProps["items"] = dropDownYears;
  const menuProps = {
    items: items,
    onClick: handleMoreClick,
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Goal Name & Description",
      key: "goalName",
      render: (record) => (
        <div key={record?.sfGoalId}>
          <div className="inner-table-milestone">
            <span className="title-mile">{record.goalName}</span>
            <span className="cont-mile">{record.goalDescription}</span>
          </div>
        </div>
      ),
    },
    {
      title: "startDate",
      dataIndex: "startDate",
    },
    {
      title: "endDate",
      dataIndex: "endDate",
    },
    {
      title: "Outcomes",
      dataIndex: "outcome",
    },
    {
      title: "Weightage",
      dataIndex: "weight",
    },
    {
      title: "Status",
      dataIndex: "goalStatus",
      render: (record, item) => renderStatusButton(record, item),
    },
    {
      title: "Action",
      key: "action",
      render: (record) => {
        const { key = "" } = record;
        return (
          <Space size="middle">
            <Dropdown
              overlayClassName="goals"
              menu={menuProps}
              trigger={["click"]}
            >
              <a
                href="#"
                onClick={(e) => {
                  dispatch(previewAction({ viewGoal: record }));
                  setGoal(e, key);
                }}
              >
                <img src={MenuIcon} alt="MenuIcon" />
              </a>
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  const tableProps: TableProps<DataType> = {
    bordered: false,
    showHeader: true,
    pagination: false,
  };

  const breadbrumbItems = [
    {
      title: (
        <Link
          to={`/${n.START}/${n.MYWORK_URL}/${n.TEAM_PERFORMANCE}`}
          className="link-highlight"
        >
          {TEAMS_GOAL}
        </Link>
      ),
    },
    {
      title: "Cascade Goal",
    },
  ];

  const onAllChange = () => {
    amplitude.getInstance().logEvent("Selected Employee");
    const filter = dropdownlistOptions.map((item) => ({
      label: item.CascadingCheckBoxTitle,
      value: item.CascadingCheckboxReporteeId,
    }));
    if (searchValue.length >= 1) {
      setSearchValue(filter);
      setAllChecked(true);
    }
    if (searchValue.length === 0) {
      setAllChecked(true);
      setSearchValue(filter);
    }
    if (searchValue.length === dropdownlistOptions.length) {
      setAllChecked(!allChecked);
      if (!allChecked) {
        setSearchValue(filter);
      } else {
        setSearchValue([]);
      }
    }
  };

  useEffect(() => {
    dispatch(
      cascadeToEmployeeList({
        year: year,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      postCasecadeGoalCommentManager({
        year: year,
      })
    );
  }, [year]);

  const cancelCascadeHandler = () => {
    setBusinessSelectedRowKeys([]);
    setTechnolgySelectedRowKeys([]);
    setPeopleSelectedRowKeys([]);
  };
  const onCloseHandler = () => {
    dispatch(
      notificationAction({
        type: WARNING,
        message: "",
        isShowNotification: false,
      })
    );
    setBusinessSelectedRowKeys([]);
    setTechnolgySelectedRowKeys([]);
    setPeopleSelectedRowKeys([]);
  };

  const handleYearClick: MenuProps["onClick"] = (e) => {
    dispatch(setActiveTeamGoalYear(e.key));
  };

  const menuYear = {
    items: yearData,
    onClick: handleYearClick,
    selectable: true,
    defaultSelectedKeys: [year],
  };

  useEffect(() => {
    if (configuration.isShowNotification) {
      const type = configuration.alertMessage.type;
      api[type]({
        message: ``,
        description: configuration.alertMessage.message,
        placement: "topRight",
        duration: NOTIFICATION_DURATION,
        icon: <ToastSuccessIcon />,
        className: "custom-toast-area custom-success-toast",
        onClose: onCloseHandler,
      });
      setSearchValue([]);
    }
  }, [configuration.isShowNotification]);

  useEffect(() => {
    dispatch(getDropDownYears({}));
  }, []);

  return (
    <>
      <div className="manager-view-container">
        {contextHolder}
        <div className="empDetialBreadcrumb">
          <BreadcrumbComponent items={breadbrumbItems} />
        </div>
        <div className="performance-header-empDetail">
          <div className="left-side">
            <div className="detail-username">Cascade Goal</div>
          </div>
          <Dropdown menu={menuYear} trigger={["click"]}>
            <a
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <Space className="year-selection">
                {year}
                <img src={ExpandMore} alt="" />
              </Space>
            </a>
          </Dropdown>
        </div>
        <Spin spinning={isLoading}>
          <div className="cascade-third-section">
            <p>Select the goals to assign employee as cascaded goals </p>
            <div className="cascade-card-list">
              {(businessselectedRowKeys.length > 0 ||
                technologyselectedRowKeys.length > 0 ||
                peopleselectedRowKeys.length > 0) && (
                <div className="selected-goals-details">
                  <div className="selected-goals-count">
                    {businessselectedRowKeys.length +
                      technologyselectedRowKeys.length +
                      peopleselectedRowKeys.length}{" "}
                    goals selected
                  </div>
                  <div className="selected-goals-action">
                    <Button
                      className="secondary-btn"
                      onClick={cancelCascadeHandler}
                    >
                      Cancel
                    </Button>
                    <Button className="primary-btn" onClick={showCascadeModal}>
                      Cascade To
                    </Button>
                  </div>
                </div>
              )}
              <div className="cascading-goal-card business">
                <h5 className="title">{GOAL_ACTIVITY_SUB_TITLE}</h5>
                {goalsList?.business?.length > 0 ? (
                  <div>
                    <Table
                      {...tableProps}
                      columns={columns}
                      dataSource={goalsList?.business}
                      rowKey={(record: any) =>
                        record?.goalId ? record?.goalId : record?.sfGoalId
                      }
                      rowSelection={{
                        ...businessRowSelection,
                        selectedRowKeys: businessselectedRowKeys,
                        onChange: onBusinessSelectChange,
                      }}
                      className="tbl-outer-section"
                    />
                  </div>
                ) : (
                  <div className="empty-goals">
                    <img src={EmptyGoal} alt="" />
                    <span>{GOAL_MAIN_PAGE_MANDATORY_GOAL}</span>
                  </div>
                )}
              </div>
              <div className="cascading-goal-card business">
                <h5 className="title">{GOAL_MAIN_PAGE_SUBTITLE1}</h5>
                {goalsList?.technology?.length > 0 ? (
                  <Table
                    {...tableProps}
                    columns={columns}
                    dataSource={goalsList?.technology}
                    rowKey={(record: any) =>
                      record?.goalId ? record?.goalId : record?.sfGoalId
                    }
                    rowSelection={{
                      ...technologyRowSelection,
                      selectedRowKeys: technologyselectedRowKeys,
                      onChange: onTechnolgySelectChange,
                    }}
                    className="tbl-outer-section"
                  />
                ) : (
                  <div className="empty-goals">
                    <img src={EmptyGoal} alt="" />
                    <span>{GOAL_MAIN_PAGE_MANDATORY_GOAL}</span>
                  </div>
                )}
              </div>
              <div className="cascading-goal-card business">
                <h5 className="title">{GOAL_MAIN_PAGE_SUBTITLE2}</h5>
                {goalsList?.people?.length > 0 ? (
                  <Table
                    {...tableProps}
                    columns={columns}
                    dataSource={goalsList?.people}
                    rowSelection={{
                      ...peopleRowSelection,
                      selectedRowKeys: peopleselectedRowKeys,
                      onChange: onPeopleSelectChange,
                    }}
                    rowKey={(record: any) =>
                      record?.goalId ? record?.goalId : record?.sfGoalId
                    }
                    className="tbl-outer-section"
                  />
                ) : (
                  <div className="empty-goals">
                    <img src={EmptyGoal} alt="" />
                    <span>{GOAL_MAIN_PAGE_MANDATORY_GOAL}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Spin>
      </div>

      <Modal
        title="Cascade To"
        open={isCascadeOpen}
        onCancel={handleCascadeCancel}
        footer={false}
        maskClosable={false}
        centered={true}
        className="manager-cascade-popup"
      >
        <div className="manager-search-area">
          <Space
            style={{ width: "100%", maxHeight: "80px" }}
            direction="vertical"
          >
            <Select
              mode="multiple"
              style={{ width: "100%", maxHeight: "80px", overflowY: "auto" }}
              placeholder={CASCADE_SEARCH_TXT}
              onChange={handleSearch}
              options={dropdownlistOptions.map((item) => ({
                label: item.CascadingCheckBoxTitle,
                value: item.CascadingCheckboxReporteeId,
              }))}
              value={searchValue}
              labelInValue
              onSearch={searchHandler}
              onBlur={onBlurHandler}
              suffixIcon={<SearchOutlined />}
              removeIcon={<CloseIconManagerCascadeView />}
              className="managercascade-multipleselect"
              popupClassName="display-none"
            />
          </Space>
          <FillButton
            fillBtnTxt="Cascade"
            disabled={searchValue.length === 0 ? true : false}
            fillBtnStyles="cascadeBtn"
            fillBtnAction={showSuccessToast}
          />
        </div>
        <div className="select-all-check-box">
          <div className="select-employee-list">Select Employee</div>

          <Checkbox name="all" checked={allChecked} onChange={onAllChange}>
            {allChecked ? "Unselect All" : "Select All"}
          </Checkbox>
        </div>
        <div className="cascading-profile-list">
          <div>
            {containerlistOptions.map((item) => (
              <div className="cascade-checkbox-area">
                <Checkbox
                  key={item.id}
                  checked={isChecked(searchValue, item.CascadingCheckBoxTitle)}
                  onChange={() => onChange(item)}
                >
                  <div className="cascading-profile">
                    {item.CascadingCheckBoxImg ? (
                      <div className="cascading-profile-img">
                        <img
                          src={`data:${item?.CascadingCheckboxPictureType};base64,${item?.CascadingCheckBoxImg}`}
                        />
                      </div>
                    ) : (
                      item.CascadingCheckBoxTitle && (
                        <div className="avatar-container">
                          <AvatarProfile
                            name={item.CascadingCheckBoxTitle}
                            size={47}
                            profilePic={""}
                          />
                        </div>
                      )
                    )}
                    <div className="cascading-profile-text">
                      <div className="cascading-profile-title">
                        {item.CascadingCheckBoxTitle}
                      </div>
                      <div className="cascading-profile-desc">
                        {item.CascadingCheckDes}
                      </div>
                    </div>
                  </div>
                </Checkbox>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CascadeGoals;
