import { createSlice } from "@reduxjs/toolkit";
import { getStoryDetail, reactions } from "../actions/story_details";

type cleranceDetailState = {
  story_detail: any;
  loading: boolean;
  error: null;
  showComment: boolean;
};

const initialDetailState: cleranceDetailState = {
  story_detail: {},
  loading: false,
  error: null,
  showComment: false,
};

export const story_details: any = createSlice({
  name: "story_details",
  initialState: initialDetailState,
  reducers: {
    cardData: (state, action) => {
      state.story_detail = action.payload;
    },
    isCommentShow: (state, action) => {
      state.showComment = !state.showComment;
    },
    setShowCommentTrue: (state, action) => {
      state.showComment = true;
    },
    bookmarkToggle: (state, action) => {
      const isbookMark = state.story_detail.content.bookMarkIndicator;
      state.story_detail.content.bookMarkIndicator = !isbookMark;
    },
    likesAction: (state, action) => {
      const { index } = action.payload;
      const isLike = state.story_detail["details"][index]["likesCount"];
      state.story_detail["details"][index]["likesCount"] = isLike + 1;
    },
    commentAction: (state, action) => {
      state.story_detail.content.commentsCount += 1;
    },
    handleComment: (state, action) => {
      try {
        const { addComment } = action.payload;
        const commentCount = state.story_detail.content.commentsCount;
        if (addComment) {
          state.story_detail.content.commentsCount = commentCount + 1;
        } else {
          state.story_detail.content.commentsCount = commentCount - 1;
        }
      } catch (e) {
        console.log("handled error", e);
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getStoryDetail.pending, (state) => {
      state.loading = true;
      state.story_detail = {};
    });
    builder.addCase(getStoryDetail.fulfilled, (state: any, action) => {
      state.loading = false;
      state.story_detail = action.payload;
    });
    builder.addCase(getStoryDetail.rejected, (state: any, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(reactions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(reactions.fulfilled, (state: any, action) => {
      state.loading = false;
      state.story_detail = action.payload;
    });
    builder.addCase(reactions.rejected, (state: any, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default story_details.reducer;

export const {
  cardData,
  isCommentShow,
  commentAction,
  bookmarkToggle,
  likesAction,
  handleComment,
  setShowCommentTrue,
} = story_details.actions;
