import React, { useState } from "react";
import { Tabs, Modal } from "antd";
import Tablecard from "../common/table/table";
import type { TabsProps } from "antd";
import SearchBar from "../SearchBar/SearchBar";
import "./my_work.css";
import Performance from "../Performance";
import CalendarComponent from "../integrated-calendar/calendar/DexCalendar";
import { useAppDispatch, useAppSelector } from "../../redux/store/redux-hooks";
// import tableContentData from "../common/table/content.json";
// import surveyQuestion from "../common/table/original_survery.json";
import { getAdmin, getAllSurveyDetails } from "../../redux/actions/admin";
import EventPage from "../eventPage/eventPage";
import { CALENDAR, MYWORK_URL, START } from "../../constants";
import LeftSideLayout from "../layouts/LeftSideLayout";
import SeparationScreen from "../separation/separation-landing/SeparationLanding";
import { useNavigate, useParams } from "react-router-dom";
import GoalsActivities from "../Goals/GoalActivities";
import QuarterlyCheckInGoals from "../Goals/QuarterlyCheckInGoals";
import Card from "../common/card";
import tempMyWorkData from "./MyworkSubheaderData";
import TeamPerformance from "../Performance/ManagerView";
import TeamsQuarterlyCheckin from "../Performance/ManagerView/QuarterlyCheckin";

const MyWork = () => {
  const params = useParams();
  const navigation = useNavigate();
  let { page = "approvals" } = params;
  page = page?.toLocaleLowerCase();
  const [modal, contextHolder] = Modal.useModal();
  const dispatch = useAppDispatch();

  // The activeCategory represents  subheader

  const webLanding = useAppSelector((state: any) => state.webLanding);
  const { quickMenuExpanded } = webLanding;
  const listTabs = tempMyWorkData?.webIconInfos?.navigationTab || [];

  const alertModal = () => {
    const response = {
      id: "6524255a3bd33f2514f453d2",
      userId: "6501",
      remarks: "Content successfully posted in UAT.",
      referenceId: "6524255a3bd33f2514f453ce",
      action: "CREATE",
      date: "2023-10-09T16:07:54.403+00:00",
      status: "ACTIVE",
      category: "CONTENT",
      uatNotificatioLog: null,
      prodNotificatioLog: null,
    };
  };

  const AdminPanel = () => {
    const [activeSubTab, setActiveSubTab] = useState("contents");

    const items: TabsProps["items"] = [
      {
        key: "contents",
        label: <label className="tabs-label">Contents</label>,
        children: <Tablecard currentTab="contents" />,
      },
      {
        key: "questions",
        label: <label className="tabs-label">Survey Question</label>,
        children: <Tablecard currentTab="questions" />,
      },
      {
        key: "calendar",
        label: <label className="tabs-label">Calendars</label>,
        children: <CalendarComponent />,
      },
    ];

    const onChange = (key: any) => {
      setActiveSubTab(key);
      if (key === "contents") {
        dispatch(getAdmin({}));
      } else if (key === "questions") {
        dispatch(getAllSurveyDetails({}));
      }
    };

    return (
      <div className="tabs-table-parent">
        <div className="tabs-content">
          <Tabs
            defaultActiveKey={items[0]?.key}
            activeKey={activeSubTab}
            type="card"
            items={items || []}
            className="tabs-styles"
            tabBarExtraContent={<SearchBar currentTab={activeSubTab} />}
            onChange={onChange}
          />
        </div>
      </div>
    );
  };

  const CalendarComp = () => {
    return (
      <>
        <h1 className="calendar-tab-title">{CALENDAR}</h1>
        <button
          className="table-addnew-btn calendar-pop-event"
          onClick={() => {
            modal.confirm({
              title: "Create Event",
              icon: null,
              closable: true,
              onOk: alertModal,
              content: <EventPage />,
              okText: "Create Event",
              cancelText: "Cancel",
            });
          }}
        >
          Create Event
        </button>
        <div className="mywork-calendar">
          <CalendarComponent />
          {contextHolder}
        </div>
      </>
    );
  };

  const ComingSoonComponent = () => {
    return (
      <div
        //going with inline styles as it is a temporary component
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "140px",
          height: "70vh",
        }}
      >
        Coming Soon
      </div>
    );
  };

  const renderMyWorkComponents = {
    admin_panel: <AdminPanel />,
    approvals: <ComingSoonComponent />,
    team_performance: <TeamPerformance />,
    calendar: <CalendarComp />,
    separation: <SeparationScreen />,
    quaterlycheckin: <QuarterlyCheckInGoals />,
    goals: <GoalsActivities />,
    team_quarterly_checkin: <TeamsQuarterlyCheckin />,
  };

  return (
    <>
      {/* Coming soon...<LeftSideLayout /> */}
      <div className={quickMenuExpanded ? "menuExpanded" : "menuCollapsed"}>
        {renderMyWorkComponents?.[page] ? (
          renderMyWorkComponents?.[page]
        ) : (
          <div className="mywork-coming-soon">Coming soon...</div>
        )}
        {/* {(page === "sodexo" ||
          page === "travel-expense" ||
          page === "timesheet") && (
          <div className="mywork-coming-soon">Coming soon...</div>
        )} */}
      </div>
    </>
  );
};

export default MyWork;
