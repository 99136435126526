import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import n from "../navigation/Route.Names";
import "./certificationStyle.css";
import EmptyImage from "../../themes/assets/images/svg/bonds.svg";
import AddImage from "../../themes/assets/images/svg/add.svg";
import { Spin } from "antd";
import {
  ADD_CERTIFICATION,
  CERTIFICATE_DELETE_SUB_TITLE,
  CERTIFICATE_DELETE_TITLE,
  CERTIFICATE_UPDATE_SUB_TITLE,
  CERTIFICATE_UPDATE_TITLE,
  CERTIFICATIONS,
  NO_CERTIFICATE,
  NO_CERTIFICATE_TITLE,
} from "../../constants";
import styles from "./MainCertification.module.css";
import AddCertificateButton from "../common/button/AddCertificateButton";
import CertificateList from "../common/CertificateList/CertificateList";
import { useAppSelector, useAppDispatch } from "../../redux/store/redux-hooks";
import {
  getCertifications,
  getSkills,
} from "../../redux/actions/certification";
import { notification as ToastMessage } from "antd";
import { ToastSuccessIcon } from "../common/svg/svg";
import { closeNotification } from "../../redux/reducers/certification";

function Certification() {
  const [api, contextHolder] = ToastMessage.useNotification({
    top: 64,
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const Certification = useAppSelector((state: any) => state.certification);
  const {
    listcertifications = [],
    loading,
    reload,
    listskills,
    isUpdated,
    isDeleted,
  } = Certification;
  const isShowEmpty = listcertifications.length === 0;

  const addCertificate = () => {
    navigate(`/${n.START}/${n.ADD_CERTIFICATION_URL}`);
  };
  useEffect(() => {
    if (listskills.length === 0) {
      dispatch(getSkills({}));
    }
    if (listcertifications.length === 0 || reload) {
      dispatch(getCertifications({}));
    }
  }, []);

  const onClose = () => {
    dispatch(closeNotification({}));
  };

  useEffect(() => {
    api.open({
      message: isUpdated
        ? CERTIFICATE_UPDATE_TITLE
        : isDeleted
        ? CERTIFICATE_DELETE_TITLE
        : "",
      description: isUpdated
        ? CERTIFICATE_UPDATE_SUB_TITLE
        : isDeleted
        ? CERTIFICATE_DELETE_SUB_TITLE
        : "",
      duration: 3,
      className: "success-Toast",
      icon: <ToastSuccessIcon />,
      onClose: onClose,
    });
  }, [isUpdated, isDeleted]);

  return (
    <>
      <>{(isUpdated || isDeleted) && contextHolder}</>
      <Spin spinning={loading} delay={500}>
        <div className="certify_main-otr">
          {isShowEmpty ? (
            <>
              <div className="Seperation-page-title">{CERTIFICATIONS}</div>
              <div className={styles.fixedHeight}>
                {/*<div className="certificate-toaster">
                                    <img src={InfoImage} />
                                    <span>{NEXT_TIME}</span>
                                </div>*/}
                <div className="certification-emptypage">
                  <div className="certification-emptypage-image">
                    <img src={EmptyImage} alt="empty certification" />
                  </div>
                  <div className="certification-emptypage-title">
                    {NO_CERTIFICATE_TITLE}
                  </div>
                  <div className="certification-emptypage-text">
                    {NO_CERTIFICATE}
                  </div>
                  <div className="certification-addbutton">
                    <AddCertificateButton
                      addCertificateAction={addCertificate}
                      addCertificateImage={AddImage}
                      addCertificateTxt={ADD_CERTIFICATION}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="certifications-header">
                <div className="Seperation-page-title">{CERTIFICATIONS}</div>
                <div className="certification-addbutton">
                  <AddCertificateButton
                    addCertificateAction={addCertificate}
                    addCertificateImage={AddImage}
                    addCertificateTxt={ADD_CERTIFICATION}
                  />
                </div>
              </div>
              <div className="certification-card">
                <div className="certification-cardinner">
                  {/*<NotificationMessage />*/}
                  <CertificateList
                    isEdit={true}
                    certifications={listcertifications}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Spin>
    </>
  );
}

export default Certification;
