import { createAsyncThunk } from "@reduxjs/toolkit";
import calendar from "../services/calendar.js";

//holiday action
export const getHoliday = createAsyncThunk(
  "getHoliday",
  async (params: any = {}, { rejectWithValue }) => {
    const {activekey,country,city} = params
    let url =`api/v1/holiday-calendar/v2?isWEB=TRUE&tag=${activekey}`
    if(country){
      url +=`&countryCode=${country}`
    }
    if(city){
      url +=`&locationCode=${city}`
    }
    const response = await calendar.get(url);
    try {
      const result = response.data.body;
      return result
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get tab
export const getTab = createAsyncThunk(
  "getTab",
  async (params: any = {}, { rejectWithValue }) => {
    const {activekey} = params
    const url = `api/v1/common/taglist?key=HOLIDAY_CALENDAR_TAGLIST`;
    const response = await calendar.get(url, {
      params,
    });
    try {
      const result = response.data;
      return result
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get country
export const getCountry = createAsyncThunk(
  "getCountry",
  async (params: any = {}, { rejectWithValue }) => {
    const url = `api/v1/holiday-calendar/filter?isMainFilter=true`;
    const response = await calendar.get(url);
    try {
      const result = response.data;
      return result
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get city
export const getCity = createAsyncThunk(
  "getCity",
  async (params: any = {}, { rejectWithValue }) => {
    const {country} = params
    const url = `api/v1/holiday-calendar/filter?isMainFilter=false&countryCode=${country}`;
    const response = await calendar.get(url);
    try {
      const result = response.data;
      return result
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
