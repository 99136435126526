import { Button, Skeleton } from "antd";
import ebookone from "../../themes/assets/images/book-1.png";
import { useEffect, useState } from "react";
import ModalDialog from "../common/modal/modal";
import { INDEX_POPUP_TITLE } from "../../constants";
import menuindex from "../../themes/assets/images/svg/menu_index.svg";
import "./Ebook.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  getBookSummary,
  getBooks,
  getBooksSection,
} from "../../redux/actions/ebooks";
import { useAppDispatch } from "../../redux/store/redux-hooks";
import { setInLocalStroage } from "../utils/util";
import { eBookLog } from "../../redux/actions/ebooks";
import { LazyLoadImage } from "react-lazy-load-image-component";

const EbooksImage = (props: any) => {
  return (
    <div className="ebooks-image-container">
      <Skeleton.Node
        active={props?.loading}
        className={props?.loading ? "ebooks-image" : "ebooks-shadow-removal"}
      >
        <LazyLoadImage
          onLoad={() => props?.setLoading(false)}
          src={props?.url}
          className="card-Image"
          alt="banner"
          effect="blur"
        />
      </Skeleton.Node>
    </div>
  );
};

const EbookIntroduction = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [book, setBook] = useState<any>({});
  const [imageLoading, setImageLoading] = useState(true);
  const dispatch = useAppDispatch();
  const params = useParams();
  const { code } = params;
  const navigate = useNavigate();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = (number) => {
    setIsModalOpen(false);
    setLoading(true);
    dispatch(eBookLog({ id: book?.id, activePage: number }))
      .unwrap()
      .then((data) => {
        setLoading(false);
        setInLocalStroage("activePage", number);
        navigate(`/web/altiverse/E_BOOKS/DETAILS/${code}`);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getBookSummary({ countId: 3, readNow: false, code }))
      .unwrap()
      .then((res: any) => {
        setCurrentPage(res ? res[0]["activePage"] : 1);
        setInLocalStroage("activePage", res ? res[0]["activePage"] : 1);
        setBook(res ? res[0] : {});
        setLoading(false);
      });
    dispatch(getBooksSection({ code }))
      .unwrap()
      .then((res: any) => {
        setSections(res.content.sections);
      });
  }, [code]);

  return (
    <>
      <div className="card-otr-sec book-intro-top">
        <div className="card-sec-inn">
          <EbooksImage
            loading={imageLoading}
            setLoading={setImageLoading}
            url={book?.mediaResponse?.referenceUrl?.url}
          />
          <div className="card-cont-inn">
            <h1>{book?.title}</h1>
            <span>By Loreum Ispum</span>
            <p className="sub-txt">Issue No. 010 | September Edition 2023</p>
            <p>{book?.summary}</p>
          </div>
        </div>
        <div className="foot_icons-btn">
          <Button onClick={showModal}>
            <img alt="menubar" src={menuindex} />
          </Button>
          <div className="read_btn">
            <Button
              onClick={() => {
                setInLocalStroage("activePage", currentPage);
                navigate(`/web/altiverse/E_BOOKS/DETAILS/${code}`);
              }}
            >
              {currentPage === 1 ? "Start Reading" : "Continue Reading"}
            </Button>
            {/*<Button
              className="cont_read"
              onClick={() => navigate(`/web/altiverse/E_BOOKS/DETAIL/${code}`)}
            >
              Continue Reading
            </Button>*/}
          </div>
        </div>
        <ModalDialog
          modalStyles={"page-index-popup"}
          openModal={isModalOpen}
          closeIcon={true}
          onOk={handleOk}
          onCancel={handleCancel}
          maskClosable={true}
          modalImageStyles={{ display: "none" }}
          modalHeadingStyles={{ display: "none" }}
          DialogHeading={INDEX_POPUP_TITLE}
          modalSubHeading={"index-popup-heading"}
          indexDetails={sections}
          index={currentPage}
        />
      </div>
    </>
  );
};
export default EbookIntroduction;
