export const sortingIcon = (className) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      className={className || ""}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7604 14.4405C17.9319 14.6254 18.0272 14.8683 18.0272 15.1205C18.0272 15.3727 17.9319 15.6156 17.7604 15.8005L12.7004 20.8605C12.5135 21.0437 12.2622 21.1463 12.0004 21.1463C11.7387 21.1463 11.4873 21.0437 11.3004 20.8605L6.24042 15.8005C6.06893 15.6156 5.97363 15.3727 5.97363 15.1205C5.97363 14.8683 6.06893 14.6254 6.24042 14.4405C6.42534 14.269 6.66822 14.1737 6.92042 14.1737C7.17262 14.1737 7.41551 14.269 7.60042 14.4405L12.0004 18.8405L16.4004 14.4405C16.5853 14.269 16.8282 14.1737 17.0804 14.1737C17.3326 14.1737 17.5755 14.269 17.7604 14.4405ZM12.7004 2.26047L17.7604 7.32047C17.9319 7.50538 18.0272 7.74827 18.0272 8.00047C18.0272 8.25267 17.9319 8.49555 17.7604 8.68047C17.5755 8.85196 17.3326 8.94725 17.0804 8.94725C16.8282 8.94725 16.5853 8.85196 16.4004 8.68047L12.0004 4.27047L7.60042 8.68047C7.41551 8.85196 7.17262 8.94725 6.92042 8.94725C6.66822 8.94725 6.42534 8.85196 6.24042 8.68047C6.06893 8.49555 5.97363 8.25267 5.97363 8.00047C5.97363 7.74827 6.06893 7.50538 6.24042 7.32047L11.3004 2.26047C11.4873 2.07724 11.7387 1.97461 12.0004 1.97461C12.2622 1.97461 12.5135 2.07724 12.7004 2.26047Z"
        fill="black"
      />
    </svg>
  );
};
