import React, { useState } from "react";
import { Input, Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import EventPage from "../eventPage/eventPage";
import Content from "../my_space/Content";
import EditPopUp from "../my_space/EditContentPop";
import { publishContent } from "../../redux/actions/add_content";

import {
  CALENDER_NAME,
  CALENDER_TAB,
  CONTENTS_NAME,
  CONTENTS_TAB,
  QUESTIONS_NAME,
  QUESTIONS_TAB,
} from "../../constants";

export default function SearchBar({ currentTab }: any) {
  const [modal, contextHolder] = Modal.useModal();

  const titleList: any = {
    calendar: CALENDER_NAME,
    questions: QUESTIONS_NAME,
    contents: CONTENTS_NAME,
  };
  const componentList: any = {
    calendar: <EventPage />,
    questions: <EditPopUp />,
    contents: <Content />,
  };
  const alertModal = () => {
    const response = {
      id: "6524255a3bd33f2514f453d2",
      userId: "6501",
      remarks: "Content successfully posted in UAT.",
      referenceId: "6524255a3bd33f2514f453ce",
      action: "CREATE",
      date: "2023-10-09T16:07:54.403+00:00",
      status: "ACTIVE",
      category: "CONTENT",
      uatNotificatioLog: null,
      prodNotificatioLog: null,
    };
    console.log(response);
  };

  const confirm = () => {
    modal.confirm({
      title: titleList[currentTab],
      icon: null,
      closable: true,
      onOk: alertModal,
      content: componentList[currentTab],
      okText: titleList[currentTab],
      cancelText: "Cancel",
    });
  };

  const isCalenderTab = currentTab === CALENDER_TAB;
  const isQuestionsTab = currentTab === QUESTIONS_TAB;
  const isContentsTab = currentTab === CONTENTS_TAB;
  return (
    <div className="search-add-parent">
      {/* <span>
        {!isCalenderTab && !isContentsTab && currentTab !== 1 && (
          <Input
            placeholder="Search"
            className="table-search-box"
            suffix={<SearchOutlined />}
          />
        )}
        {!isCalenderTab && !isQuestionsTab && (
          <Input
            placeholder="Filter By Contents"
            className="table-search-box"
            suffix={<SearchOutlined />}
          />
        )}
      </span> */}

      <span>
        <button className="table-addnew-btn" onClick={confirm}>
          {" "}
          {isCalenderTab
            ? "Create Event"
            : isQuestionsTab
            ? "Add New"
            : "Add New"}
        </button>
      </span>

      {contextHolder}
    </div>
  );
}
