import "./index.css";
import {
  ASP_FORM,
  ASP_LAUNCH_FORM,
  ASP_RESUME_FORM,
  ASP_SIGN_FORM,
  ASP_START_FORM,
  ASP_VIEW_FORM,
  EMPLOYEE_ASP_TITLE,
  SIGN_FORM,
  MANAGER_REVIEW,
  MANAGER_SIGNATURE,
  MYWORK_URL,
  PERFORMANCE,
  START,
  TEAMS_PERFORMANCE,
  TEAMS_GOAL,
  TEAM_PERFORMANCE,
  TEAM_YEAR_END_REVIEW_TITLE,
  VIEW_FORM,
  YEAR_END_EVALUATION_URL,
  YEAR_END_SUBTITLE,
  YEAR_END_TITLE,
  NOTIFICATION_DURATION,
  SUCCESS,
  FAILURE,
  WARNING,
  NAVIGATION_TEXT_ASP_EMPLOYEE,
  NAVIGATION_TEXT_ASP_MANAGER,
} from "../../../constants";
import n from "../../navigation/Route.Names";
import GoalPreview from "./GoalPreview";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import { useEffect, useState } from "react";
import {
  AnnualYear,
  getAspFormEmployeeDetail,
} from "../../../redux/actions/performance";
import { Dropdown, MenuProps, notification, Space, Spin } from "antd";
import ExpandMore from "../../../themes/assets/images/performance/svg/Expand_more.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BreadcrumbComponent from "../../common/breadcrumb/breadcrumb";
import YearEvaluation from "../../../themes/assets/images/performance/svg/year_evaluation.svg";
import ErrorComponent from "../../errorComponent/ErrorComponent";
import {
  notificationAction,
  setActiveTeamGoalYear,
} from "../../../redux/reducers/performance";
import {
  GoalsErrorIcon,
  GoalsInfoIcon,
  ToastSuccessIcon,
} from "../../common/svg/svg";
import Iicon from "../../../themes/assets/images/info_grey.png";

const NoYearEndFrom = () => {
  return (
    <div className="no-year">
      <img src={YearEvaluation} alt="" />
      <p className="title">{YEAR_END_TITLE}</p>
      <p className="sub-title">{YEAR_END_SUBTITLE}</p>
    </div>
  );
};

const AspForm = (props: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { reporteeId, appraisalId } = params;
  const isManager = location.pathname.includes(TEAM_PERFORMANCE);
  const {
    getAspFormEmployeeView,
    isLoading,
    yearDropdown,
    configuration,
    loadingASPform,
    teamGoalActiveYear,
    getAspFormEmployeeViewError,
  } = useAppSelector((state: any) => state.performance) || {};

  const { error = false } = configuration;

  const { years, currentYear } = yearDropdown || {};
  const [year, setYear] = useState(teamGoalActiveYear);
  const [waitingToast, setWaitingToast] = useState(false);
  const [api, contextHolder] = notification.useNotification({
    top: 0,
    maxCount: 1,
  });
  useEffect(() => {
    dispatch(AnnualYear({}));
  }, []);

  useEffect(() => {
    setYear(teamGoalActiveYear);
  }, []);

  useEffect(() => {
    if (!year) return;
    isManager
      ? dispatch(
          getAspFormEmployeeDetail({ year: year || currentYear, reporteeId })
        )
      : dispatch(
          getAspFormEmployeeDetail({ year: year || currentYear, appraisalId })
        );
  }, [year]);

  useEffect(() => {
    if (configuration.isShowNotification) {
      setWaitingToast(true);
      const type = configuration.alertMessage.type;
      api[type]({
        message:
          type === SUCCESS ? configuration?.alertMessage?.additionalInfo : "",
        description: configuration?.alertMessage?.message,
        placement: "topRight",
        duration: NOTIFICATION_DURATION,
        icon:
          type === SUCCESS ? (
            <ToastSuccessIcon />
          ) : type === FAILURE ? (
            <GoalsErrorIcon />
          ) : (
            <GoalsInfoIcon />
          ),
        className: "custom-toast-area custom-success-toast",
        onClose: onCloseHandler,
      });
    }
  }, [configuration.isShowNotification]);
  const onCloseHandler = () => {
    dispatch(
      notificationAction({
        type: WARNING,
        message: "",
        isShowNotification: false,
        additionalInfo: "",
      })
    );
  };

  const items: MenuProps["items"] = years;

  const handleYearClick: MenuProps["onClick"] = (e) => {
    const key = e.key;
    setYear(key);
    dispatch(setActiveTeamGoalYear(key));
  };
  const menuYear = {
    items,
    onClick: handleYearClick,
    selectable: true,
    defaultSelectedKeys: [year?.toString()],
  };

  const breadbrumbItems = [
    {
      title: (
        <Link
          to={`/${n.START}/${n.MYWORK_URL}/${n.TEAM_PERFORMANCE}/asp`}
          className="link-highlight"
        >
          {TEAM_YEAR_END_REVIEW_TITLE}
        </Link>
      ),
    },
    // {
    //   title: (
    //     <Link
    //       to={`/${n.START}/${n.MYWORK_URL}/${n.TEAM_PERFORMANCE}`}
    //       className="link-highlight"
    //     >
    //       {TEAMS_GOAL}
    //     </Link>
    //   ),
    // },
    // {
    //   title: (
    //     <Link
    //       to={`/${n.START}/${n.MYWORK_URL}/${n.TEAM_PERFORMANCE}`}
    //       className="link-highlight"
    //     >
    //       {"Employee List"}
    //     </Link>
    //   ),
    // },
    {
      title: getAspFormEmployeeView?.formOwner,
    },
  ];

  if (error || getAspFormEmployeeViewError) {
    return (
      <div className="performanceContainer">
        {isManager && <BreadcrumbComponent items={breadbrumbItems} />}
        {year && (
          <div className="asp-form-heading">
            <h5>
              {isManager ? TEAM_YEAR_END_REVIEW_TITLE : EMPLOYEE_ASP_TITLE}
            </h5>
            <Dropdown menu={menuYear} trigger={["click"]}>
              <a
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <Space className="year-selection">
                  {year}
                  <img src={ExpandMore} alt="" />
                </Space>
              </a>
            </Dropdown>
          </div>
        )}
        <div className="loading-asp">
          <ErrorComponent
            image={require("../../../themes/assets/images/something-went-wrong.png")}
            title={"Something Went Wrong"}
            description={
              "We are looking to fix it. Please try again in few minutes."
            }
          />
        </div>
      </div>
    );
  }

  const nagiateBasedOnButtonName = (buttonName: any, isManager: boolean) => {
    let navigationUrl = "/web";
    switch (buttonName) {
      case ASP_START_FORM:
        navigationUrl = isManager
          ? `/${START}/${MYWORK_URL}/${TEAM_PERFORMANCE}/${n.GOAL_EVALUATION_URL}/${reporteeId}/${teamGoalActiveYear}`
          : `/${n.PERFORMANCE_URL}/${n.GOAL_EVALUATION_URL}/detail/${getAspFormEmployeeView?.appraisalId}/${teamGoalActiveYear}`;
        break;
      case ASP_RESUME_FORM:
        navigationUrl = isManager
          ? `/${START}/${MYWORK_URL}/${TEAM_PERFORMANCE}/${n.GOAL_EVALUATION_URL}/${reporteeId}/${teamGoalActiveYear}`
          : `/${n.PERFORMANCE_URL}/${n.GOAL_EVALUATION_URL}/detail/${getAspFormEmployeeView?.appraisalId}/${teamGoalActiveYear}`;
        break;
      case ASP_SIGN_FORM:
        navigationUrl = isManager
          ? `/${START}/${n.MYWORK_URL}/${TEAM_PERFORMANCE}/${ASP_FORM}/${YEAR_END_EVALUATION_URL}/${getAspFormEmployeeView?.currentStep}/${reporteeId}/${teamGoalActiveYear}`
          : `/${START}/${n.MY_SPACE}/${PERFORMANCE}/AspForm/view_asp/${getAspFormEmployeeView?.appraisalId}/${teamGoalActiveYear}`;
        break;
      case ASP_LAUNCH_FORM:
        navigationUrl = isManager
          ? `/${START}/${n.MYWORK_URL}/${TEAM_PERFORMANCE}/${ASP_FORM}/${YEAR_END_EVALUATION_URL}/${getAspFormEmployeeView?.currentStep}/${reporteeId}/${teamGoalActiveYear}`
          : navigationUrl;
        break;
      case VIEW_FORM:
        navigationUrl = isManager
          ? `/${START}/${n.MYWORK_URL}/${TEAM_PERFORMANCE}/${ASP_FORM}/${YEAR_END_EVALUATION_URL}/${getAspFormEmployeeView?.currentStep}/${reporteeId}/${teamGoalActiveYear}`
          : `/${START}/${n.MY_SPACE}/${PERFORMANCE}/view_asp/${getAspFormEmployeeView?.currentStep}/${getAspFormEmployeeView?.appraisalId}/${teamGoalActiveYear}`;
        break;
      default:
        navigationUrl = "/web";
    }
    return navigationUrl;
  };

  const onClickTeamsGoal = () => {
    navigate("/web/my_work/team_performance");
  };
  const onClickTeamsQC = () => {
    navigate("/web/my_work/team_quarterly_checkin");
  };
  const onClickGoals = () => {
    navigate("/web/my_space/performance");
  };
  const onClickQC = () => {
    navigate("/web/my_space/quaterlycheckin");
  };

  return (
    <div className="performanceContainer">
      {contextHolder}
      {isManager && <BreadcrumbComponent items={breadbrumbItems} />}
      {year && (
        <div className="asp-form-heading">
          <h5>{isManager ? TEAM_YEAR_END_REVIEW_TITLE : EMPLOYEE_ASP_TITLE}</h5>
          <Dropdown menu={menuYear} trigger={["click"]}>
            <a
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <Space className="year-selection">
                {year}
                <img src={ExpandMore} alt="" />
              </Space>
            </a>
          </Dropdown>
        </div>
      )}
      <div className="navigation-text-icon">
        <img src={Iicon} alt="" className="icon-navigation" />
        {isManager ? (
          <div className="navigation-text">
            {`If you want to view the Employee's Goals, please go to “My Work >`}
            <span className="highlighted-link" onClick={onClickTeamsGoal}>
              {" "}
              Team’s Goals
            </span>
            {`> Employee Goals”.\nTo view Quarterly Review Comments, please go to “My Work >`}
            <span className="highlighted-link" onClick={onClickTeamsQC}>
              Team’s Quarterly Review
            </span>{" "}
            {`> Employee’s Quarterly Review”.`}
          </div>
        ) : (
          <div className="navigation-text">
            {`If you want to view your goals, please go to “My Space > `}
            <span className="highlighted-link" onClick={onClickGoals}>
              My Goals
            </span>
            {`”.\nTo view your Quarterly Review Comments, please go to “My Space > `}
            <span className="highlighted-link" onClick={onClickQC}>
              My Quarterly Check In
            </span>
            {`".`}
          </div>
        )}
      </div>
      {isLoading || loadingASPform ? (
        <div className="loading-asp">
          <Spin spinning={isLoading || loadingASPform} />
        </div>
      ) : (
        <div className="asp-form-conatiner">
          <>
            {getAspFormEmployeeView?.error ? (
              <NoYearEndFrom />
            ) : (
              !getAspFormEmployeeView?.error && (
                <GoalPreview
                  title={
                    getAspFormEmployeeView?.year + " " + "Year End Evaluation"
                  }
                  startDate={getAspFormEmployeeView?.startDate}
                  endDate={getAspFormEmployeeView?.endDate}
                  currentlyWith={getAspFormEmployeeView?.currentlyWith}
                  currentStep={getAspFormEmployeeView?.currentStep}
                  navigationUrl={nagiateBasedOnButtonName(
                    getAspFormEmployeeView?.buttonName,
                    isManager
                  )}
                  buttonText={getAspFormEmployeeView?.buttonName}
                  disabled={getAspFormEmployeeView?.disable}
                  isManager={isManager}
                />
              )
            )}
          </>
        </div>
      )}
    </div>
  );
};

export default AspForm;
