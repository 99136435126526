import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store/redux-hooks";
import { getUserBadges } from "../../../../../redux/actions/gamification";
import "../LeaderBoardLanding/profile.css";
import LockBadgeOne from "../../../../../themes/assets/images/svg/Lock-badge-one.svg";
import LockBadgeTwo from "../../../../../themes/assets/images/svg/Lock-badge-two.svg";
import LockBadgeThree from "../../../../../themes/assets/images/svg/Lock-badge-three.svg";
import { Modal } from "antd";
import { formatBadgeName } from "../../../../utils/util";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CirclePlaceholder from "../../../../../themes/assets/images/svg/Circle_placeholder.svg";
import { EmptyEarnedBadges } from "./ActiveIconDetail";
import { GOT_IT } from "../../../../../constants";

const LockedIconDetail = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const { userBadges, loadingProfile, loading, error, badgesFilterData } =
    useAppSelector((state) => state.gamification);

  const lockedBadges = userBadges?.data?.lockedBadges;

  const filteredBadges = lockedBadges?.filter((item) =>
    badgesFilterData.includes(item?.gameId)
  );

  const showModal = (item) => {
    setIsModalOpen(true);
    setModalData(item);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setModalData(null);
  };
  return (
    <div className="badges_lock-otr">
      {filteredBadges?.length > 0 && badgesFilterData?.length > 0 ? (
        filteredBadges?.map((item) => {
          return (
            <div
              className="badges-lock_list"
              key={item.badgeId}
              type="primary"
              onClick={() => showModal(item)}
            >
              <div className="badge-otr_image">
                <LazyLoadImage
                  src={item?.lockedBadgeurl}
                  alt=""
                  effect="blur"
                />
              </div>
              <span>{item?.badgeName}</span>
              <h4>{formatBadgeName(item?.gameName)}</h4>
            </div>
          );
        })
      ) : badgesFilterData?.length > 0 ? (
        <div className="empty-badges-container">
          <EmptyEarnedBadges
            message={userBadges?.data?.headerInfo?.noBadgeMsg}
          />
        </div>
      ) : lockedBadges?.length > 0 ? (
        lockedBadges?.map((item) => (
          <div
            className="badges-lock_list"
            key={item.badgeId}
            type="primary"
            onClick={() => showModal(item)}
          >
            <div className="badge-otr_image">
              <LazyLoadImage src={item?.lockedBadgeurl} alt="" effect="blur" />
            </div>
            <span>{item?.badgeName}</span>
            <h4>{formatBadgeName(item?.gameName)}</h4>
          </div>
        ))
      ) : (
        <div className="empty-badges-container">
          <EmptyEarnedBadges
            message={userBadges?.data?.headerInfo?.noBadgeMsg}
          />
        </div>
      )}

      <Modal
        className="badges_popup-cont"
        title={modalData?.badgeName}
        open={isModalOpen}
        onOk={handleOk}
        okText={GOT_IT}
        centered={true}
        width={380}
      >
        <div className="placeholder_img">
          <LazyLoadImage
            src={modalData?.lockedBadgeurl}
            className="placeholder"
            alt="banner"
            effect="blur"
          />
          <LazyLoadImage
            src={modalData?.lockedBadgeurl}
            alt="banner"
            effect="blur"
          />
        </div>
        <p>{modalData?.badgeDsc}</p>
      </Modal>
    </div>
  );
};

export default LockedIconDetail;
