import "./profile.css";
import gameData from "../../../GamificationData.json";
import { useEffect, useState } from "react";
import { Skeleton } from "antd";
import AVATARIMG from "../../../../../themes/assets/images/svg/gamification_profile-pic.svg";
import RUBY from "../../../../../themes/assets/images/gamification/png/RUBY.png";
import AMBER from "../../../../../themes/assets/images/gamification/png/AMBER.png";
import JADE from "../../../../../themes/assets/images/gamification/png/JADE.png";
import TURQUOISE from "../../../../../themes/assets/images/gamification/png/TURQUIOSE.png";
import SAPPHIRE from "../../../../../themes/assets/images/gamification/png/SAPPHIRE.png";
import ProfileRewards from "./ProfileRewards";
import ProfileBadge from "./ProfileBadge";
import Leadersearch from "./Leadersearch";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store/redux-hooks";
import {
  getUserCardData,
  getFilters,
} from "../../../../../redux/actions/gamification";
import LeaderboardScrollable from "./LeaderBoardScrollable";
import AvatarProfile from "../../../../Avatar/AvatarProfile";
import { setEmptyLeaderboardData } from "../../../../../redux/reducers/gamification";

interface User {
  displayName?: string;
  designation?: string;
  account?: string;
  profileimg?: string;
}
const userProfileUserId =
  gameData?.[0]?.tempGameData?.userProfile?.userData?.[0]?.userId;

const Club_Images = {
  // "No Club": GameNoData,
  "Ruby Club": RUBY,
  "Jade Club": JADE,
  "Amber Club": AMBER,
  "Turquoise Club": TURQUOISE,
  "Sapphire Club": SAPPHIRE,
};

const formatUserClub = (userClub) => {
  return userClub || "No Club";
};
const formatClub = (userClub) => {
  return Club_Images[userClub] || null;
};

const formatAccount = (account) => {
  if (account?.[0]?.toUpperCase() === account?.[0]) {
    return account;
  } else {
    return account?.[0]?.toUpperCase() + account?.slice(1);
  }
};

export const LeaderBoardDetailSkeleton = ({ loading }) => {
  return (
    <div className="leadersection leader-board-skeleton-parent">
      <div className="skeleton-leader-board-top-detail">
        {Array.from({ length: 3 })?.map((item, index) => {
          return (
            <Skeleton.Button
              key={index}
              active={loading}
              className="leader-board-top-skeleton"
            />
          );
        })}
      </div>
      {Array.from({ length: 10 })?.map((item, index) => {
        return (
          <Skeleton.Button
            key={index}
            active={loading}
            className="leader-board-top-skeleton-card"
          />
        );
      })}
    </div>
  );
};

const LeaderBoardDetailSkeletonProfile = ({ loading }) => {
  return (
    <div className="gamifiction-otr leader-board-detail-skeleton-profile">
      <Skeleton.Avatar shape="circle" size={120} active={loading} />
      {Array?.from({ length: 3 })?.map((item, index) => {
        return (
          <Skeleton.Button
            key={index}
            active={loading}
            className="user-info-skeleton-card"
          />
        );
      })}
      <div className="profile-skeleton-user-data">
        <Skeleton.Button
          active={loading}
          className="user-card-badge-title-skeleton"
        />
        <div className="skeleton-badge-leader-board">
          {Array?.from({ length: 6 }).map((item, index) => {
            return (
              <Skeleton.Button
                key={index}
                active={loading}
                className="skelton-badge-leader-board-card"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const LeaderBoardProfile = () => {
  const { leaderBoardSelectedUser, userCardData } = useAppSelector(
    (state: any) => state?.gamification
  );

  const userData = userCardData?.data?.[0];

  const [currentUserData, setCurrentUserData] = useState<any>(userData);

  const formatSelectedUserData = (seletedUserData: any) => {
    const userLevel = seletedUserData?.levels?.[0]?.levelName;
    const account = seletedUserData?.account;
    return {
      ...seletedUserData,
      clubimg: formatClub(userLevel),
      clubname: formatUserClub(userLevel),
      account: formatAccount(account),
      leadertile: "current_user_tile",
      leaderrank: "current_rank_display",
      leadername: "current_username_lead",
      leaderclub: "current_club_display",
      leaderpoints: "current_points_display",
    };
  };

  useEffect(() => {
    if (leaderBoardSelectedUser) {
      setCurrentUserData(formatSelectedUserData(leaderBoardSelectedUser));
    } else {
      setCurrentUserData(formatSelectedUserData(userData));
    }
  }, [leaderBoardSelectedUser, userCardData]);

  // const currentUserData = userCardData?.data?.map((items) => {
  //   const userLevel = items?.levels?.[0]?.levelName;
  //   const account = items?.account;
  //   return {
  //     ...items,
  //     clubimg: formatClub(userLevel),
  //     clubname: formatUserClub(userLevel),
  //     account: formatAccount(account),
  //     leadertile: "current_user_tile",
  //     leaderrank: "current_rank_display",
  //     leadername: "current_username_lead",
  //     leaderclub: "current_club_display",
  //     leaderpoints: "current_points_display",
  //   };
  // });

  return (
    <div className="gamifiction-otr">
      {/* This will be required in phase two of gamification */}
      {/* <a href="#">
    <img
      className="edit_icon"
      src={GamificationEditIcon}
      alt="gamificationediticon"
    />
  </a> */}
      <div>
        <div className="profile_title-sec">
          <>
            <div className="gamificationprofilepic">
              <AvatarProfile
                name={currentUserData?.displayName}
                profilePic={currentUserData?.mediaUrl}
              />
            </div>
            <h1>{currentUserData?.displayName}</h1>
            <p className="profile_desc">{currentUserData?.designation}</p>
            <p className="profile_desc">{currentUserData?.account}</p>
          </>
        </div>

        <ProfileRewards RewardsData={currentUserData} />

        <div className="badges_otr-sec">
          <h1>Badges</h1>
          <ProfileBadge
            badgesData={
              leaderBoardSelectedUser
                ? leaderBoardSelectedUser?.rewards?.earnedBadges
                : userData?.rewards?.earnedBadges
            }
          />
        </div>
      </div>
    </div>
  );
};

const GamificationProfile = () => {
  const dispatch = useAppDispatch();

  const { loadingProfile, loadingList } = useAppSelector(
    (state: any) => state.gamification
  );
  const fetchUserCardData = () => {
    dispatch(getUserCardData({}));
  };
  const fetchFilterData = () => {
    dispatch(getFilters({}));
  };
  useEffect(() => {
    fetchUserCardData();
    fetchFilterData();
    dispatch(setEmptyLeaderboardData());
  }, []);

  return (
    <div>
      <div className={"game_sect-otr"}>
        <div className="megaleaderboard">Leaderboard</div>
        <div className="leaderboardash">
          <div className="leadersection">
            {!loadingProfile && !loadingList && <Leadersearch />}

            <LeaderboardScrollable />
          </div>

          {loadingProfile ? (
            <LeaderBoardDetailSkeletonProfile loading={true} />
          ) : (
            <LeaderBoardProfile />
          )}
        </div>
      </div>
    </div>
  );
};

export default GamificationProfile;
