import React, { useState, useEffect } from "react";
import { Button, Checkbox, Space, Radio, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import "../table/filterDropdown.css";
import {
  FILTER_CLEAR,
  FILTER_APPLY,
  ADMIN_FILTER_SEARCH,
} from "../../../constants";

const initialSelectedFilters = {
  id: "",
  type: "",
  questionId: "",
  question: "",
  date: "",
  order: "",
  content: "",
};

const FilterDropdown = (props: any) => {
  const [filterSearchValue, setFilterSearchValue] = useState("");
  const {
    filterProps,
    filterId,
    tableData,
    onApply,
    onClear,
    setSelectedFilters,
    selectedFilter,
  } = props;
  const [currentFilteredList, setCurrentFilteredList] = useState([]);

  // Fiter update functionality main
  useEffect(() => {
    const {
      _id,
      type,
      content = [],
      title,
      category,
      createdDate,
      questionId,
      question,
      createdAt,
    } = selectedFilter;
    if (_id) {
      const data = tableData.filter((ele: any) => ele._id === _id);
      setCurrentFilteredList(data);
    } else if (type) {
      const data = tableData.filter((ele: any) => ele.type === type);
      setCurrentFilteredList(data);
    } else if (title) {
      const data = tableData.filter((ele: any) => ele.title === title);
      setCurrentFilteredList(data);
    } else if (category) {
      const data = tableData.filter((ele: any) => ele.category === category);
      setCurrentFilteredList(data);
    } else if (createdDate) {
      const data = tableData.filter(
        (ele: any) => ele.createdDate === createdDate
      );
      setCurrentFilteredList(data);
    } else if (questionId) {
      const data = tableData.filter(
        (ele: any) => ele.questionId === questionId
      );
      setCurrentFilteredList(data);
    } else if (question) {
      const data = tableData.filter((ele: any) => ele.question === question);
      setCurrentFilteredList(data);
    } else if (createdAt) {
      const data = tableData.filter((ele: any) => ele.createdAt === createdAt);
      setCurrentFilteredList(data);
    } else if (content.length > 0) {
      const data = tableData.filter((ele: any) =>
        content.some((content: string) => ele.content.includes(content))
      );
      setCurrentFilteredList(data);
    }
  }, [selectedFilter]);

  useEffect(() => {
    return () => {
      setSelectedFilters(initialSelectedFilters);
      filterProps.clearFilters();
      setFilterSearchValue("");
    };
  }, []);

  const renderFilterContainer = (
    filterId: string,
    searchKey: string,
    filterData: any
  ) => {
    const showCheckbox = filterId === "content";
    if (filterData.length === 0) {
      return <p className="no-matched-records">{ADMIN_FILTER_SEARCH}</p>;
    }

    const filters = showCheckbox
      ? [{ text: "Select All", value: "all" }, ...filterData]
      : filterData;

    let filterDropdown = (filters || []).map(
      (filter: { value: string; text: string }) =>
        showCheckbox ? (
          <Checkbox
            className="landing-checkbox-group"
            key={filter.value}
            checked={selectedFilter[filterId]?.includes(filter.value)}
            onChange={(e) => {
              let updatedValues = e.target.checked
                ? [...(selectedFilter[filterId] || []), filter.value]
                : selectedFilter[filterId]?.filter((value: string) =>
                    value === "all" ? false : value !== filter.value
                  );

              if (filter.value === "all") {
                updatedValues = e.target.checked
                  ? filters.map((filter: any) => filter.value)
                  : [];
              } else if (
                filterData.length === updatedValues.length &&
                !updatedValues.includes("all")
              ) {
                updatedValues.push("all");
              }
              setSelectedFilters({
                ...selectedFilter,
                [filterId]: updatedValues,
              });
            }}
          >
            {filter.text}
          </Checkbox>
        ) : (
          <Radio
            className="landing-radio-group"
            key={filter.value}
            checked={selectedFilter?.[filterId]?.includes(filter.value)}
            onChange={(e) => {
              setSelectedFilters({
                ...selectedFilter,
                [filterId]: e.target.checked && filter.value,
              });
            }}
          >
            {filter.text}
          </Radio>
        )
    );
    return filterDropdown;
  };

  const getFilterData = (filterKey: string, searchKey: string = "") => {
    return (tableData || [])
      .map((item: any) => {
        if (filterKey === "deptGrpClrStatus") {
          const { deptGroupStatus } = item;
          return {
            text: deptGroupStatus?.label,
            value: deptGroupStatus?._id,
          };
        } else {
          return {
            text: item[filterKey],
            value: item[filterKey],
          };
        }
      })
      .filter(({ text }: any): any =>
        text?.toLowerCase().includes(searchKey?.toLowerCase())
      )
      .filter(
        ({ text }: any, index: number, array: any): any =>
          array.findIndex((item: any) => item.text === text) === index
      );
  };
  const filterData = getFilterData(filterId, filterSearchValue) || [];
  return (
    <div className="search-card">
      <Input
        placeholder="Search"
        value={filterSearchValue}
        onChange={(e) => {
          filterProps.setSelectedKeys(e.target.value ? [e.target.value] : []);
          setFilterSearchValue(e.target.value);
        }}
        // onPressEnter={() => filterProps.confirm()}
        suffix={<SearchOutlined />}
      />
      <div className="filterWrapper">
        {renderFilterContainer(
          filterId,
          filterProps.selectedKeys[0],
          filterData
        )}
      </div>
      <div>
        <Space className="clear-apply-parent">
          <Button
            onClick={() => {
              filterProps.setSelectedKeys([]);
              setSelectedFilters(initialSelectedFilters);
              filterProps.clearFilters();
              onClear(true);
              setFilterSearchValue("");
            }}
            size="small"
            className="clear-btn"
            disabled={
              !(filterSearchValue || !isEmpty(selectedFilter?.[filterId]))
            }
          >
            {FILTER_CLEAR}
          </Button>
          <Button
            type="primary"
            className="filter_apply_btn"
            onClick={() => {
              onApply(currentFilteredList, filterData);
              filterProps.confirm();
              filterProps.setSelectedKeys([]);
              // onApply(currentFilteredList);
              setFilterSearchValue("");
            }}
            size="small"
            disabled={
              isEmpty(selectedFilter?.[filterId]) || !filterData?.length
            }
          >
            {FILTER_APPLY}
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default FilterDropdown;
