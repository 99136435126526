import React from "react"

export const SvgDownIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M11.97 15.2c-.27 0-.54-.1-.74-.31l-4.76-4.76a.755.755 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l4.44 4.44 4.5-4.5c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-4.82 4.82c-.2.21-.47.31-.74.31Z"
    />
  </svg>
)