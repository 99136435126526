import React from "react";
import "../contributeGoals/contribute.css";
import BreadcrumbComponent from "../../common/breadcrumb/breadcrumb";
import FillButton from "../../common/button/FillButton";
import OutlineButton from "../../common/button/OutlineButton";
import n from "../../navigation/Route.Names";
import AccessTime from "../../../themes/assets/images/svg/front/active_access_time.svg";
import BusinessMilestone from "../../../themes/assets/images/svg/BusinessMilestone.svg";
import { GOAL_TITLE, GOAL_SUBTITLE } from "../../../constants";
import { useState } from "react";
import { EDIT_GOAL, CANCEL } from "../../../constants";
import { useNavigate, Link } from "react-router-dom";

const CascadingViewGoal = () => {
  const navigate = useNavigate();

  const [isEmptyMilestone, setEmptyMilestone] = useState(false);

  const fillBtnAction = (value: object) => {
    navigate(`/${n.PERFORMANCE_URL}/${n.CASCADING_EDIT_GOALS_URL}`);
  };
  const items = [
    {
      title: (
        <Link to={`/${n.PERFORMANCE_URL}`} className="link-highlight">
          Perfomance
        </Link>
      ),
    },
    {
      title: (
        <Link
          to={`/${n.PERFORMANCE_URL}/${n.CASCADING_EDIT_GOALS_URL}`}
          className="link-highlight"
        >
          Edit Cascaded Goal
        </Link>
      ),
    },
    {
      title: "View Goal",
    },
  ];

  return (
    <div className="contribute_view-otr">
      <div className="container">
        <div className="breadcrumb_preview">
          <BreadcrumbComponent
            // rootPath={"Perfomance / Edit Cascaded Goal"}
            // subPath={"View Goal"}
            // rootLink={`${n.PERFORMANCE_URL}/${n.CASCADING_EDIT_GOALS_URL}`}
            items={items}
          />
        </div>
        <div className="goal-preview-header">
          <div className="goal-preview-title">
            <h3 className="preview-main-title">View Goal</h3>
          </div>
        </div>
        <div className="preview-goal-main">
          <div className="view-status-row">
            <div className="row-section">
              <h5>Category</h5>
              <p>Business Category</p>
            </div>
          </div>
          <div className="row-section">
            <h5>Goal Name</h5>
            <p>Sprint Quality Management</p>
          </div>
          <div className="row-section">
            <h5>Goal Description</h5>
            <p>
              Achieve high sprint velocity and on-time product releases so that
              strategic timelines are met.Ensure best practices followed within
              the team to produce high quality product and features
            </p>
          </div>
          <div className="row-section">
            <h5>Outcome</h5>
            <p>Delivery Excellence and Innovation</p>
          </div>
          <div className="row-section">
            <h5>Metric</h5>
            <p>Business Category</p>
          </div>
          <div className="row-section">
            <h5>Weight</h5>
            <p>25%</p>
          </div>
          <div className="row-section">
            <h5>Visibility</h5>
            <p>Private</p>
          </div>
          <div className="row-section">
            <h5>Start and End Date</h5>
            <p>01/02/2022 - 12/02/2023</p>
          </div>
          <div className="milestone-section">
            <div className="milestone-header-section">
              <div className="milestone-header-left">
                <h5>{GOAL_TITLE}</h5>
                <span>{GOAL_SUBTITLE}</span>
              </div>
            </div>
            {isEmptyMilestone ? (
              <div className="milestone-body-section">
                <div className="milestone-body-header">
                  <img src={AccessTime} alt="" />
                  <h5>Milestones 1</h5>
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500. Lorem Ipsum has been
                  the industry's standard dummy text ever since the 1500s, when
                  an unknown printer took a galley of type and scrambled
                </p>
                <div className="milestone-body-dates">
                  <span className="milestone-start-date">
                    Target Date : 31/02/2023
                  </span>
                  <span>Actual Date : 31/02/2023</span>
                </div>
              </div>
            ) : (
              <div className="empty-milestone">
                <img src={BusinessMilestone} alt="" />
                <span>
                  No milestone has been added yet. Click to add milestone.
                </span>
              </div>
            )}
          </div>
          <div className="button-area">
            <OutlineButton
              outlineBtnStyles="outlineBtnStyles"
              backText={CANCEL}
            />
            <FillButton
              fillBtnStyles="fillBtnStyles"
              fillBtnTxt={EDIT_GOAL}
              fillBtnAction={fillBtnAction}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CascadingViewGoal;
