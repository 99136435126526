import React, { useEffect } from "react";
import employeeDetails from "../EmployeeData.json";
import EmployeeCard from "../../components/EmployeeCard";
import "./index.css";
import emptySubmittedGoal from "../../../../themes/assets/images/performance/no-submitted-goals.png";
import NoPending from "../../../../themes/assets/images/performance/NoPending.svg";
import {
  ASP_FLOW,
  EMPTY_SUBMITTED_GOAL_SUBTITLE,
  EMPTY_SUBMITTED_GOAL_TITLE,
  NO_PENDING_QUATERLY_REVIEW,
  NO_PENDING_REVIEWS,
  NO_YEAR_END_REVIEW,
  PENDING_REVIEWS_TYPE_ASP,
  PENDING_REVIEWS_TYPE_QUARTERLY_CHECKIN,
  TEAM_QUARTERLY_CHECKIN,
  TEAMS_ASP,
  TEAMS_QUARTERLY_CHECKIN,
} from "../../../../constants";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store/redux-hooks";
import {
  getPendingReivewsManager,
  getSubmittedGoals,
} from "../../../../redux/actions/performance";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";

const EmptySubmittedGoal = (props: any) => {
  const { flow } = props;
  let imgSrc = emptySubmittedGoal;
  let title = EMPTY_SUBMITTED_GOAL_TITLE;
  let subtitle = EMPTY_SUBMITTED_GOAL_SUBTITLE;
  if (flow === TEAMS_QUARTERLY_CHECKIN) {
    imgSrc = NoPending;
    title = NO_PENDING_REVIEWS;
    subtitle = NO_PENDING_QUATERLY_REVIEW;
  } else if (flow === TEAMS_ASP) {
    imgSrc = NoPending;
    title = NO_PENDING_REVIEWS;
    subtitle = NO_YEAR_END_REVIEW;
  }
  return (
    <div className="empty-submitted-goals">
      <img src={imgSrc} alt="" className="submitted-goal-img" />
      <div className="submitted-goal-empty-title">{title}</div>
      <div className="submitted-goal-empty-subheader">{subtitle}</div>
    </div>
  );
};

const PendingReview = ({ flow, goalsTabKey }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { configuration, isLoading, managerSubmittedGoals } = useAppSelector(
    (state: any) => state?.performance
  );
  const year = useAppSelector(
    (state: any) => state?.performance?.teamGoalActiveYear
  );

  useEffect(() => {
    if (location?.pathname?.includes(TEAM_QUARTERLY_CHECKIN))
      dispatch(
        getPendingReivewsManager({
          year,
          type: PENDING_REVIEWS_TYPE_QUARTERLY_CHECKIN,
        })
      );
    else if (location?.pathname?.includes(ASP_FLOW))
      dispatch(
        getPendingReivewsManager({
          year,
          type: PENDING_REVIEWS_TYPE_ASP,
        })
      );
    else dispatch(getSubmittedGoals({ year }));
  }, [year]);

  return (
    <div className="overview_count_main">
      {isLoading ? (
        <Spin spinning={isLoading} className="submitted-goals-loading" />
      ) : managerSubmittedGoals?.length > 0 ? (
        <div className="employeeDisplay">
          {managerSubmittedGoals?.map((item, index) => {
            // if (index > 4) return;
            return (
              <EmployeeCard
                flow={flow}
                key={item?.id?.toString()}
                employee={item}
                goalsTabKey={goalsTabKey}
              />
            );
          })}
        </div>
      ) : (
        <EmptySubmittedGoal flow={flow} />
      )}
    </div>
  );
};

export default PendingReview;
