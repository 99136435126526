import React from "react";
import NoData from "../../../../../themes/assets/images/gamification/png/no-data-activity.png";
import {
  ACTIVITY_HISTORY,
  HISTORY_START_COUNT,
  LOADMORE,
  NO_POINTS,
  NO_POINTS_SUBTITLE,
} from "../../../../../constants";
import "./index.css";
import LeaderSearchMonth from "./LeadersearchMonth";
import { useSelector } from "react-redux";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store/redux-hooks";
import { Skeleton } from "antd";
import {
  getPointsHistoryByMonth,
  getPointsHistoryByMonthFilter,
} from "../../../../../redux/actions/gamification";
import { convertDateToMMYY } from "../../../../utils/util";

const CardItem = ({ data }) => {
  function formatDateToLongerFormat(dateStr) {
    const dateObj = new Date(dateStr);
    const month = dateObj?.toLocaleString("default", { month: "long" });
    const day = dateObj?.getDate();
    const year = dateObj?.getFullYear();
    return `${month} ${day}, ${year}`;
  }

  return (
    <div className="card-item">
      <div className="title-one">{data?.gameName}</div>
      <div className="title-two">
        {data?.eventDesc} -{" "}
        <span>{formatDateToLongerFormat(data?.eventDate)}</span>
      </div>
      <div
        className={
          data?.points >= 0 ? "title-three" : "title-three title-three-red"
        }
      >{`${data?.points > 0 ? "+" : ""}${data?.points}`}</div>
    </div>
  );
};

const EmptyPointsDashboard = () => {
  return (
    <div className="no-data-container">
      <img src={NoData} alt="no activity" className="activity-empty-image" />
      <div className="no-data-activity-title">{NO_POINTS}</div>
      <div className="sub-title-no-activity">{NO_POINTS_SUBTITLE}</div>
    </div>
  );
};

const ActivitySection = ({ data, dataKey }) => {
  const dispatch = useAppDispatch();
  const { activeModulesPoints, activeMonthPoints } = useAppSelector(
    (state: any) => state?.gamification
  );

  function countFirstLevelChildren(obj) {
    let count = 0;

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const child = obj[key];
        if (typeof child === "object" && child !== null) {
          count += Object.keys(child).length;
        }
      }
    }
    return count;
  }

  const formatDate = (dateString) => {
    const date = dateString?.split("-");
    return `${date[1]} ${date[0]}`;
  };

  const onClickLoadMore = () => {
    if (activeModulesPoints?.length > 0 || activeMonthPoints) {
      dispatch(
        getPointsHistoryByMonthFilter({
          month: dataKey,
          start: countFirstLevelChildren(data?.history) + HISTORY_START_COUNT,
          monthAndYear: activeMonthPoints
            ? convertDateToMMYY(activeMonthPoints)
            : "",
          gameName: activeModulesPoints,
        })
      );
    } else {
      dispatch(
        getPointsHistoryByMonth({
          month: dataKey,
          start: countFirstLevelChildren(data?.history) + HISTORY_START_COUNT,
        })
      );
    }
  };

  return (
    <div className="activity-section">
      <div className="activity-section-header">
        <div className="activity-section-title">{formatDate(dataKey)}</div>
        <div
          className={
            !data?.totalPoints?.includes("-")
              ? "activity-section-points"
              : "activity-section-points activity-section-points-red"
          }
        >{`${data?.totalPoints} Points`}</div>
      </div>
      {Object?.keys(data?.history)?.map((key) => (
        <div className="card-activity" key={key}>
          {data?.history?.[key]?.map((item: any, index: any) => {
            return <CardItem key={index} data={item} />;
          })}
        </div>
      ))}
      {countFirstLevelChildren(data?.history) < data?.totalEventsCount && (
        <div onClick={onClickLoadMore} className="load-more-button">
          {LOADMORE}
        </div>
      )}
    </div>
  );
};

const ActivityHistory = () => {
  const gamificationData: any = useAppSelector(
    (state: any) => state?.gamification
  );
  const { loading, loadingSummaryData } = useAppSelector(
    (state: any) => state.gamification
  );
  const pointsActivate = () => {
    return (
      activityHistory === null || Object?.keys(activityHistory)?.length < 1
    );
  };
  const activityHistory = gamificationData?.activityHistory;

  const tempLoadingVal = Array?.from({ length: 10 });

  return (
    <div className="activity-container">
      {loading || loadingSummaryData ? (
        <div className="header-section-activity">
          <Skeleton.Button
            active={true}
            className="activity-container-title-skeleton"
          />
          <Skeleton.Button
            active={true}
            className="activity-container-title-skeleton"
          />
        </div>
      ) : (
        <div className="header-section-activity">
          <div className="activity-container-title">{ACTIVITY_HISTORY}</div>
          <LeaderSearchMonth />
        </div>
      )}
      {pointsActivate() ? (
        <div className="content-container-activity-empty">
          <EmptyPointsDashboard />
        </div>
      ) : !loading && !loadingSummaryData ? (
        <div className="content-container-activity">
          {Object?.keys(activityHistory)?.map((key) => (
            <ActivitySection
              key={key}
              data={activityHistory?.[key]}
              dataKey={key}
            />
          ))}
        </div>
      ) : (
        <div className="content-container-activity skeleton-gap">
          {tempLoadingVal?.map((item) => {
            return (
              <Skeleton.Button
                active={true}
                className="loading-card-skeleton"
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ActivityHistory;
