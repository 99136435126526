import React, { useState } from "react";

const ContributeSlider = (props) => {
  const { progress, userClickedStatus, max } = props;
  const contributeprogress = ((100 * progress) / 100).toFixed(1);

  const progressStyle = {
    width: `${contributeprogress}%`,
    backgroundColor: userClickedStatus ? "#5cb85c" : "#0642B5", // Set background color based on userClickedStatus
    height: "4px", // Set height of the progress bar
    borderRadius: "4px", // Add border radius for rounded corners
  };

  return (
    <div className="range_otr-sec">
      <div className="line-border">
        <div className="range-width-inn" style={progressStyle}></div>
      </div>
      <span>
        {progress}/{max}
      </span>
    </div>
  );
};

export default ContributeSlider;
