import React, { useEffect, useState } from "react";

import "./quarterly-checkin.css";
import CommentTextArea from "../../components/CommentsTextArea";
import QuarterReview from "../../components/QuarterReview";
import ClosePopUp from "../../components/ClosePopUp";
import EmptyReviewComments from "../../components/EmptyReviewComments";
import ToastMessage from "../../../common/ToastMsg/toastMessage";
import ToastInfo from "../../../../themes/assets/images/performance/svg/ToastInfo.svg";
import ToastError from "../../../../themes/assets/images/performance/svg/ToastError.svg";
import ExpandMore from "../../../../themes/assets/images/performance/svg/Expand_more.svg";
import ToastClose from "../../../../themes/assets/images/performance/svg/ToastClose.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store/redux-hooks";
import {
  AnnualYear,
  postQuarterlyCheckinCommentEmployee,
  quarterlyCheckinDataEmployee,
} from "../../../../redux/actions/performance";
import somethingWrong from "../../../../themes/assets/images/something-went-wrong.png";
import { Dropdown, MenuProps, notification, Space, Spin } from "antd";
import {
  CONFIRMATION_TITLE,
  FAILURE,
  NAVIGATION_TEXT_ASP_EMPLOYEE,
  NAVIGATION_TEXT_QUARTELY_EMPLOYEE,
  NOTIFICATION_DURATION,
  SUCCESS,
  WARNING,
} from "../../../../constants";
import { GoalsErrorIcon, ToastSuccessIcon } from "../../../common/svg/svg";
import { notificationAction } from "../../../../redux/reducers/performance";
import Iicon from "../../../../themes/assets/images/info_grey.png";
import amplitude from "amplitude-js";
import { useNavigate } from "react-router-dom";

const QuarterlyCheckIn = (props: any) => {
  const [api, contextHolder] = notification.useNotification({
    top: 0,
    maxCount: 1,
  });
  const [modalState, setModalState] = useState(false);
  const [comment, setComment] = useState("");
  const [quarter, setQuarter] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    errorTabData,
    loadingTabData,
    quarterlyCheckInEmployeeData,
    yearDropdown,
    isLoading,
    configuration,
  } = useAppSelector((state: any) => state?.performance);

  const { years, currentYear } = yearDropdown || {};
  const [year, setYear] = useState(currentYear);
  useEffect(() => {
    setYear(currentYear);
  }, [currentYear]);

  useEffect(() => {
    dispatch(AnnualYear({}));
  }, []);

  const items: MenuProps["items"] = years;
  const handleYearClick: MenuProps["onClick"] = (e) => {
    const key = e.key;
    setYear(key);
    setComment("");
  };
  const menuYear = {
    items,
    onClick: handleYearClick,
    selectable: true,
    defaultSelectedKeys: [year?.toString()],
  };

  const onCloseHandler = () => {
    dispatch(
      notificationAction({
        type: WARNING,
        message: "",
        isShowNotification: false,
      })
    );
  };

  useEffect(() => {
    if (configuration.isShowNotification) {
      const type = configuration.alertMessage.type;
      api[type]({
        message: configuration.alertMessage.message,
        description: configuration.alertMessage.additionalInfo,
        placement: "topRight",
        duration: NOTIFICATION_DURATION,
        icon:
          type === SUCCESS ? (
            <ToastSuccessIcon />
          ) : type === FAILURE ? (
            <GoalsErrorIcon />
          ) : null,
        className:
          type === SUCCESS
            ? `custom-toast-area custom-success-toast`
            : type === FAILURE
            ? `custom-toast-area custom-error-toast`
            : `custom-toast-area custom-info-toast`,
        onClose: onCloseHandler,
      });
    }
  }, [configuration.isShowNotification]);

  useEffect(() => {
    year && dispatch(quarterlyCheckinDataEmployee({ year }));
  }, [year]);

  const { employee, manager, comments = [] } = quarterlyCheckInEmployeeData;
  const orderedQuarterlyCheckInEmployeeData = [...comments]?.reverse();

  const onClickSubmit = (quarter, comment) => {
    setModalState(true);
    setQuarter(quarter);
  };

  if (errorTabData) {
    return (
      <div className="quarterly-checkinEmployee centerItems ">
        <img src={somethingWrong} className="quarterly-checkin-error-main" />
        <div className="something-went-wrong-text-quarterly-checkin">
          Something went wrong
        </div>
        <div className="something-went-wrong-subHeder">
          Connect to a different network and try again
        </div>
      </div>
    );
  }

  if (loadingTabData) {
    return (
      <div className="quarterly-checkinEmployee centerItems ">
        <Spin spinning={loadingTabData} />
      </div>
    );
  }

  const submitHandler = () => {
    setModalState(false);
    dispatch(
      postQuarterlyCheckinCommentEmployee({
        year: year,
        quarter: quarter,
        comment: comment,
      })
    )
      .unwrap()
      .then((success) => {
        dispatch(quarterlyCheckinDataEmployee({ year: year }));
      });
    amplitude.getInstance().logEvent("Employee Quaterly Check-in");
    setComment("");
  };

  const onClickGoals = () => {
    navigate("/web/my_space/performance");
  };
  const onClickYearEnd = () => {
    navigate("/web/my_space/AspForm");
  };

  return (
    <div className="performanceContainer">
      {contextHolder}
      <Spin spinning={isLoading}>
        <div className="left-side quarterly-reivew-margin">
          <h5>Quarterly Review</h5>
          <Dropdown menu={menuYear} trigger={["click"]}>
            <a onClick={(e) => e.preventDefault()}>
              <Space className="year-selection">
                {year}
                <img src={ExpandMore} alt="" />
              </Space>
            </a>
          </Dropdown>
        </div>
        <div className="navigation-text-icon">
          <img src={Iicon} alt="" className="icon-navigation" />
          <div className="navigation-text">
            {`If you want to view your goals, please go to “My Space >  `}
            <span className="highlighted-link" onClick={onClickGoals}>
              My Goals
            </span>
            {`”.\nTo view your Year End Review, please go to “My Space > `}
            <span className="highlighted-link" onClick={onClickYearEnd}>
              My Year End Review
            </span>
            {`"`}
          </div>
        </div>
        <div className="quarterly-checkinEmployee quarterly-checkin-reviewContainer">
          {/* Check weather BE will send empty array when there is no comments TODO */}
          {comments?.length > 0 ? (
            <>
              <div className="quaterly-review-container">
                {comments?.map((item: any, index: any) => {
                  if (item?.allowEmployeeComment) {
                    return (
                      <CommentTextArea
                        key={index?.toString()}
                        title={item?.quarter}
                        placeholder={
                          "Please add your self review comments for this quarter"
                        }
                        submitButtonText={"Self-Assessment Complete"}
                        onClickSubmit={() => onClickSubmit(index + 1, comment)}
                        comment={comment}
                        setComment={setComment}
                      />
                    );
                  }
                  return null;
                })}
                {orderedQuarterlyCheckInEmployeeData?.map(
                  (item: any, index: any) => {
                    if (item?.allowEmployeeComment) {
                      return null;
                    }
                    return (
                      <>
                        <QuarterReview
                          data={item}
                          manager={false}
                          key={index}
                          employeeData={employee}
                          managerData={manager}
                        />
                      </>
                    );
                  }
                )}
              </div>
            </>
          ) : (
            <EmptyReviewComments />
          )}

          <ClosePopUp
            modalState={modalState}
            setModalState={setModalState}
            onHandleSubmit={submitHandler}
            title={CONFIRMATION_TITLE}
            subTitle={`Submitting Self-Assessment to ${manager?.userName}`}
          />
        </div>
      </Spin>
    </div>
  );
};

export default QuarterlyCheckIn;

//TODO: trigger refetch every time user visits clicks on quarterly checkin
