import { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store/redux-hooks";
import { formatBadgeName } from "../../../../utils/util";
import { getUserBadges } from "../../../../../redux/actions/gamification";
import "../LeaderBoardLanding/profile.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LockBadgeOne from "../../../../../themes/assets/images/svg/Lock-badge-one.svg";
import LockBadgeTwo from "../../../../../themes/assets/images/svg/Lock-badge-two.svg";
import LockBadgeThree from "../../../../../themes/assets/images/svg/Lock-badge-three.svg";

const LockedBadgeIcon = () => {
  const { userBadges, loadingProfile, loading, error } = useAppSelector(
    (state) => state.gamification
  );
  const lockedBadges = userBadges?.data?.lockedBadges?.slice(0, 6);
  return (
    <div className="badges_lock-otr">
      {lockedBadges?.map((item) => (
        <div className="badges-lock_list" key={item?.badgeId}>
          <div className="badge-otr_image">
            <LazyLoadImage src={item?.lockedBadgeurl} alt="" effect="blur"/>
          </div>
          <span>{item?.badgeName}</span>
          <h4>{formatBadgeName(item?.gameName)}</h4>
        </div>
      ))}
    </div>
  );
};

export default LockedBadgeIcon;
