import { createAsyncThunk } from "@reduxjs/toolkit";
import separation from "../services/separation";
import { CardDetails } from "../../components/separation/clearance-detail/ClearanceInterface";

//save detail action
export const saveClearanceDetail = createAsyncThunk(
  "saveClearanceDetail",
  async (data: any, { rejectWithValue }) => {
    const { empId, locId, filtercard, custommessage } = data;
    const url = `api/v1/clearance/detail-page?employeeId=${empId}&locationCode=${locId}`;
    try {
      const response = await separation.post(url, filtercard);
      const result = await response.data;
      result.custommessage = custommessage;
      return result;
    } catch (er) {
      console.log(er);
      return rejectWithValue(er);
    }
  }
);

//upload attachment action
export const uploadAttachment = createAsyncThunk(
  "uploadAttachment",
  async (data: any, { rejectWithValue }) => {
    const { index, ...request } = data;
    const url = `api/v1/clearance/uploadFile`;
    try {
      const response = await separation.post(url, request);
      const result = await response.data;
      const mergeresult = { ...result, index, fileName: request.fileName };
      return mergeresult;
    } catch (er) {
      console.log(er);
      return rejectWithValue(er);
    }
  }
);

//get detail action
export const getClearanceDetail = createAsyncThunk(
  "getClearanceDetail",
  async (args: any, { rejectWithValue }) => {
    const { empId, locId } = args;
    const url = `api/v1/clearance/detail-page?employeeId=${empId}&locationCode=${locId}`;
    try {
      const response = await separation.get<CardDetails>(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//download attachment action
export const downloadAttachment = createAsyncThunk(
  "downloadAttachment",
  async (args: any, { rejectWithValue }) => {
    const { attachmentId } = args;
    const url = `api/v1/clearance/getFile?attachmentId=${attachmentId}`;
    try {
      const response = await separation.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//delete attachment action
export const deleteAttachment = createAsyncThunk(
  "deleteAttachment",
  async (args: any, { rejectWithValue }) => {
    const { attachmentId, index } = args;
    const url = `api/v1/clearance/deleteFile?attachmentId=${attachmentId}`;
    try {
      const response = await separation.delete(url);
      const result = await response.data;
      const mergeresult = { ...result, index };
      return mergeresult;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
