import { createSlice } from "@reduxjs/toolkit";
import { callLoginSAMLApi, getJWTTokenForLoggedinUser } from '../actions/auth';

type employeeProfileState = {
    samlAuthResponse: any,
    samlAuthLoading: boolean,
    samlAuthResponseError: string,
    authJWTLoading: boolean
    authJWTResponseError: any;
    authJWTResponse: string;
};

const initialTalentCentralState: employeeProfileState = {
    samlAuthResponse: {},
    samlAuthLoading: false,
    samlAuthResponseError: "",
    authJWTResponse: "",
    authJWTResponseError: null,
    authJWTLoading: false,
};

const AuthSlice:any = createSlice({
    name: 'auth',
    initialState: initialTalentCentralState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(callLoginSAMLApi.pending, (state) => {
            state.samlAuthResponse = [];
            state.samlAuthLoading = true;
            state.samlAuthResponseError = "";
        });
        builder.addCase(callLoginSAMLApi.fulfilled, (state, { payload }) => {
            state.samlAuthResponse = payload.data;
            state.samlAuthLoading = false;
            state.samlAuthResponseError = "";
        });
        builder.addCase(callLoginSAMLApi.rejected,
            (state, { payload }) => {
                state.samlAuthResponse = [];
                state.samlAuthLoading = false;
                state.samlAuthResponseError = "Something Went Wrong";
        });
        builder.addCase(getJWTTokenForLoggedinUser.pending, (state) => {
            state.authJWTLoading = true;
            state.authJWTResponseError = null;
            state.authJWTResponse = ""
        });
        builder.addCase(getJWTTokenForLoggedinUser.fulfilled,
            (state, { payload }) => {
                state.authJWTResponse = payload.data.jwt
                state.authJWTLoading = false;
                state.authJWTResponseError = null;
        });
        builder.addCase(getJWTTokenForLoggedinUser.rejected,
            (state, { payload }) => {
                state.authJWTResponse = ""
                state.authJWTLoading = false;
                state.authJWTResponseError = "Something Went Wrong"
        });

    },
})

export default AuthSlice.reducer;