import { useNavigate } from "react-router-dom";
import {
  ASP_COMPETENCY_EVALUATION_MANAGER_LABEL,
  ASP_COMPETENCY_EVALUATION_RATING_LABEL,
  ASP_COMPETENCY_EVALUATION_TITLE,
  ASP_START_NEXT,
  MANAGER_COMPETENCY_EFFECTIVE_TITLE,
  MANAGER_COMPETENCY_TITLE,
  MANAGER_COMPETENCY_SELF_TITLE,
  COMPETENCY_EVALUATION_PRE,
  COMPETENCY_RATING_CALCULATION,
  SAVE,
  PLACEHOLDER_ADD_COMMENT,
  MIN_ROWS,
  MAX_ROWS,
  CANCEL,
} from "../../../../../constants";
import FillButton from "../../../../common/button/FillButton";
import { Card, Form, Rate, Input } from "antd";
import { useState } from "react";
import OutlineButton from "../../../../common/button/OutlineButton";
import ManagerRatingCard from "../../../components/ManagerRatingCard";
import TextStylesChange from "../../../components/TextStylesChange";
import CommonTable from "../../../../common/CommonTable";
import "./ManagerCompotencyEvaluation.css";

const { TextArea } = Input;

const CompetencyEvaluation = () => {
  const [value, setValue] = useState("");
  let navigate = useNavigate();

  return (
    <>
      <div className="layoutContainer">
        <h4 className="common-short-heading">
          {ASP_COMPETENCY_EVALUATION_TITLE}
        </h4>
        <Card className="compotency-card">
          <div className="compotency-main-section">
            <div className="compotency-inner-section">
              <h4>{MANAGER_COMPETENCY_EFFECTIVE_TITLE}</h4>
              <ol type="1">
                <li>
                  Demonstrates effective written and oral communication skills.
                </li>
                <li>
                  Structures communication appropriately, listens carefully
                  (content, choice of words and grammar in line with audience)
                  and fluently.
                </li>
                <li>
                  Sharing relevant information to the respective stake holders &
                  Adhering to communication channel etiquettes
                </li>
              </ol>
            </div>
            <div className="compotency-inner-section">
              <Form.Item
                label={ASP_COMPETENCY_EVALUATION_RATING_LABEL}
                required
              >
                <div>
                  <Rate className={"custom-rating"} defaultValue={0} />
                </div>
              </Form.Item>
            </div>
            <div className="compotency-inner-section">
              <Form.Item
                label={ASP_COMPETENCY_EVALUATION_MANAGER_LABEL}
                required
              >
                <div>
                  <TextArea
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder={PLACEHOLDER_ADD_COMMENT}
                    autoSize={{ minRows: MIN_ROWS, maxRows: MAX_ROWS }}
                  />
                </div>
              </Form.Item>
              <div>
                <TextStylesChange />
              </div>
            </div>
            <div className="commentCardContainer-manager">
              <ManagerRatingCard />
            </div>
          </div>
        </Card>
        <Card className="compotency-card">
          <div className="compotency-main-section">
            <div className="compotency-inner-section">
              <h4>{MANAGER_COMPETENCY_SELF_TITLE}</h4>
              <p>
                Accomplishes 30 hours of learning per year based on the
                discussion with Reporting Manager
              </p>
            </div>
            <div className="compotency-inner-section">
              <Form.Item
                label={ASP_COMPETENCY_EVALUATION_RATING_LABEL}
                required
              >
                <div>
                  <Rate className={"custom-rating"} defaultValue={0} />
                </div>
              </Form.Item>
            </div>
            <div className="compotency-inner-section">
              <Form.Item
                label={ASP_COMPETENCY_EVALUATION_MANAGER_LABEL}
                required
              >
                <div>
                  <TextArea
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder={PLACEHOLDER_ADD_COMMENT}
                    autoSize={{ minRows: MIN_ROWS, maxRows: MAX_ROWS }}
                  />
                </div>
              </Form.Item>
              <div>
                <TextStylesChange />
              </div>
            </div>
            <div className="commentCardContainer-manager">
              <ManagerRatingCard />
            </div>
          </div>
        </Card>
        <Card className="compotency-card">
          <div className="compotency-main-section">
            <div className="compotency-inner-section">
              <h4>{MANAGER_COMPETENCY_TITLE}</h4>
              <ol type="1">
                <li>
                  Being mindful to interdependencies across functions and roles{" "}
                </li>
                <li>
                  Building a working relationship with the peers by seeking
                  ideas and suggestions from peers, when required
                </li>
                <li>Knowledge sharing & learning</li>
                <li>Collaboration</li>
              </ol>
            </div>
            <div className="compotency-inner-section">
              <Form.Item
                label={ASP_COMPETENCY_EVALUATION_RATING_LABEL}
                required
              >
                <div>
                  <Rate className={"custom-rating"} defaultValue={0} />
                </div>
              </Form.Item>
            </div>
            <div className="compotency-inner-section">
              <Form.Item
                label={ASP_COMPETENCY_EVALUATION_MANAGER_LABEL}
                required
              >
                <div>
                  <TextArea
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder={PLACEHOLDER_ADD_COMMENT}
                    autoSize={{ minRows: MIN_ROWS, maxRows: MAX_ROWS }}
                  />
                </div>
              </Form.Item>
              <div>
                <TextStylesChange />
              </div>
            </div>
            <div className="commentCardContainer-manager">
              <ManagerRatingCard />
            </div>
          </div>
        </Card>
        <div className="rating-calculated-container">
          <div className="rating-calculated-title">
            <span className="calculated-title">
              {COMPETENCY_RATING_CALCULATION}
            </span>
            <span className="calculated-rating-btn">4.25/5.0</span>
          </div>
          <div>
            <CommonTable />
          </div>
        </div>
        <div className="footer-btn-section">
          <div className="footer-btn-left">
            <OutlineButton
              outlineBtnStyles={"cancel-outline-btn"}
              outlineBtnAction={() => {}}
              backText={CANCEL}
            />
          </div>
          <div className="footer-btn-right">
            <OutlineButton
              outlineBtnStyles={"previous-outline-btn"}
              outlineBtnAction={() => {
                navigate(
                  `/web/my_work/${"team_performance"}/manager_goal_evaluation`
                );
              }}
              backText={COMPETENCY_EVALUATION_PRE}
            />
            <OutlineButton
              outlineBtnStyles={"previous-outline-btn"}
              outlineBtnAction={() => {}}
              backText={SAVE}
            />
            <FillButton
              fillBtnStyles={"manager-submit-fill-btn"}
              fillBtnAction={() => {
                navigate(`/web/my_work/${"team_performance"}/potential_rating`);
              }}
              fillBtnTxt={ASP_START_NEXT}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompetencyEvaluation;
