import { useEffect, useState } from "react";
import { Table, Avatar, Button } from "antd";
import "../SeparationLanding.css";
import { useNavigate } from "react-router-dom";
import routePath from "../../../navigation/Route.Names";
import { replaceString } from "../../../utils/util";
import {
  SEPARATION_DETAIL_EMP_CODE_REPLACE_STRING,
  SEPARATION_DETAIL_LOCATION_CODE_REPLACE_STRING,
} from "../../../../constants";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store/redux-hooks";
import { getLanding } from "../../../../redux/actions/landing";
import { isArray, isEmpty } from "lodash";
import TableFilter from "./tableFilter";
import { nodatafoundIcon } from "../../../common/svg/svg";
import { sortingIcon } from "../../../common/svg/svg";

interface DataType {
  key: string;
  employeeName: string;
  employeeId: string;
  employeeLocation: string;
  employeeLWD: string;
  employeeLocationCode: string;
  deptGrpClrStatus: string;
  employeeAction: string;
  deptGroupStatus: any;
}

const ContentTable = (props: any) => {
  const initialSelectedFilters = {
    employeeLocation: [],
    employeeName: "",
    employeeId: "",
    employeeLWD: "",
  };
  const separationLanding = useAppSelector((state: any) => state.landing);
  const { employeeDetailList, headerList } = separationLanding.landing;
  const { employeeInfo } = separationLanding;

  const noFilterColumns = ["deptGrpClrStatus", "employeeAction"];
  const [filterTableInfo, setFilterTableInfo] = useState<DataType[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<any>(
    initialSelectedFilters
  );
  const [appliedFilters, setAppliedFilter] = useState<any>(
    initialSelectedFilters
  );
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
  const [sortKeys, setSortKeys] = useState<any>({
    employeeLocation: "",
    employeeName: "",
    employeeId: "",
    employeeLWD: "",
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setAppliedFilter(initialSelectedFilters);
    setSelectedFilters(initialSelectedFilters);
  }, [props?.currentActivetab]);

  const onClickClearance = (data: any) => {
    const navigateBase = replaceString(
      routePath.SEPARATION_DETAIL_URL,
      SEPARATION_DETAIL_EMP_CODE_REPLACE_STRING,
      data.employeeDexId
    );
    const navigateUrl = replaceString(
      navigateBase,
      SEPARATION_DETAIL_LOCATION_CODE_REPLACE_STRING,
      data.empLocationCode
    );
    navigate(`../${navigateUrl}`);
  };

  const renderColumns = (text: any, record: any, headerId: string) => {
    if (headerId === "deptGrpClrStatus") {
      return (
        <Button
          className="action-btn btn-fonts"
          style={{
            backgroundColor: record?.deptGroupStatus.bgColorCode,
            color: record?.deptGroupStatus.colorCode,
          }}
        >
          {record.deptGroupStatus.label}
        </Button>
      );
    } else if (headerId === "employeeAction") {
      return (
        <Button
          className="btn-fonts"
          style={{
            backgroundColor: record?.action.bgColorCode,
            color: record?.action.colorCode,
          }}
          onClick={() => onClickClearance(record)}
        >
          {record.action.label}
        </Button>
      );
    } else if (headerId === "employeeName") {
      const nameParts = text.split(" ");
      const initials = nameParts
        .map((part: string) => part.charAt(0).toUpperCase())
        .join("");
      return (
        <div className="parentdiv">
          {record.profileImage ? (
            <Avatar
              src={`data:image/png;base64,${record.profileImage}`}
              className="custom-avatar"
            />
          ) : (
            <Avatar className="custom-avatar">{initials}</Avatar>
          )}
          <div className="childdiv">
            <span className="employeename-style">{text}</span>
            <p className="secondary-avatar">{record.employeeTitle}</p>
          </div>
        </div>
      );
    } else {
      return text;
    }
  };

  const getFilterData = (filterKey: string, searchKey: string = "") => {
    return filterTableInfo
      .map((item: any) => {
        if (filterKey === "deptGrpClrStatus") {
          const { deptGroupStatus } = item;
          return {
            text: deptGroupStatus?.label,
            value: deptGroupStatus?.id,
          };
        } else {
          return {
            text: item[filterKey],
            value: item[filterKey],
          };
        }
      })
      .filter(({ text }): any =>
        text?.toLowerCase().includes(searchKey?.toLowerCase())
      )
      .filter(
        ({ text }, index, array): any =>
          array.findIndex((item) => item.text === text) === index
      );
  };

  const handleFilterApply = (headerId: any) => {
    const restValue = selectedFilters[headerId];

    setSelectedFilters({
      ...initialSelectedFilters,
      [headerId]: selectedFilters[headerId],
    });
    let searchText;
    if (Array.isArray(restValue)) {
      searchText = restValue.toString();
    } else {
      searchText = restValue;
    }
    dispatch(
      getLanding({ headerId, searchText, sectionId: props?.currentActivetab })
    );
    setAppliedFilter({
      ...initialSelectedFilters,
      [headerId]: selectedFilters[headerId],
    });
  };

  const handleFilterClear = (headerId: any) => {
    dispatch(
      getLanding({
        headerId,
        searchText: "",
        sectionId: props?.currentActivetab,
      })
    );
    setAppliedFilter({ ...appliedFilters, [headerId]: "" });
    setSelectedFilters({ ...appliedFilters, [headerId]: "" });
  };
  const columns = headerList?.map((header: any) => ({
    title: () => {
      if (header.id === "employeeAction") {
        return header.label;
      }
      return (
        <div className="table_title_sorticon">
          {header.label}{" "}
          {sortingIcon(!employeeDetailList?.length && "svg_disabled")}
        </div>
      );
    },
    dataIndex: header.id,
    key: header.id,
    sorter: () => {},
    defaultSortOrder: header.sortType,
    sortDirections: ["ascend", "descend", "ascend"],
    render: (text: any, record: any) => renderColumns(text, record, header.id),
    filterIcon: (filtered: any, filterProps: any) => {
      return (
        <svg
          width="18"
          height="18"
          className={`${
            isEmpty(appliedFilters[header.id]) ? "" : "filterBlue-svg"
          } ${!employeeDetailList?.length ? "svg_disabled" : ""}`}
          viewBox="0 0 20 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 13.0996C13 13.3648 12.8946 13.6192 12.7071 13.8067C12.5196 13.9943 12.2652 14.0996 12 14.0996H8C7.73478 14.0996 7.48043 13.9943 7.29289 13.8067C7.10536 13.6192 7 13.3648 7 13.0996C7 12.8344 7.10536 12.58 7.29289 12.3925C7.48043 12.205 7.73478 12.0996 8 12.0996H12C12.2652 12.0996 12.5196 12.205 12.7071 12.3925C12.8946 12.58 13 12.8344 13 13.0996ZM17 7.09961C17 7.36483 16.8946 7.61918 16.7071 7.80672C16.5196 7.99425 16.2652 8.09961 16 8.09961H4C3.73478 8.09961 3.48043 7.99425 3.29289 7.80672C3.10536 7.61918 3 7.36483 3 7.09961C3 6.83439 3.10536 6.58004 3.29289 6.3925C3.48043 6.20497 3.73478 6.09961 4 6.09961H16C16.2652 6.09961 16.5196 6.20497 16.7071 6.3925C16.8946 6.58004 17 6.83439 17 7.09961ZM20 1.09961C20 1.36483 19.8946 1.61918 19.7071 1.80672C19.5196 1.99425 19.2652 2.09961 19 2.09961H1C0.734784 2.09961 0.48043 1.99425 0.292893 1.80672C0.105357 1.61918 0 1.36483 0 1.09961C0 0.834393 0.105357 0.580039 0.292893 0.392503C0.48043 0.204966 0.734784 0.0996094 1 0.0996094H19C19.2652 0.0996094 19.5196 0.204966 19.7071 0.392503C19.8946 0.580039 20 0.834393 20 1.09961Z"
            fill="black"
          />
        </svg>
      );
    },
    filters: noFilterColumns.includes(header.id)
      ? []
      : [...getFilterData(header.id, "")],
    filterMultiple: false,
    className: noFilterColumns.includes(header.id)
      ? `hide-filter-icon ${
          header.id === "employeeAction" ? "hide-filter-sorter" : ""
        }`
      : "",

    onFilterDropdownVisibleChange: (visible: boolean) =>
      setIsFilterVisible(header.id === "employeeLWD" && visible ? true : false),
    filterDropdown: (props: any) =>
      employeeDetailList?.length && (
        <TableFilter
          filterProps={props}
          filterId={header.id}
          tableData={employeeInfo}
          onApply={() => handleFilterApply(header.id)}
          onClear={() => {
            // setAppliedFilter(initialSelectedFilters);
            handleFilterClear(header.id);
          }}
          isFilterVisible={isFilterVisible}
          setSelectedFilters={setSelectedFilters}
          selectedFilters={selectedFilters}
          employeeDetailList={employeeDetailList}
        />
      ),
  }));

  const handleChange = (a: any, b: any, sorting: any) => {
    const { order, columnKey } = sorting;
    if (order && employeeDetailList?.length) {
      const sortType = order === "ascend" ? "asc" : "desc";
      const params = {
        headerId: columnKey,
        sortType,
        sectionId: props?.currentActivetab,
        searchText: isArray(appliedFilters[columnKey])
          ? appliedFilters[columnKey].toString()
          : appliedFilters[columnKey],
      };
      setSelectedFilters({
        ...initialSelectedFilters,
        [columnKey]: appliedFilters[columnKey],
      });
      setAppliedFilter({
        ...initialSelectedFilters,
        [columnKey]: appliedFilters[columnKey],
      });
      if (columnKey !== "employeeAction" && sortKeys[columnKey] !== sortType) {
        dispatch(getLanding(params));
        setSortKeys({ ...sortKeys, [columnKey]: sortType });
      }
    }
  };
  let messageText;
  if (props?.currentActivetab === "NOT_COMPLETED") {
    messageText = "No employees found for the user to take action";
  }
  if (props?.currentActivetab === "NO_ACTION") {
    messageText = "No “To be actioned” items to show";
  }
  if (props?.currentActivetab === "PENDING") {
    messageText = "No pending items to show";
  }
  let locale = {
    emptyText: (
      <div>
        {nodatafoundIcon()}
        <p className="no-data-found">{messageText}</p>
      </div>
    ),
  };
  return (
    <div className={!employeeDetailList?.length ? "no_data" : ""}>
      <Table
        locale={locale}
        columns={columns}
        dataSource={employeeDetailList}
        rowKey="employeeId"
        pagination={false}
        className="pagination-styles table-styles"
        onChange={handleChange}
        loading={separationLanding.loading}
      />
      {/* <div className="pagination-bg">
        <Pagination />
      </div> */}
    </div>
  );
};

export default ContentTable;
