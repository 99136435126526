import React from "react";
import "./ErrorComponent.css";

const ErrorComponent = (props: any) => {
  return (
    <div className="errorSubContainer">
      <img src={props?.image} className="errorImage" alt="Error Image" />
      <div>
        <h1 className="errorHeading">{props?.title}</h1>
        <h2 className="errorSubHeading">{props?.description}</h2>
      </div>
    </div>
  );
};

export default ErrorComponent;
