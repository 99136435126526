import { useEffect, useState } from "react";
import PrivateLayout from "../layouts/Private.Layout";
import { useNavigate, useLocation } from "react-router-dom";
import n from "../navigation/Route.Names";
import {
  callLoginSAMLApi,
  getJWTTokenForLoggedinUser,
} from "../../redux/actions/auth";
import { useAppDispatch, useAppSelector } from "../../redux/store/redux-hooks";
import * as util from "../utils/CheckEmptyConditions";
import { setInLocalStroage, getFromLocalStorage } from "../utils/util";
import UnAuthrorizedScreen from "../layouts/Unauthorized.Layout";
import {
  ALTIVERSE,
  LOGOUT_URL,
  REDIRECTION_PAGE,
  SEPARATION_UI,
  START,
  USERJWT,
} from "../../constants";

function RoutesScreeen() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [isTrue, setisState] = useState(true);
  const search = location.search;
  const pathname = location.pathname;

  const SAMLRefId: any = new URLSearchParams(search).get("samlrefid");

  const samlAuthResponse = useAppSelector(
    (state: any) => state.auth.samlAuthResponse
  );
  const authJWTResponse = useAppSelector(
    (state: any) => state.auth.authJWTResponse
  );
  const authJWTResponseError = useAppSelector(
    (state: any) => state.auth.authJWTResponseError
  );

  useEffect(() => {
    //when we redirect from saml page after successful login
    if (util.checkStringHasValue(SAMLRefId) && isTrue) {
      dispatch(getJWTTokenForLoggedinUser(SAMLRefId));
    } else {
      if (!getFromLocalStorage(USERJWT) && isTrue) {
        // setInLocalStroage(REDIRECTION_PAGE, pathname);
        dispatch(callLoginSAMLApi());
      }
    }
  }, []);

  //when doing logout
  useEffect(() => {
    if (
      !getFromLocalStorage(USERJWT) &&
      !util.checkStringHasValue(SAMLRefId) &&
      pathname !== `/${n.LOGOUT_URL}` &&
      isTrue
    ) {
      // setInLocalStroage(REDIRECTION_PAGE, pathname);
      dispatch(callLoginSAMLApi());
    }
  }, [getFromLocalStorage(USERJWT)]);

  useEffect(() => {
    if (util.checkObjectHasValue(samlAuthResponse)) {
      window.location.href = samlAuthResponse.loginUrl;
    }
  }, [samlAuthResponse]);

  //If User JWT returns
  useEffect(() => {
    if (authJWTResponse) {
      afterSuccessLogin();
    }
  }, [authJWTResponse, authJWTResponseError]);

  const afterSuccessLogin = async () => {
    // const decoded = await jwtDecode<any>(authJWTResponse); // Returns with the JwtPayload type
    // const {apiToken,...restInfo} = decoded // Returns with the JwtPayload type
    await setInLocalStroage(USERJWT, authJWTResponse);
    // await setInLocalStroage(LOGGEDINUSERDETAILS, JSON.stringify(restInfo));
    /* const redirectionpage= await getFromLocalStorage(REDIRECTION_PAGE);
    if(redirectionpage===`/${n.START}/${n.SEPARATION_URL}`){
      navigate(`${n.START}/${n.SEPARATION_URL}`)
    } else if(redirectionpage===`/${n.START}/${n.CERTIFICATION_URL}`){
      navigate(`${n.START}/${n.CERTIFICATION_URL}`)
    }else{
      navigate(`${n.START}/${n.CERTIFICATION_URL}`)
    }*/
    const redirectionpage = await getFromLocalStorage(REDIRECTION_PAGE);
    if (
      redirectionpage === `/${SEPARATION_UI}/${n.SEPARATION_URL}` ||
      redirectionpage === `/${SEPARATION_UI}/${n.SEPARATION_URL}/`
    ) {
      // navigate(`${SEPARATION_UI}/${n.SEPARATION_URL}`);
      window.location.href = `/${SEPARATION_UI}/${n.SEPARATION_URL}`;
    } else if (redirectionpage === `/${SEPARATION_UI}/${n.CERTIFICATION_URL}`) {
      // navigate(`${SEPARATION_UI}/${n.CERTIFICATION_URL}`);
      window.location.href = `/${SEPARATION_UI}/${n.CERTIFICATION_URL}`;
    } else {
      //navigate(`${n.START}/${n.CERTIFICATION_URL}`)
      navigate(`${START}/${ALTIVERSE}`);
    }
  };

  return (
    <div>
      {isTrue && getFromLocalStorage(USERJWT) && <PrivateLayout />}
      {isTrue && util.checkStringHasValue(authJWTResponseError) && (
        <UnAuthrorizedScreen />
      )}
      {isTrue && pathname === `/${START}/${LOGOUT_URL}` && (
        <UnAuthrorizedScreen />
      )}
      {!isTrue && <PrivateLayout />}
    </div>
  );
}

export default RoutesScreeen;
