import { createSlice, current } from "@reduxjs/toolkit";
import {
  getHeaderNotification,
  getWebLandingDetail,
  putHeaderNotificationMark,
} from "../actions/web_landing";
import { QUICKTOUR } from "../../constants";

type cleranceDetailState = {
  web_landing_details: any;
  card_detail: any;
  loading: boolean;
  error: null;
  activeTab: any;
  quickMenuExpanded: boolean;
  quickTour: boolean;
  headerNotificationList: any;
  loadingNotification: boolean;
};

const initialDetailState: cleranceDetailState = {
  web_landing_details: {},
  card_detail: {},
  loading: true,
  error: null,
  activeTab: { detail: "", index: "" },
  quickMenuExpanded: false,
  quickTour: localStorage?.getItem(QUICKTOUR) === "true",
  headerNotificationList: {},
  loadingNotification: false,
};

export const web_landing_details: any = createSlice({
  name: "web_landing_details",
  initialState: initialDetailState,
  reducers: {
    setQuickTour: (state, action) => {
      state.quickTour = action?.payload;
    },
    setQuickMenuMode: (state, action) => {
      state.quickMenuExpanded = action?.payload;
    },
    setWebLandingActiveTab: (state, action) => {
      state.activeTab = action?.payload;
    },
    isCommentShow: (state, action) => {
      const { index } = action.payload;
      const isComment = state.web_landing_details["justOut"]["details"][index];
      if (isComment && isComment["isComment"]) {
        state.web_landing_details["justOut"]["details"][index]["isComment"] =
          !isComment;
      } else {
        state.web_landing_details["justOut"]["details"][index]["isComment"] =
          true;
      }
    },
    bookmarkAction: (state, action) => {
      const { index } = action.payload;
      const isbookMark =
        state.web_landing_details["justOut"]["details"][index][
          "bookMarkIndicator"
        ];
      state.web_landing_details["justOut"]["details"][index][
        "bookMarkIndicator"
      ] = !isbookMark;
    },
    likesAction: (state, action) => {
      const { index } = action.payload;
      const isLike =
        state.web_landing_details["justOut"]["details"][index]["likesCount"];
      state.web_landing_details["justOut"]["details"][index]["likesCount"] =
        isLike + 1;
    },
    commentAction: (state, action) => {
      const { index } = action.payload;
      const isComment =
        state.web_landing_details["justOut"]["details"][index]["commentsCount"];
      state.web_landing_details["justOut"]["details"][index]["commentsCount"] =
        isComment + 1;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getWebLandingDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getWebLandingDetail.fulfilled, (state: any, action) => {
      state.loading = false;
      const { justOutm, ...restResponse } = action.payload;
      state.web_landing_details = restResponse;
    });
    builder.addCase(getWebLandingDetail.rejected, (state: any, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getHeaderNotification.pending, (state) => {
      state.loadingNotification = true;
    });
    builder.addCase(getHeaderNotification.fulfilled, (state: any, action) => {
      state.loadingNotification = false;
      const { page, result } = action?.payload;
      if (page <= 1) {
        state.headerNotificationList = result;
        state.headerNotificationList.page = page + 1;
      } else {
        state.headerNotificationList.hasNextPage = result?.hasNextPage;
        state.headerNotificationList.hasPending = result?.hasPending;
        state.headerNotificationList.notifications = [
          ...state.headerNotificationList?.notifications,
          ...result?.notifications,
        ];
        state.headerNotificationList.page = page + 1;
      }
    });
    builder.addCase(getHeaderNotification.rejected, (state: any, action) => {
      state.loadingNotification = false;
    });
    builder.addCase(putHeaderNotificationMark.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      putHeaderNotificationMark.fulfilled,
      (state: any, action) => {
        state.loading = false;
        const { result, notificationId } = action?.payload;
        if (notificationId) {
          const stateNotifications = state.headerNotificationList.notifications;
          state.headerNotificationList = result;
          const [extractNotifications] = result.notifications;
          const finalNotifications = stateNotifications.map((item) => {
            if (item.notificationId === extractNotifications.notificationId) {
              return { ...item, ...extractNotifications };
            } else {
              return { ...item };
            }
          });
          state.headerNotificationList.notifications = finalNotifications;
        } else {
          state.headerNotificationList = result;
        }
      }
    );
    builder.addCase(
      putHeaderNotificationMark.rejected,
      (state: any, action) => {
        state.loading = false;
      }
    );
  },
});

export default web_landing_details.reducer;

export const {
  isCommentShow,
  commentAction,
  bookmarkAction,
  likesAction,
  setWebLandingActiveTab,
  setQuickMenuMode,
  setQuickTour,
} = web_landing_details.actions;
