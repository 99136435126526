import { useState } from "react";
import { useNavigate } from "react-router-dom";
import EditButton from "../button/EditButton";
import EditImage from "../../../themes/assets/images/svg/edit.svg";
import DeleteImage from "../../../themes/assets/images/svg/Certificatedelete.svg";
import { useAppDispatch } from "../../../redux/store/redux-hooks";
import { download, editCertification } from "../../../redux/reducers/certification";
import { deleteCertifications } from "../../../redux/actions/certification";
import {
  START,
  EDIT_CERTIFICATION_URL,
  CANCEL,
  DELETE_TITLE,
  DELETE_MESSAGE,
  DELETE,
  CERTIFICATE_DELETE_SUCCESSFULL,
  DONE,
} from "../../../constants";
import DeleteButton from "../button/DeleteButton";
import ModalDialog from "../modal/modal";
import { isValidDate } from "../../utils/util";

function CertificateList(props: any) {
  const { isEdit, certifications = [] } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const editAction = (certification_id: string) => {
    dispatch(editCertification({ certification_id }));
    navigate(`/${START}/${EDIT_CERTIFICATION_URL}`);
  };

  const [isPopup, setPopup] = useState({
    isConfirm: false,
    isNotification: false,
    certification_id: "",
  });

  const showModal = (certification_id: string) => {
    setPopup({ ...isPopup, isConfirm: true, certification_id });
  };

  const handleOk = () => {
    if (isPopup.isConfirm) {
      setPopup({ ...isPopup, isConfirm: false });
      dispatch(
        deleteCertifications({ certification_id: isPopup.certification_id })
      )
        .unwrap()
        .then(() => {
          // setPopup({
          //   ...isPopup,
          //   isConfirm: false,
          //   isNotification: true,
          //   certification_id: "",
          // });
        });
    } else {
      setPopup({ ...isPopup, isNotification: false });
    }
  };

  const handleCancel = () => {
    if (isPopup.isConfirm) {
      setPopup({ ...isPopup, isConfirm: false });
    } else {
      setPopup({ ...isPopup, isNotification: false });
    }
  };
  const onDownloadHandler = (attachment:any) => {
   dispatch(download(attachment))
  
  }

  return (
    <div>
      {certifications.map((item: any, index: number) => (
        <div className="certification-listsection" key={index}>
          <div className="certification-leftlist">
            <p className="certification-listheading">{item.name}</p>
            <p className="certification-listpara">{item.institution}</p>
            {item.startDate && (
              <p className="certification-listdate">
                Effective on :{" "}
                <span>
                  {isValidDate(item?.startDate)}
                </span>
              </p>
            )}
            {item.fileName && (<p className="certification-listdate">Certificate : <a onClick={()=>onDownloadHandler(item)}>{item.fileName}</a></p>)}
          </div>
          
          {isEdit && (
            <div className="certification-rightlist">
              <EditButton
                editBtnAction={() => editAction(item.certificateId)}
                editImage={EditImage}
              />
              <DeleteButton
                deleteBtnAction={() => showModal(item.certificateId)}
                DeleteImage={DeleteImage}
              />
            </div>
          )}
        </div>
      ))}
      <ModalDialog
        openModal={isPopup.isConfirm || isPopup.isNotification}
        DialogHeading={isPopup.isConfirm ? DELETE_TITLE : null}
        closeIcon={false}
        modalImageStyles={{ display: "none" }}
        modalHeadingStyles={{ display: "none" }}
        msgContent={
          isPopup.isConfirm ? DELETE_MESSAGE : CERTIFICATE_DELETE_SUCCESSFULL
        }
        okTxt={isPopup.isConfirm ? DELETE : DONE}
        cancelTxt={CANCEL}
        cancelBtn={handleCancel}
        okBtn={handleOk}
        onOk={handleOk}
        isSingleButton={isPopup.isNotification}
        isCancel={isPopup.isNotification}
        onCancel={handleCancel}
        modalStyles={"backpopup"}
        maskClosable={false}
      />
    </div>
  );
}

export default CertificateList;
