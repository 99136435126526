import React, { useState, useEffect } from "react";
import { Button, Checkbox, Space, Radio, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import { APPLY_BUTTON, CLEAR_BUTTON } from "../../../../constants";
import moment from "moment";
import CalendarWithReferenceDates from "./CustomCalendar";

const initialSelectedFilters = {
  employeeLocation: [],
  employeeName: "",
  employeeId: "",
};

const FilterDropdown = (props: any) => {
  const [filterSearchValue, setFilterSearchValue] = useState("");
  const {
    filterProps,
    filterId,
    tableData,
    onApply,
    onClear,
    setSelectedFilters,
    selectedFilters,
    isFilterVisible,
  } = props;
  const [currentFilteredList, setCurrentFilteredList] = useState([]);
  const [tableLwds, setTableLwds] = useState([]);

  useEffect(() => {
    if (filterId === "employeeLWD" && tableData?.length) {
      const lwds = tableData.reduce((acc: any, item: any) => {
        const parsedDate = item.employeeLWDDate
          ? moment(item.employeeLWDDate, "YYYY-MM-DD").format("DD/MM/YYYY")
          : "";
        return item.employeeLWDDate ? [...acc, parsedDate] : acc;
      }, []);
      if (lwds?.length) {
        setTableLwds(lwds);
      }
    } else {
      setTableLwds([]);
    }
  }, [filterId]);

  useEffect(() => {
    const { employeeId, employeeName, employeeLocation = [] } = selectedFilters;

    if (employeeName) {
      const data = tableData?.length
        ? tableData.filter(
            (ele: any) =>
              employeeName.length > 0 && ele.employeeName === employeeName
          )
        : [];
      setCurrentFilteredList(data);
      return;
    }
    if (employeeId) {
      const data = tableData?.length
        ? tableData.filter(
            (ele: any) => employeeId.length > 0 && ele.employeeId === employeeId
          )
        : [];
      setCurrentFilteredList(data);
      return;
    }

    if (employeeLocation.length > 0) {
      const data = tableData?.length
        ? tableData.filter((ele: any) =>
            employeeLocation.some((employeeLocation: string) =>
              ele.employeeLocation.includes(employeeLocation)
            )
          )
        : [];
      setCurrentFilteredList(data);
      return;
    }
  }, [selectedFilters]);

  useEffect(() => {
    return () => {
      setSelectedFilters(initialSelectedFilters);
      filterProps.clearFilters();
      setFilterSearchValue("");
    };
  }, []);

  const renderFilterContainer = (
    filterId: string,
    searchKey: string,
    filterData: any
  ) => {
    const showCheckbox = filterId === "employeeLocation";
    if (filterData.length === 0) {
      return (
        <p className="no_matched_records">
          No employee details match your search. Check and try again.
        </p>
      );
    }

    const filters = showCheckbox
      ? [{ text: "Select All", value: "all" }, ...filterData]
      : filterData;

    let filterDropdown = filters?.length
      ? filters.map((filter: { value: string; text: string }) =>
          showCheckbox ? (
            <Checkbox
              className="landing-checkbox-group"
              key={filter.value}
              checked={selectedFilters[filterId]?.includes(filter.value)}
              onChange={(e) => {
                let updatedValues = e.target.checked
                  ? [...(selectedFilters[filterId] || []), filter.value]
                  : selectedFilters[filterId]?.filter((value: string) =>
                      value === "all" ? false : value !== filter.value
                    );

                if (filter.value === "all") {
                  updatedValues = e.target.checked
                    ? filters.map((filter: any) => filter.value)
                    : [];
                } else if (
                  filterData.length === updatedValues.length &&
                  !updatedValues.includes("all")
                ) {
                  updatedValues.push("all");
                }
                setSelectedFilters({
                  ...selectedFilters,
                  [filterId]: updatedValues,
                });
              }}
            >
              {filter.text}
            </Checkbox>
          ) : (
            <Radio
              className="landing-radio-group"
              key={filter.value}
              checked={selectedFilters[filterId]?.includes(filter.value)}
              onChange={(e) => {
                setSelectedFilters({
                  ...selectedFilters,
                  [filterId]: e.target.checked && filter.value,
                });
              }}
            >
              {filter.text}
            </Radio>
          )
        )
      : [];
    return filterDropdown;
  };

  const getFilterData = (filterKey: string, searchKey: string = "") => {
    return tableData?.length
      ? tableData
          .map((item: any) => {
            if (filterKey === "deptGrpClrStatus") {
              const { deptGroupStatus } = item;
              return {
                text: deptGroupStatus?.label,
                value: deptGroupStatus?.id,
              };
            } else {
              return {
                text: item[filterKey],
                value: item[filterKey],
              };
            }
          })
          .filter(({ text }: any): any =>
            text?.toLowerCase().includes(searchKey?.toLowerCase())
          )
          .filter(
            ({ text }: any, index: number, array: any): any =>
              array.findIndex((item: any) => item.text === text) === index
          )
      : [];
  };

  const filterData = getFilterData(filterId, filterSearchValue) || [];

  console.log("selectedFilters[filterId", selectedFilters[filterId]);

  return (
    <div
      className={`${filterId === "employeeLWD" && "search-lwd"} search-card`}
    >
      {filterId !== "employeeLWD" ? (
        <>
          <Input
            placeholder="Search"
            value={filterSearchValue}
            onChange={(e) => {
              filterProps.setSelectedKeys(
                e.target.value ? [e.target.value] : []
              );
              setFilterSearchValue(e.target.value);
            }}
            onPressEnter={() => filterProps.confirm()}
            suffix={<SearchOutlined />}
          />
          <div className="filterWrapper">
            {renderFilterContainer(
              filterId,
              filterProps.selectedKeys[0],
              filterData
            )}
          </div>
        </>
      ) : (
        <CalendarWithReferenceDates
          referenceDates={tableLwds}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          visible={filterId === "employeeLWD" && isFilterVisible}
        />
      )}
      <div>
        <Space>
          <Button
            onClick={() => {
              filterProps.setSelectedKeys([]);
              setSelectedFilters(initialSelectedFilters);
              filterProps.clearFilters();
              onClear(true);
              setFilterSearchValue("");
            }}
            size="small"
            className="clear-btn"
            disabled={
              !(filterSearchValue || !isEmpty(selectedFilters[filterId]))
            }
          >
            {CLEAR_BUTTON}
          </Button>
          <Button
            type="primary"
            className="filter_apply_btn"
            onClick={() => {
              filterProps.confirm();
              filterProps.setSelectedKeys([]);
              onApply(currentFilteredList);
              setFilterSearchValue("");
            }}
            size="small"
            disabled={isEmpty(selectedFilters[filterId]) || !filterData?.length}
          >
            {APPLY_BUTTON}
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default FilterDropdown;
