import { Modal } from "antd";
import FillButton from "../../common/button/FillButton";
import OutlineButton from "../../common/button/OutlineButton";
import { Link, useLocation } from "react-router-dom";
import { START } from "../../../constants";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";

const CommentDialog = (props) => {
  const { indexDetails = [], index: currentPage, goalValues } = props;
  const items = [
    {
      index: 1,
      title: "Goal Name",
      content: goalValues?.["Goal Name"] || goalValues?.goalName,
    },
    {
      index: 2,
      title: "Outcome",
      content: goalValues?.["Outcome"] || goalValues?.outcome,
    },
    {
      index: 3,
      title: "Weightage",
      content: `${goalValues?.["Weight"] || goalValues?.weight}%`,
    },
  ];

  let location = useLocation();
  const { pathname } = location;
  //   const pageIndex = pathname === `/${START}/altiverse/EbookIntroduction`;
  const pageIndex = 1;
  const indexNavigation = (e, index) => {
    e.preventDefault();
    props.onOk(index);
  };

  return (
    <>
      <Modal
        className={props.modalStyles}
        open={props.openModal}
        footer={null}
        closable={props.closeIcon}
        onOk={props.onOk}
        onCancel={props.onCancel}
        maskClosable={props.maskClosable}
        centered={true}
      >
        <h5 className={props.modalSubHeading}>{props.DialogHeading}</h5>
        <div className="goalcontent">
          {/* Rendering items */}
          {items.map((item, index) => (
            <p key={index} className={props.modalContent}>
              <strong>{item.title}: </strong> {item.content}
            </p>
          ))}
        </div>
        {pageIndex && (
          <div style={props.popupDivider} className="popup-divider"></div>
        )}
        <p className={props.modalContent}>{props.msgContent}</p>

        <div
          className={
            props.isSingleButton
              ? "button-section single-button-section"
              : "button-section"
          }
        >
          {props.isEdit && (
            <OutlineButton
              className={props.displayOutline}
              backText={props.editBtn}
              outlineBtnAction={props.editAction}
            />
          )}
          {!props.isCancel ? (
            <FillButton fillBtnTxt={props.okTxt} fillBtnAction={props.okBtn} />
          ) : (
            <OutlineButton
              className={props.displayOutline}
              backText={props.cancelTxt}
              outlineBtnAction={props.cancelBtn}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default CommentDialog;
