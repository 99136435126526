import { useEffect } from 'react';
import { notification } from 'antd';


const NotificationComponent = (props) => { 
    const [api, contextHolder] = notification.useNotification();
    const {type,message} = props;
    useEffect(()=>{
      api[type]({
        duration:2,
        description: message,
        placement:'center',
        onClose:props.onClose
      });
    },[])
    return (<>
    {contextHolder}
    </>)
}

export default NotificationComponent