import { createSlice } from "@reduxjs/toolkit";
import { getDownTimeNotification } from "../actions/notification";

type cleranceDetailState = {
  message: any;
};

const initialDetailState: cleranceDetailState = {
  message: "",
};

export const notification: any = createSlice({
  name: "notification",
  initialState: initialDetailState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(
      getDownTimeNotification.pending,
      (state: any, action: any) => {}
    );
    builder.addCase(getDownTimeNotification.fulfilled, (state: any, action) => {
      state.message = action?.payload;
    });
    builder.addCase(
      getDownTimeNotification.rejected,
      (state: any, action) => {}
    );
  },
});

export default notification.reducer;
//export const { typeChangeAction,deductionAction,remarksChangeAction,notificationAction,beforeUploadAction,onChangeAction,onRemoveAction } = detail.actions;
