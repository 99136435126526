import { CurrentMonthIcon } from "../../../../common/svg/CurrentMonthIcon";
import { ExpandIcon } from "../../../../common/svg/ExpandIcon";
import { LastMonthIcon } from "../../../../common/svg/LastMonthIcon";
import LineChartComponent from "./LineChartComponent";
import PieChartComponent from "./PieChartComponent";
import "./PointsSummary.css";
import { getPointsLineChartData } from "../../../../../redux/actions/gamification";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store/redux-hooks";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  CURRENT_MONTH_TEXT,
  PIECHARTCOLLAPSEBUTTON,
  PIECHARTEXPANDBUTTON,
  PREVIOUS_MONTH_TEXT,
} from "../../../../../constants";
import { Skeleton } from "antd";
import { CollapseIcon } from "../../../../common/svg/CollapseIcon";

const PointsSummaryDetails = () => {
  const [toggle, setToggle] = useState(true);
  const {
    loading,
    error,
    pointSummaryData,
    PointsLineChartData,
    loadingSummaryData,
  } = useAppSelector((state: any) => state.gamification);

  const pointsData = pointSummaryData?.data;
  const pointsSummaryColor = pointSummaryData?.data;
  const pointsLineChart = PointsLineChartData?.data;

  return (
    <>
      <div className="points-summary-left-area">
        <div
          className={
            !toggle
              ? "points-summary-doughnut toggle-height"
              : "points-summary-doughnut "
          }
        >
          <div className="points-summary-doughnut-area">
            {loadingSummaryData || loading ? (
              <div className="pie-skeleton-container">
                <Skeleton.Button
                  active={true}
                  className="circle-pie-skeleton"
                />
              </div>
            ) : (
              <PieChartComponent pieChartData={pointsData} />
            )}
          </div>
          <div className="points-summary-doughnut-summary">
            <ul>
              {!loadingSummaryData || !loading
                ? pointsData?.Game_Summary?.map((game: any, gameIndex: any) => {
                    if (toggle && gameIndex > PIECHARTEXPANDBUTTON - 1) {
                      return null;
                    }
                    return (
                      <li key={gameIndex}>
                        <span
                          style={{
                            background: game.colourCode
                              ? game.colourCode
                              : null,
                          }}
                          className="doughnut-circle doughnut-profilebg"
                        ></span>
                        <span className="points-summary-doughnut-label">
                          {game.gameName} -{" "}
                          <b className="points-summary-doughnut-label-value">
                            {game.points}
                          </b>
                        </span>
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>
          {pointsData?.Game_Summary?.length >= PIECHARTEXPANDBUTTON &&
            toggle && (
              <button
                onClick={() => setToggle(!toggle)}
                className="points-dasboard-expand"
              >
                Expand <ExpandIcon />
              </button>
            )}
          {pointsData?.Game_Summary.length >= PIECHARTCOLLAPSEBUTTON &&
            !toggle && (
              <button
                onClick={() => setToggle(!toggle)}
                className="points-dasboard-expand"
              >
                Collapse <CollapseIcon />
              </button>
            )}
        </div>
        <div className="points-summary-monthly">
          {toggle &&
            pointsData?.Points_Trend?.map(
              (pointsTrend: any, pointsTrendIndex: any) => {
                return (
                  <div
                    key={pointsTrendIndex}
                    className="points-summary-monthly-card"
                  >
                    <div className="points-monthly-text-row">
                      <div className="points-monthly-text">
                        {pointsTrend?.trendMonth === "PREVIOUS"
                          ? PREVIOUS_MONTH_TEXT
                          : CURRENT_MONTH_TEXT}
                      </div>
                      <div className="points-monthly-icon-area">
                        {pointsTrend?.trendType === "NEGATIVE" ? (
                          <LastMonthIcon />
                        ) : pointsTrend?.trendType === "NEUTRAL" ? (
                          <CurrentMonthIcon />
                        ) : (
                          <CurrentMonthIcon />
                        )}
                        <span className="points-monthly-percentage">
                          {pointsTrend?.trend} %
                        </span>
                      </div>
                    </div>
                    <div className="points-monthly-value">
                      <b>{pointsTrend?.points}</b> pts
                    </div>
                  </div>
                );
              }
            )}
        </div>
        <div className="points-summary-line-chart">
          <div className="points-summary-line-chart-static">
            <div className="points-summary-line-chart-text">Last 6 Months</div>
            <div className="points-summary-line-chart-text">
              Click the month to view points
            </div>
          </div>
          {loading || loadingSummaryData ? (
            <div className="skeleton-chart">
              <Skeleton.Button
                active={loading}
                className="skelton-chart-loading"
              />
            </div>
          ) : (
            <LineChartComponent lineChartData={pointsLineChart} />
          )}
        </div>
      </div>
    </>
  );
};

export default PointsSummaryDetails;
