import moment from "moment";
import {
  DRAFT,
  PRIVATE,
  PUBLIC,
  USERJWT,
  WEIGHTAGE_EXCEEDING,
} from "../../constants";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { CERTIFICATION_DATE_FORMAT } from "../../constants";
import dayjs from "dayjs";

export const isValidDate = (dateString: any) => {
  let newdateString = dateString;
  if (typeof dateString !== "string") newdateString = dateString["$d"];
  return moment(newdateString).format(CERTIFICATION_DATE_FORMAT);
};

const leadingZero = (number: any) => {
  return number > 9 ? number : `0${number}`;
};
export const dateFormat = (data: any) => {
  let requestDate = new Date(data["$d"]);
  let format_date = requestDate.getDate();
  let format_month = requestDate.getMonth() + 1;
  let format_year = requestDate.getFullYear();
  return `${format_year}-${leadingZero(format_month)}-${leadingZero(
    format_date
  )}`;
};

export const replaceString = (
  str: string,
  sString: string,
  rString: string
) => {
  return str.replace(sString, rString);
};

export const isRadioButtonChecked = (list: any, type: string) => {
  if (Array.isArray(list)) {
    const data = [...list];
    let isSlectedCard = data.filter((item) => {
      return item.clearanceStatus !== "" && item.clearanceStatus !== null;
    });
    if (type === "change") {
      if (list.length === isSlectedCard.length) {
        const previousStatus = checkPreviousStatus(isSlectedCard);
        return previousStatus.length > 0 ? false : true;
      }
      return list.length === isSlectedCard.length ? false : true;
    } else {
      if (isSlectedCard.length === 0) {
        return true;
      } else if (list.length === isSlectedCard.length) {
        const previousStatus = checkPreviousStatus(isSlectedCard);
        return previousStatus.length > 0 ? false : true;
      }
      return list.length === isSlectedCard.length ? false : true;
    }
  } else {
    return false;
  }
};

export const checkPreviousStatus = (selectedCard: any) => {
  const previousStatus = selectedCard.filter((item: any) => {
    return item.clearanceStatus !== item.tempclearanceStatus;
  });
  return previousStatus;
};

export const getLoggedInUserDetails = () => {
  let details: any = localStorage.getItem("loggedInUserDetails");
  if (details) {
    return JSON.parse(details);
  } else {
    return null;
  }
};

export const setInLocalStroage = (keyName: string, value: any) => {
  return localStorage.setItem(keyName, value);
};

export const getFromLocalStorage = (keyName: string) => {
  return localStorage.getItem(keyName);
};

export const removeFromLocalStorage = (keyValue: string) => {
  return localStorage.removeItem(keyValue);
};

export function formatTimeDifference(inputTime: string): string {
  const currentTime: Date = new Date();
  const inputDate: Date = new Date(inputTime);
  const timeDifference: number = currentTime.getTime() - inputDate.getTime();
  const minutesDifference: number = Math.floor(timeDifference / (60 * 1000));

  if (minutesDifference === 1) {
    return "1 min ago";
  } else if (minutesDifference < 60) {
    return `${minutesDifference} mins ago`;
  } else if (minutesDifference === 60) {
    return "1 hr ago";
  } else if (minutesDifference < 60 * 24) {
    const hoursDifference: number = Math.floor(minutesDifference / 60);
    return `${hoursDifference} hrs ago`;
  } else {
    const dd: string = String(inputDate.getDate()).padStart(2, "0");
    const mm: string = String(inputDate.getMonth() + 1).padStart(2, "0");
    const yy: string = String(inputDate.getFullYear()).slice(-2);
    return `${dd}/${mm}/${yy}`;
  }
}
export const getWordLetter = (name: string) => {
  if (!name) return "";
  const avatarName = name
    .split(" ")
    .map((name) => name[0])
    .join("")
    .toUpperCase();
  return avatarName?.length != 1
    ? avatarName.substring(1, 2) === "."
      ? avatarName.substring(0, 1)
      : avatarName.substring(0, 2)
    : avatarName + avatarName;
};

export const DateFormat = (date: any) => {
  return moment(date).format("DD MMM YYYY");
};

export const getLoggedInUserData = () => {
  const authJWTResponse: any = localStorage.getItem(USERJWT);
  const userData: any = authJWTResponse
    ? jwtDecode<JwtPayload>(authJWTResponse)
    : "";
  return userData;
};

export const maxBy = (arr, fn) =>
  Math.max(...arr.map(typeof fn === "function" ? fn : (val) => val[fn]));

export const newPageOptions = (totalItems, value) =>
  Array.from({ length: Math.ceil(totalItems / value) }, (_, index) =>
    (index + 1).toString()
  );

export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number
) => {
  let timeout;

  return (...args: Parameters<F>): Promise<ReturnType<F>> =>
    new Promise((resolve) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => resolve(func(...args)), waitFor);
    });
};

export const get_time_difference = (recentDate: any) => {
  try {
    var serverDate = new Date(recentDate);
    var localDate = new Date();

    var diff = localDate.getTime() - serverDate.getTime();

    var msec = diff;
    var hh: any = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    var mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    var ss = Math.floor(msec / 1000);
    msec -= ss * 1000;

    return hh;
  } catch (e) {
    console.log(e);
  }
};

export const get_time_difference_mm = (recentDate: any) => {
  try {
    var serverDate = new Date(recentDate);
    var localDate = new Date();

    var diff = localDate.getTime() - serverDate.getTime();
    if (diff > 0) {
      var msec = diff;
      var hh = Math.floor(msec / 1000 / 60 / 60);
      msec -= hh * 1000 * 60 * 60;
      var mm: any = Math.floor(msec / 1000 / 60);
      msec -= mm * 1000 * 60;
      var ss = Math.floor(msec / 1000);
      msec -= ss * 1000;

      return mm;
    } else {
      return 0;
    }
  } catch (e) {
    console.log(e);
  }
};

export function getDateFor(timeStamp, formatType = "DD MMM YYYY") {
  const currentTimestamp = moment().format();
  const oneSecond = 1; //seconds
  const oneMinute = 60; //seconds
  const oneHour = 3600; //seconds
  const oneDay = 86400; //seconds
  const oneWeek = 604800; //seconds
  if (timeStamp && currentTimestamp) {
    const diffInSeconds = moment(currentTimestamp).diff(timeStamp, "seconds");
    const diffInMinutes = moment(currentTimestamp).diff(timeStamp, "minutes");
    const diffInHours = moment(currentTimestamp).diff(timeStamp, "hours");
    const diffInDays = moment(currentTimestamp).diff(timeStamp, "days");

    if (diffInSeconds <= oneMinute) {
      return "just now";
    } else if (diffInSeconds <= oneHour) {
      return diffInMinutes === 1
        ? `${diffInMinutes} min ago`
        : `${diffInMinutes} mins ago`;
    } else if (diffInSeconds <= oneDay) {
      return diffInHours === 1
        ? `${diffInHours} hour ago`
        : `${diffInHours} hours ago`;
    } else if (diffInSeconds <= oneWeek) {
      return diffInDays === 1
        ? `${diffInDays} day ago`
        : `${diffInDays} days ago`;
    } else if (diffInSeconds > oneWeek) {
      return moment(timeStamp).format(formatType);
    } else {
      return "";
    }
  }
}

export default function capitalizeFirstLetter(sentence) {
  var words = sentence.split(" ");
  var capitalizedWords = words.map(function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  var capitalizedSentence = capitalizedWords.join(" ");

  return capitalizedSentence;
}

export const skillArrayToobject = (skills: any) => {
  let rv: any = {};
  for (let i = 0; i < skills.length; ++i) rv["skill" + (i + 1)] = skills[i].key;
  return rv;
};

export const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const base64ToFile = (base64: any, fileName: any, mimeType: any) => {
  var mime = mimeType,
    bstr = atob(base64),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  let base = new File([u8arr], fileName, { type: mime });
  return [base];
};

export const formatCertificate = (listCertifications: any, listskills: any) => {
  const formattedCertifications = listCertifications.map((item: any) => {
    const { name, institution, description, startDate, endDate } = item;
    let basic: any = {
      name: name ? name : "",
      institution: institution ? institution : "",
      description: description ? description : "",
      startDate: startDate ? startDate : null,
      endDate: endDate ? endDate : null,
      upload: [],
      isNew: false,
      fileName: "",
      certificateId: item.certificateId,
    };
    const skills = [];
    if (item?.skill1) {
      const skill1: never[] = listskills.filter(
        (ele: any) => ele.key === item?.skill1
      );
      if (skill1.length > 0) {
        skills.push(skill1[0]);
      }
    }
    if (item?.skill2) {
      const skill2: never[] = listskills.filter(
        (ele: any) => ele.key === item?.skill2
      );
      if (skill2.length > 0) {
        skills.push(skill2[0]);
      }
    }
    if (item?.skill3) {
      const skill3: never[] = listskills.filter(
        (ele: any) => ele.key === item?.skill3
      );
      if (skill3.length > 0) {
        skills.push(skill3[0]);
      }
    }
    if (item?.skill4) {
      const skill4: never[] = listskills.filter(
        (ele: any) => ele.key === item?.skill4
      );
      if (skill4.length > 0) {
        skills.push(skill4[0]);
      }
    }
    if (item?.skill5) {
      const skill5: never[] = listskills.filter(
        (ele: any) => ele.key === item?.skill5
      );
      if (skill5.length > 0) {
        skills.push(skill5[0]);
      }
    }
    basic["skill"] = skills;
    if (item.sfAttachment && item.sfAttachment?.fileContent) {
      const { fileContent, fileName, mimeType, attachmentId } =
        item.sfAttachment;
      const sfAttachment = {
        fileName: fileName ? fileName : "",
        //upload: fileContent?base64ToFile(fileContent,fileName,mimeType):[],
        isNew: false,
        fileContent: fileContent ? fileContent : "",
        attachmentId: fileContent ? attachmentId : "",
        mimeType: mimeType,
        upload: [
          {
            name: fileName ? fileName : "",
            status: "done",
          },
        ],
        bckupload: [
          {
            name: fileName ? fileName : "",
            status: "done",
          },
        ],
      };
      basic = { ...basic, ...sfAttachment };
    }
    return basic;
  });
  return formattedCertifications;
};

export const isValidForm = (certificates: any) => {
  let status = false;
  certificates.filter((item: any) => {
    if (item === undefined || item?.name === "" || item?.name === undefined) {
      status = true;
      return item;
    }
  });
  return status;
};

export const dateSplit = (date: any, text: string) => {
  let label = "";
  let dateSplit = moment(date).format("DD-MMM-ddd").split("-");
  if (text === "date") {
    label = dateSplit[0];
  } else if (text === "month") {
    label = dateSplit[1].toLocaleUpperCase();
  } else if (text === "day") {
    label = dateSplit[2].toLocaleUpperCase();
  }
  return label;
};

export function convertDateToMMYY(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth();
  const monthNames = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };
  return `${year}-${monthNames[month]}`;
}

export function isChecked(list, text) {
  var searchResult = list.filter((item) => item.label === text);
  return searchResult.length > 0 ? true : false;
}

export function formObject(list) {
  let listMilestone: any = [];
  let listViewMilestone: any = [];
  let listMilestoneStatus: any = [];
  list?.forEach((section: any) => {
    let rowObject = {};
    let rowViewObject = {};
    let rowMilestoneStatus =
      section?.milestoneStatusDetails?.milestoneStatusIcon;
    section.questionList?.forEach((question: any) => {
      const questionId = question.questionDTO.description;
      let answer = question.questionDTO.answer;
      let viewanswer = question.questionDTO.answer;
      if (
        questionId === "MILESTONE_QUESTION_2" ||
        questionId === "MILESTONE_QUESTION_3"
      ) {
        answer = answer ? dayjs(answer, "DD/MM/YYYY") : null;
      }
      rowObject[questionId] = answer;
      rowViewObject[questionId] = viewanswer;
    });
    listMilestone.push(rowObject);
    listViewMilestone.push(rowViewObject);
    listMilestoneStatus.push(rowMilestoneStatus);
  });
  const emptyList = listViewMilestone.filter(
    (item: any) =>
      item.MILESTONE_QUESTION_1 === null &&
      item.MILESTONE_QUESTION_2 === null &&
      item.MILESTONE_QUESTION_3 === null
  );
  return {
    listMilestone,
    listViewMilestone,
    listMilestoneStatus,
    isPreview: emptyList.length > 0 ? true : false,
  };
}

export function goalFormObject(list) {
  let singleGoal = {};
  list?.forEach((section: any) => {
    section.questionList?.forEach((question: any) => {
      const questionId = question.questionDTO.questionId;
      const description = question.questionDTO.description;
      let answer = question.questionDTO.answer;
      if (
        description === "GOAL_QUESTION_7" ||
        description === "GOAL_QUESTION_8"
      ) {
        answer = answer ? dayjs(answer, "DD/MM/YYYY") : null;
      } else if (description === "GOAL_QUESTION_6") {
        answer = answer ? parseInt(answer) : null;
      }
      singleGoal[questionId] = answer;
    });
  });
  return singleGoal;
}

export function dateObjectFormat(date) {
  if (typeof date === "string") {
    return date;
  } else if (date === null) {
    return "";
  } else {
    return isValidDate(date);
  }
}

export function goalEexceed(percentage: any) {
  return WEIGHTAGE_EXCEEDING.replace(":num", percentage);
}

export function goalVisibility(goalData: any) {
  if (goalData?.visibility === true || goalData?.GOAL_QUESTION_9 === true) {
    return PRIVATE;
  } else if (
    goalData?.visibility === false ||
    goalData?.GOAL_QUESTION_9 === false
  ) {
    return PUBLIC;
  }
}

export function goalWeight(goalData: any) {
  if (goalData?.weight || goalData?.GOAL_QUESTION_6) {
    return `${goalData?.weight || goalData?.GOAL_QUESTION_6} %`;
  }
  return "";
}

export function notificationGoal(filterGoal: any) {
  const exists = filterGoal?.filter((item) => item?.state === DRAFT);
  const noDraftUnsaved = filterGoal?.filter(
    (item) => item?.state !== DRAFT && item?.goalId !== null
  );
  const noDraftNoUnsaved = filterGoal?.filter(
    (item) => item?.state !== DRAFT && item?.sfGoalId !== null
  );
  let draftGoalExists = false;
  let actionNotification = false;
  if (exists.length > 0) {
    draftGoalExists = true;
    actionNotification = true;
  } else if (noDraftUnsaved.length > 0) {
    draftGoalExists = true;
    actionNotification = false;
  } else if (noDraftNoUnsaved.length > 0) {
    draftGoalExists = false;
    actionNotification = false;
  }
  return { draftGoalExists, actionNotification };
}
export function formatBadgesDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const formattedDate = `${day} ${
    monthNames ? monthNames[month] : month + 1
  } ${date.getFullYear()}`;
  return formattedDate;
}
export function formatHolidayCalendarDate(dateHoliday) {
  const date = new Date(dateHoliday);
  const day = date.getDate();
  const formattedDate = `${day}`;
  return formattedDate;
}

export function formatHolidayCalendarMonth(dateHoliday) {
  const date = new Date(dateHoliday);
  const month = date.getMonth();
  const monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const formattedMonth = `${monthNames ? monthNames[month] : month + 1}`;
  return formattedMonth;
}

export const formatBadgeName = (text) => {
  return text
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0)?.toUpperCase() + word?.slice(1))
    .join(" ");
};
