import { Spin, List, Card } from "antd";

const Faq = () => {
  return (
    <>
      <div className="asop_main_page policyhub-section1 main-page_policy1">
        <Spin spinning={false} delay={500}></Spin>
        <div>faq</div>
      </div>
    </>
  );
};

export default Faq;
