import { Link, useNavigate } from "react-router-dom";
import { Form, Button } from "antd";
import "./Certification.css";
import n from "../navigation/Route.Names";
import {
  PREVIEW,
  BACK,
  ADD_MORE_CERTIFICATION,
  CERTIFICATIONS,
  CANCEL,
  PROCEED,
  QUIET_MESSAGE,
  QUIET_TITLE,
  CERTIFICATION,
  CERTIFICATION_URL,
  START,
  ALTIVERSE,
  ALTIVERSE_BREADCRUMP,
  ADD_CERTIFICATION_BREADCRUMP,
} from "../../constants";
import { useEffect, useState } from "react";
import OutlineButton from "../common/button/OutlineButton";
import { useAppSelector, useAppDispatch } from "../../redux/store/redux-hooks";
import {
  previewCertification,
  reset,
} from "../../redux/reducers/certification";
import ModalDialog from "../common/modal/modal";
import CertificateUpdateForm from "./CertificateUpdateForm";
import { getSkills } from "../../redux/actions/certification";
import { isValidForm } from "../utils/util";
import BreadcrumbComponent from "../common/breadcrumb/breadcrumb";
import AddOperator from "../../themes/assets/images/svg/Add-certify.svg";
export default function AddCertification() {
  const Certification = useAppSelector((state: any) => state.certification);
  const dispatch = useAppDispatch();
  const { addcertifications = [], listskills } = Certification;
  const [btndisabled, setbtndisabled] = useState(true);

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = (values: any) => {
    const request = values.certificates;
    dispatch(previewCertification(request));
    navigate(`/${n.START}/${n.CERTIFICATION_PREVIEW_URL}`);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    const { certificates } = allValues;
    const isValid = isValidForm(certificates);
    setbtndisabled(isValid);
  };

  useEffect(() => {
    const isValid = isValidForm(addcertifications);
    setbtndisabled(isValid);
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ispage, setIsPage] = useState("");

  const showModal = (page) => {
    const newRecord = form.getFieldsValue().certificates;
    if (JSON.stringify(addcertifications) !== JSON.stringify(newRecord)) {
      setIsModalOpen(true);
      setIsPage(page);
    } else {
      if (page === ALTIVERSE) {
        navigate(`/${n.START}/${n.ALTIVERSE}`);
      } else if (page === CERTIFICATION_URL) {
        dispatch(reset({}));
        navigate(`/${n.START}/${n.CERTIFICATION_URL}`);
      }
      //navigate(`/${n.START}/${n.CERTIFICATION_URL}`);
    }
  };

  useEffect(() => {
    if (listskills.length === 0) {
      dispatch(getSkills({}));
    }
    /*.unwrap().then((data)=>{
    })*/
  }, []);

  const handleDelete = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(reset({}));
    if (ispage === ALTIVERSE) {
      navigate(`/${n.START}/${n.ALTIVERSE}`);
    } else if (ispage === CERTIFICATION_URL) {
      navigate(`/${n.START}/${n.CERTIFICATION_URL}`);
    }
    //navigate(`/${n.START}/${n.CERTIFICATION_URL}`);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const dateChange = (e: any, index: number) => {
    const data = form.getFieldValue("certificates");
    if (e) {
      data[index] = { ...data[index], ["startDate"]: e };
    } else {
      data[index] = { ...data[index], ["startDate"]: e, ["endDate"]: "" };
    }
    form.setFieldValue("certificates", data);
  };

  const fieldChange = (
    skills: any,
    index: number,
    changeFiled: string,
    base64File: string,
    fileName: string,
    type: string,
    isError: boolean
  ) => {
    const formData = form.getFieldValue("certificates");
    const filteredData = formData.map((data: any, skillindex: any) => {
      if (index === skillindex) {
        if (changeFiled === "skill" || changeFiled === "removeSkill") {
          return { ...data, skill: skills };
        } else if (changeFiled === "addimage") {
          if (isError) {
            return {
              ...data,
              upload: data && data.bckUpload ? data.bckUpload : [],
            };
          } else {
            return {
              ...data,
              upload: [skills],
              fileContent: base64File,
              fileName: fileName,
              bckUpload: [skills],
            };
          }
        } else if (changeFiled === "removeimage") {
          return {
            ...data,
            upload: [],
            fileContent: "",
            fileName: "",
            attachmentId: null,
            bckUpload: [],
          };
        }
      } else {
        return { ...data };
      }
    });
    form.setFieldValue("certificates", filteredData);
  };
  const [showInitialDiv, setShowInitialDiv] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      setShowInitialDiv(window.scrollY <= 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const redirection = (e: any, page: string) => {
    e.preventDefault();
    showModal(page);
  };

  const items = [
    {
      title: (
        <Link
          to={`/${START}/${ALTIVERSE}`}
          onClick={(e) => redirection(e, ALTIVERSE)}
          className="link-highlight"
        >
          {ALTIVERSE_BREADCRUMP}
        </Link>
      ),
    },
    {
      title: (
        <Link
          to={`/${START}/${CERTIFICATION_URL}`}
          onClick={(e) => redirection(e, CERTIFICATION_URL)}
          className="link-highlight"
        >
          {CERTIFICATION}
        </Link>
      ),
    },
    {
      title: `${ADD_CERTIFICATION_BREADCRUMP}`,
    },
  ];

  return (
    <div className="certify_main-otr">
      <Form
        form={form}
        layout="vertical"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
        autoComplete="off"
      >
        <div className="hidestricky_breadcrump">
          <BreadcrumbComponent items={items} />
          <div className="Seperation-page-title">{CERTIFICATIONS}</div>
        </div>
        {!showInitialDiv && (
          <div className="headingWrapper">
            <BreadcrumbComponent items={items} />
            <div className="Seperation-page-title certification-page-title">
              {CERTIFICATIONS}
            </div>
          </div>
        )}
        <Form.List name={["certificates"]} initialValue={addcertifications}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <CertificateUpdateForm
                  key={key}
                  index={index}
                  showModal={showModal}
                  isModalOpen={isModalOpen}
                  remove={remove}
                  handleDelete={handleDelete}
                  handleCancel={handleCancel}
                  restField={restField}
                  name={name}
                  addcertifications={addcertifications}
                  dateChange={dateChange}
                  fieldChange={fieldChange}
                  listskills={listskills}
                />
              ))}
              <div className="btn_otr-sec">
                <Form.Item className="add-certify">
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    size="large"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={AddOperator} />
                    {ADD_MORE_CERTIFICATION}
                  </Button>
                </Form.Item>
                <div className="certify_btn-otr">
                  {/* <Form.Item className="certify-back">
                    <OutlineButton
                      backText={BACK}
                      outlineBtnAction={showModal}
                    />
                  </Form.Item> */}
                  <Form.Item className="certify-back">
                    <ModalDialog
                      openModal={isModalOpen}
                      DialogHeading={QUIET_TITLE}
                      closeIcon={false}
                      modalImageStyles={{ display: "none" }}
                      modalHeadingStyles={{ display: "none" }}
                      msgContent={QUIET_MESSAGE}
                      okTxt={PROCEED}
                      cancelTxt={CANCEL}
                      cancelBtn={handleCancel}
                      okBtn={handleOk}
                      onOk={handleOk}
                      onCancel={handleCancel}
                      modalStyles={"backpopup"}
                      maskClosable={false}
                    />
                  </Form.Item>
                  <Form.Item className="certify-prev">
                    <Button htmlType="submit" disabled={btndisabled}>
                      {PREVIEW}
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </>
          )}
        </Form.List>
      </Form>
    </div>
  );
}
