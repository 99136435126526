import "./index.css";
import { LEAVE_MANAGEMENT } from "../../constants";
import {
  LeaveTypeBalanceCard,
  HolidayQuoteCard,
  LeaveRequestCard,
  HolidayCarousel,
  AbsenceRequestsCard,
  UpcomingHolidaysCard,
} from "./components";
import {
  getLMLandingPage,
  getLeaveTemplate,
  getLeaveTypes,
  getUpcomingLeaves,
} from "../../redux/actions/leavemanagement";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/store/redux-hooks";
import { Skeleton } from "antd";

const LeaveManagement = () => {
  const dispatch = useAppDispatch();
  const { landingpageData, leaveTemplate, leaveTypesData, upcomingLeaves } = useAppSelector(
    (state: any) => state?.leavemanagement
  );
  const [leaveType, setLeaveType] = useState<{
    displayName: string;
    leaveTypeId: string;
  }>();
  const {
    abcenseList,
    leaveBalanceList,
    leaveManagementCalendarViewList,
    floaterBannerList,
  } = landingpageData;
  useEffect(() => {
    dispatch(getLMLandingPage({}));
    dispatch(getLeaveTypes({}));
    dispatch(getUpcomingLeaves({}));  
    dispatch(
      getLeaveTemplate({
        templateId: leaveType?.leaveTypeId || leaveTypesData?.[0]?.leaveTypeId,
      }));
    
  }, []);

  const SkeletonLeave = () => (
    <Skeleton.Node
      style={{
        width: "100%",
        height: "100%",
      }}
      children={false}
      active={true}
    />
  );

  const leaveLoading = landingpageData?.loading || landingpageData?.loadingList;

  return (
    <div className="leave-management-top-container">
      <h1 className="leave-management-main-heading">{LEAVE_MANAGEMENT}</h1>
      <div className="leave-management-body-container">
        <div className="leave-management-left-container">
          <div className="leave-management-top-left-conatiner">
            <div className="leave-management-leave-balance-card">
              {leaveLoading ? (
                <SkeletonLeave />
              ) : (
                <LeaveTypeBalanceCard
                  leaveBalanceList={leaveBalanceList || []}
                />
              )}
            </div>
            <div className="leave-management-holiday-quotecard">
              {leaveLoading ? <SkeletonLeave /> : <HolidayQuoteCard />}
            </div>
          </div>
          <div className="leave-management-leave-request-card">
            {leaveLoading ? (
              <SkeletonLeave />
            ) : (
              <LeaveRequestCard
                calendarData={leaveManagementCalendarViewList || []}
                leaveTypesData={leaveTypesData || []}
                leaveType={leaveType || {}}
                setLeaveType={setLeaveType}
                leaveTemplate={leaveTemplate || {}}
              />
            )}
          </div>
        </div>
        <div className="leave-management-right-container">
          <div className="leave-management-holiday-carousel-card">
            {leaveLoading ? (
              <SkeletonLeave />
            ) : (
              <HolidayCarousel floaterBannerList={floaterBannerList || []} />
            )}
          </div>
          <div className="leave-management-upcoming-holidays-card">
            {leaveLoading ? <SkeletonLeave /> : <UpcomingHolidaysCard />}
          </div>
          <div className="leave-management-absence-request-card">
            {leaveLoading ? (
              <SkeletonLeave />
            ) : (
              <AbsenceRequestsCard absenceList={abcenseList || []} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveManagement;
