import React from "react";
import "./WaitingResponseCard.css";
import waitingResponse from "../../../themes/assets/images/waitingResp.png";

const WaitingResponseCard = () => {
  return (
    <div className="waitingResponseCard">
      <img src={waitingResponse} alt="" className="waitingResImage" />
      <div className="subText">Awaiting Response From Manager</div>
    </div>
  );
};

export default WaitingResponseCard;
