import React, { useEffect, useState } from "react";
import "./index.css";
import rightArrow from "../../themes/assets/images/rightArrow.png";
import leftArrow from "../../themes/assets/images/leftArrow.png";
import BreadcrumbComponent from "../common/breadcrumb/breadcrumb";
import menuindex from "../../themes/assets/images/svg/menu_index.svg";
import ModalDialog from "../common/modal/modal";
import { INDEX_POPUP_TITLE } from "../../constants";
import { useParams } from "react-router";
import { useAppDispatch } from "../../redux/store/redux-hooks";
import {
  eBookLog,
  getBooksDetail,
  getBooksSection,
} from "../../redux/actions/ebooks";
import { Spin } from "antd";
import { getFromLocalStorage, setInLocalStroage } from "../utils/util";
import EbookLeftArrow from "../common/svg/EbookLeftArrow";
import EbookRightArrow from "../common/svg/EbookRightArrow";

const EBookDetail = () => {
  const activePage = getFromLocalStorage("activePage");
  const [iframeContent, setIframeContent] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(
    activePage ? parseInt(activePage) : 1
  );
  const [id, setId] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sections, setSections] = useState([]);
  const dispatch = useAppDispatch();
  const param = useParams();
  const { code } = param;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = (number) => {
    PageLoad({ page: number, code, isLoad: false });
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleArrowClick = (direction: "left" | "right") => {
    if (direction === "left") {
      PageLoad({ page: currentPage - 1, code, isLoad: false });
    } else if (direction === "right") {
      PageLoad({ page: currentPage + 1, code, isLoad: false });
    }
  };

  const PageLoad = ({ page, code, isLoad }) => {
    const currentPage = page;
    setLoading(true);
    dispatch(getBooksDetail({ code, page }))
      .unwrap()
      .then((res: any) => {
        console.log(res);
        const { totalPages, id, activePage } = res;
        const { page, pageNo } = res.content.pages[0];
        const base64Contend = atob(page);
        const htmlContend = base64Contend.toString();
        setId(id);
        setIframeContent(htmlContend);
        setCurrentPage(isLoad ? activePage : currentPage);
        setInLocalStroage("activePage", isLoad ? activePage : currentPage);
        setTotalPages(totalPages);
        setLoading(false);
      });
  };

  useEffect(() => {
    PageLoad({ page: currentPage, code, isLoad: true });
    dispatch(getBooksSection({ code }))
      .unwrap()
      .then((res: any) => {
        setSections(res.content.sections);
      });
  }, [param]);

  useEffect(() => {
    if (id) {
      dispatch(eBookLog({ id: id, activePage: currentPage }));
    }
  }, [currentPage]);

  return (
    <>
      <Spin spinning={loading}>
        <div className="ebook-whole-section">
          <div className="breadcrumb-ebooks-detail">
            <BreadcrumbComponent
              rootPath={"E-Books"}
              subPath={"Name of the Book"}
              rootLink={`/web/altiverse/E_BOOKS`}
              isDirect={true}
            />
            <div>
              <img
                className="ebooks-menu-icon"
                onClick={showModal}
                src={menuindex}
                alt="menubar"
              />
            </div>
          </div>
          <div className="ebooks-content-main">
            <iframe
              id="f"
              scrolling="no"
              title="Multi-File Iframe"
              srcDoc={iframeContent}
              width="100%"
              // height="490px"
              // scrolling="no"
              className="ebooks-iframe"
              // style={{
              //   position: "fixed",
              //   top: "0",
              //   left: "0",
              //   height: "100%",
              //   width: "100%",
              // }}
            />
          </div>
          <div className="ebooks-arrows-main">
            <div className="ebooks-arrows-area">
              <button
                onClick={() => handleArrowClick("left")}
                className={`ebooks-left-arrow ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                disabled={currentPage === 1}
              >
                <EbookLeftArrow />
                {/* <img className="left-arrow-img" src={leftArrow} alt="Left" /> */}
              </button>
              <span className="ebooks-pages-count">
                Page {currentPage}/{totalPages}
              </span>
              <button
                onClick={() => handleArrowClick("right")}
                className={`ebooks-right-arrow ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
                disabled={currentPage === totalPages}
              >
                <EbookRightArrow />
                {/* <img className="right-arrow-img" src={rightArrow} alt="Right" /> */}
              </button>
            </div>
          </div>
        </div>
      </Spin>
      <ModalDialog
        modalStyles={"page-index-popup"}
        openModal={isModalOpen}
        closeIcon={true}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={true}
        modalImageStyles={{ display: "none" }}
        modalHeadingStyles={{ display: "none" }}
        DialogHeading={INDEX_POPUP_TITLE}
        modalSubHeading={"index-popup-heading"}
        indexDetails={sections}
        index={currentPage}
      />
    </>
  );
};

export default EBookDetail;
