import "./CommentCard.css";
import { Rate } from "antd";
import "./ManagerRatingCard.css";
import AvatarProfile from "../../Avatar/AvatarProfile";

const ManagerRatingCard = (props: any) => {
  const hideRating = !props?.hideRating;
  const style = props?.large
    ? "commentCard-checkin "
    : "commentCard-checkin medium";
  const { managerInfo, comment, rating } = props;
  return (
    <div className={style}>
      <div className="inner-container">
        <div
          className="manager-comment"
          dangerouslySetInnerHTML={{ __html: comment }}
        />
        {hideRating && (
          <p>
            <Rate className={"custom-rating"} value={rating} disabled />
          </p>
        )}
        <div className="profile-checkin">
          <AvatarProfile
            name={managerInfo?.userName}
            size={40}
            profilePic={
              managerInfo?.profilePictureImage &&
              `data:image/png;base64,${managerInfo?.profilePictureImage}`
            }
          />

          <div className="userData-checkin">
            <div className="name-checkin">{managerInfo?.userName}</div>
            <div className="designation-checkin">
              {managerInfo?.designation}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagerRatingCard;
