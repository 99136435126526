import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import {
  ASP_CANCEL_DRAFT_DESC,
  ASP_CANCEL_DRAFT_TITLE,
  ASP_COMPETENCY_EVALUATION_TITLE,
  ASP_FORM,
  ASP_START_NEXT,
  ASP_SUBMIT_EVALUATION,
  CHARACTER_COUNT,
  COMMENTS_LENGTH_GREATER_TEXT,
  COMMENTS_LENGTH_LESSER_TEXT,
  CONFIRMATION_TITLE_CANCEL,
  DISCARD_BUTTON,
  DRAFT,
  IN_PROGRESS,
  MINIMUM_CHAR_COUNT,
  MY_SPACE,
  MY_YEAR_END_FORM,
  MY_YEAR_END_REVIEW,
  MYWORK_URL,
  NO,
  NO_MIN_CHAR_COUNT,
  NOTIFICATION_DURATION,
  SAVE,
  SLIDER_SPLIT_COUNT,
  START,
  TEAM_PERFORMANCE,
  TEAM_YEAR_END_REVIEW_TITLE,
  TEAMS_ASP_YEAR_END,
  WARNING,
  YEAR_END_DRAFT_TOAST_MSG_DES,
  YEAR_END_DRAFT_TOAST_MSG_DES_REMAINIG,
  YEAR_END_DRAFT_TOAST_TITLE,
  YEAR_END_REVIEW_CANCEL_POPUP_MSG,
  YES,
} from "../../../constants";
import "../performance.css";
import {
  Card,
  Form,
  Rate,
  Steps,
  Progress,
  notification,
  Spin,
  FormInstance,
  Button,
} from "antd";
import { useEffect, useState } from "react";
import "./aspform.css";
import OutlineButton from "../../common/button/OutlineButton";
import n from "../../navigation/Route.Names";
import ModalDialog from "../../common/modal/modal";
import BreadcrumbComponent from "../../common/breadcrumb/breadcrumb";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RichTextEditor from "../components/RichTextEditor";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import {
  getAspFormSteps,
  getBehaviouralEmployeeDetails,
  getOverallFormRating,
  postOverallFormRating,
  saveBehaviouralEmployeeDetails,
  submitYearEndReview,
} from "../../../redux/actions/performance";
import ManagerRatingCard from "../components/ManagerRatingCard";
import { notificationAction } from "../../../redux/reducers/performance";
import { ToastSuccessIcon } from "../../common/svg/svg";
import ErrorComponent from "../../errorComponent/ErrorComponent";
import amplitude from "amplitude-js";

interface SubmitButtonProps {
  form: FormInstance;
}

const CompetencyEvaluation = () => {
  const [api, contextHolder] = notification.useNotification({
    top: 0,
    maxCount: 1,
  });

  const [initialFormValue, setInitialFormValue] = useState<any>([]);
  const [initialStepsValue, setInitialStepsValue] = useState<any>([]);
  const [settings, setSettings] = useState<any>({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
  });
  const [overAllRating, setOverAllRating] = useState<any>();
  const [overAllRatingComment, setOverAllRatingComment] = useState<any>();
  const [cancelPopup, setCancelPopup] = useState<any>();
  const [form] = Form.useForm();
  const param = useParams();
  let navigate = useNavigate();

  const { reporteeId = "", appraisalId = "", userId = "", year = "" } = param;
  const location = useLocation();
  const isManager = location.pathname.includes(TEAM_PERFORMANCE);
  const {
    getBehaviouralFormEmployeeList,
    getBehaviouralFormEmployeeError,
    isLoading,
    getAspFormStepsList,
    configuration,
    overallFormRatingLoading,
    overallFormRatingError,
    viewASPData,
  } = useAppSelector((state: any) => state.performance);

  const { progressBarInfo } = viewASPData || "";

  const dispatch = useAppDispatch();
  const onCloseHandler = () => {
    dispatch(
      notificationAction({
        type: WARNING,
        message: "",
        isShowNotification: false,
      })
    );
  };
  useEffect(() => {
    if (configuration.isShowNotification) {
      const type = configuration.alertMessage.type;
      api[type]({
        message: ``,
        description: configuration.alertMessage.message,
        placement: "topRight",
        duration: NOTIFICATION_DURATION,
        icon: <ToastSuccessIcon />,
        className: "custom-toast-area custom-success-toast",
        onClose: onCloseHandler,
      });
    }
  }, [configuration.isShowNotification]);

  useEffect(() => {
    if (isManager) {
      dispatch(
        getAspFormSteps({
          reporteeId: reporteeId,
          year: year,
        })
      );
      dispatch(
        getBehaviouralEmployeeDetails({
          //userId: userId,
          year: year,
          reporteeId: reporteeId,
        })
      );
    } else {
      dispatch(
        getAspFormSteps({
          appraisalId: appraisalId,
          year: year,
        })
      );
      dispatch(
        getBehaviouralEmployeeDetails({
          //userId: userId,
          year: year,
        })
      );
    }
  }, [userId, year, appraisalId, reporteeId]);
  useEffect(() => {
    setInitialFormValue(getBehaviouralFormEmployeeList);
    form.setFieldsValue({
      evaluation: getBehaviouralFormEmployeeList,
    });
  }, [getBehaviouralFormEmployeeList]);

  useEffect(() => {
    const stepsList = getAspFormStepsList?.stepsList || [];
    setInitialStepsValue(getAspFormStepsList?.stepsList);
    const progresStatus = stepsList?.findIndex(
      (item: any) => item.subTitle === IN_PROGRESS
    );
    setSettings({
      ...settings,
      initialSlide: progresStatus > SLIDER_SPLIT_COUNT - 1 ? 1 : 0,
    });
  }, [getAspFormStepsList]);

  const clickHandler = (values) => {
    const data = form.getFieldValue("evaluation");
    const evaluationFormDetails = data.map((item: any, index) => {
      return {
        evaluationName: item?.evaluationName,
        assessment: {
          rateScale: {
            title: item?.ratingTitle,
            rating: item?.rating,
            isMandatory: item?.ratingIsMandatory,
          },
          assessmentComment: {
            commentTitle: item?.commentTitle,
            comment: item?.comment,
            isMandatory: item?.commentIsMandatory,
          },
          editable: item?.editable,
        },
        goalId: "",
      };
    });
    if (!isManager) {
      amplitude.getInstance().logEvent("Completed Employee Review");
    }

    dispatch(
      saveBehaviouralEmployeeDetails({
        // userId: userId,
        appraisalId: isManager ? "" : appraisalId,
        year: year,
        request: evaluationFormDetails,
        reporteeId: reporteeId,
      })
    )
      .unwrap()
      .then(() => {
        if (!isManager) {
          dispatch(submitYearEndReview({ year: year }))
            ?.unwrap()
            ?.then(() => navigate(`/${START}/my_space/AspForm`));
        }
        if (isManager)
          navigate(
            `/${START}/${n.MYWORK_URL}/team_performance/potential_rating/${reporteeId}/${year}`
          );
      });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const breadbrumbItems = isManager
    ? [
        {
          title: (
            <Link
              to={`/${n.START}/${n.MYWORK_URL}/${n.TEAM_PERFORMANCE}/asp`}
              className="link-highlight"
            >
              {TEAM_YEAR_END_REVIEW_TITLE}
            </Link>
          ),
        },
        {
          title: (
            <Link
              to={`/${n.START}/${n.MYWORK_URL}/${n.TEAM_PERFORMANCE}/aspform/${reporteeId}`}
              className="link-highlight"
            >
              {viewASPData?.reporteeInfo?.userName}
            </Link>
          ),
        },
        {
          title: `${year} ${TEAMS_ASP_YEAR_END}`,
        },
      ]
    : [
        {
          title: (
            <Link
              to={`/${n.START}/${n.MY_SPACE}/AspForm`}
              className="link-highlight"
            >
              {MY_YEAR_END_REVIEW}
            </Link>
          ),
        },
        {
          title: `${MY_YEAR_END_FORM} ${year}`,
        },
      ];
  const handleFormSubmit = async (values) => {};

  const currentStep = initialStepsValue
    ?.map((item: any) => item.current)
    .indexOf(true);

  const firstSet =
    initialStepsValue !== undefined
      ? initialStepsValue.slice(0, SLIDER_SPLIT_COUNT)
      : [];
  const secondSet =
    initialStepsValue !== undefined
      ? initialStepsValue.slice(SLIDER_SPLIT_COUNT)
      : [];

  const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({
    form,
    children,
  }) => {
    const [submittable, setSubmittable] = useState<boolean>(false);

    // Watch all values
    const values = Form.useWatch([], form);

    useEffect(() => {
      form
        .validateFields({ validateOnly: true })
        .then((someData) => {
          setSubmittable(true);
        })
        .catch(() => setSubmittable(false));
    }, [form, values]);

    return (
      <Button
        type="primary"
        htmlType="submit"
        className={isManager ? "previous-outline-btn" : "submit-fill-btn"}
        disabled={!submittable}
      >
        {children}
      </Button>
    );
  };
  const goBack = () => {
    if (isManager)
      navigate(
        `/${START}/${MYWORK_URL}/${TEAM_PERFORMANCE}/${n.GOAL_EVALUATION_URL}/${reporteeId}/${year}`
      );
    else
      navigate(
        `/${n.PERFORMANCE_URL}/${n.GOAL_EVALUATION_URL}/detail/${appraisalId}/${year}`
      );
  };

  const goBackToASP = () => {
    if (isManager) {
      navigate(
        `/${START}/${MYWORK_URL}/${TEAM_PERFORMANCE}/${ASP_FORM}/${reporteeId}`
      );
    } else {
      navigate(`/${START}/${MY_SPACE}/${ASP_FORM}`);
    }
  };

  const formatPercent = (percent) => {
    return `${percent.toFixed(2)}%`;
  };

  const onClickSaveDraft = () => {
    const data = form.getFieldValue("evaluation");
    const evaluationFormDetails = data.map((item: any, index) => {
      return {
        evaluationName: item?.evaluationName,
        assessment: {
          rateScale: {
            title: item?.ratingTitle,
            rating: item?.rating ? item?.rating : "",
            isMandatory: item?.ratingIsMandatory,
          },
          assessmentComment: {
            commentTitle: item?.commentTitle,
            comment: item?.comment,
            isMandatory: item?.commentIsMandatory,
          },
          editable: item?.editable,
        },
        goalId: "",
      };
    });

    dispatch(
      saveBehaviouralEmployeeDetails({
        // userId: userId,
        appraisalId: isManager ? "" : appraisalId,
        year: year,
        request: evaluationFormDetails,
        reporteeId: reporteeId,
        message: `${YEAR_END_DRAFT_TOAST_MSG_DES} ${year} ${YEAR_END_DRAFT_TOAST_MSG_DES_REMAINIG}`,
        additionalInfo: YEAR_END_DRAFT_TOAST_TITLE,
        status: DRAFT,
      })
    )
      .unwrap()
      .then(() => {
        goBackToASP();
      });
  };

  const hideCancelPopup = () => setCancelPopup(false);
  if (getBehaviouralFormEmployeeError || overallFormRatingError) {
    return (
      <div className="p2p-container">
        <BreadcrumbComponent items={breadbrumbItems} />
        <div className="loadingP2P">
          <ErrorComponent
            image={require("../../../themes/assets/images/something-went-wrong.png")}
            title={"Something Went Wrong"}
            description={
              "We are looking to fix it. Please try again in few minutes."
            }
          />
        </div>
      </div>
    );
  }
  if (isLoading || overallFormRatingLoading) {
    return (
      <div className="p2p-container">
        <BreadcrumbComponent items={breadbrumbItems} />
        <div className="loadingP2P">
          <Spin spinning={isLoading} />
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={
          isManager
            ? "layoutContainer"
            : "layoutContainer competancy-evaluation-asp"
        }
      >
        {contextHolder}
        <BreadcrumbComponent items={breadbrumbItems} />
        <Spin spinning={isLoading}>
          <div className="competency-heading-section">
            <h1>{year} Year End Evaluation</h1>
            <div className="progress-section">
              <p>
                Page {progressBarInfo?.currentStep} of{" "}
                {progressBarInfo?.totalSteps}
              </p>
              <Progress
                format={formatPercent}
                percent={
                  (progressBarInfo?.currentStep * 100) /
                  progressBarInfo?.totalSteps
                }
              />
            </div>
          </div>
          <Slider className="stepper-process" {...settings}>
            <div className="stepper-section">
              <Steps
                status="process"
                current={currentStep}
                items={firstSet}
                labelPlacement="vertical"
                className="steps"
              />
            </div>
            <div className="stepper-section">
              <Steps
                status="process"
                current={currentStep}
                items={secondSet}
                labelPlacement="vertical"
                className="steps"
              />
            </div>
          </Slider>
          <h4 className="common-short-heading">
            {ASP_COMPETENCY_EVALUATION_TITLE}
          </h4>

          <Form
            form={form}
            layout="vertical"
            name="basic"
            autoComplete="off"
            onFinish={(values) => clickHandler(values)}
          >
            <Form.List name={["evaluation"]} initialValue={initialFormValue}>
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Card
                        key={key}
                        className="compotency-card"
                        style={{ width: "100%" }}
                      >
                        <div className="compotency-main-section">
                          <div className="compotency-inner-section">
                            <h4>{initialFormValue[index]?.evaluationName}</h4>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  initialFormValue[index]
                                    ?.evaluationDescription,
                              }}
                            />
                          </div>
                          <div className="compotency-inner-section">
                            <Form.Item
                              label={initialFormValue[index]?.ratingTitle}
                              name={[name, "rating"]}
                              rules={[
                                {
                                  required:
                                    initialFormValue[index]?.ratingIsMandatory,
                                  message: "Please rate this goal.",
                                  // min: 1,
                                },
                              ]}
                            >
                              <Rate className={"custom-rating"} />
                            </Form.Item>
                          </div>
                          <div className="compotency-inner-section text-editor-section">
                            <Form.Item
                              label={initialFormValue?.[index]?.commentTitle}
                              name={[name, "comment"]}
                              rules={[
                                {
                                  required:
                                    initialFormValue?.[index]
                                      ?.commentIsMandatory,

                                  max: CHARACTER_COUNT,
                                  min: initialFormValue?.[index]
                                    ?.commentIsMandatory
                                    ? MINIMUM_CHAR_COUNT
                                    : NO_MIN_CHAR_COUNT,
                                  validator(rule, value) {
                                    if (
                                      !initialFormValue?.[index]
                                        ?.commentIsMandatory &&
                                      !value
                                    ) {
                                      return Promise.resolve();
                                    }
                                    if (
                                      initialFormValue?.[index]
                                        ?.commentIsMandatory &&
                                      value &&
                                      value?.length < MINIMUM_CHAR_COUNT
                                    ) {
                                      return Promise.reject(
                                        new Error(COMMENTS_LENGTH_LESSER_TEXT)
                                      );
                                    } else if (
                                      value &&
                                      value?.length > CHARACTER_COUNT
                                    ) {
                                      return Promise.reject(
                                        new Error(COMMENTS_LENGTH_GREATER_TEXT)
                                      );
                                    } else {
                                      if (
                                        value &&
                                        value?.length > MINIMUM_CHAR_COUNT &&
                                        value?.length < CHARACTER_COUNT
                                      )
                                        return Promise.resolve();
                                    }
                                  },
                                },
                              ]}
                            >
                              <RichTextEditor />
                            </Form.Item>
                          </div>
                          {isManager && (
                            <ManagerRatingCard
                              comment={
                                initialFormValue?.[index]?.employeeComment
                              }
                              rating={initialFormValue?.[index]?.employeeRating}
                              managerInfo={viewASPData?.reporteeInfo}
                            />
                          )}
                        </div>
                      </Card>
                    ))}
                  </>
                );
              }}
            </Form.List>

            <div className="footer-btn-section">
              <div className="footer-btn-left">
                <OutlineButton
                  outlineBtnStyles={"cancel-outline-btn"}
                  outlineBtnAction={() => {
                    setCancelPopup(true);
                  }}
                  backText="Cancel"
                />
              </div>
              <div className="footer-btn-right">
                <OutlineButton
                  outlineBtnStyles={"previous-outline-btn"}
                  outlineBtnAction={goBack}
                  backText="Previous"
                />

                <Form.Item>
                  <SubmitButton form={form}>
                    {isManager ? ASP_START_NEXT : ASP_SUBMIT_EVALUATION}
                  </SubmitButton>
                </Form.Item>
              </div>
            </div>
          </Form>
        </Spin>
        {/* <ModalDialog
          openModal={cancelPopup}
          DialogHeading={CONFIRMATION_TITLE_CANCEL}
          closeIcon={false}
          modalImageStyles={{ display: "none" }}
          modalHeadingStyles={{ display: "none" }}
          popupDivider={{ display: "none" }}
          indexScroll={{ display: "none" }}
          modalCenterImgStyles={{ display: "none" }}
          msgContent={YEAR_END_REVIEW_CANCEL_POPUP_MSG}
          okTxt={YES}
          cancelTxt={NO}
          cancelBtn={() => setCancelPopup(false)}
          okBtn={() => {
            isManager
              ? navigate(-1)
              : navigate(
                  `/${n.PERFORMANCE_URL}/${n.GOAL_EVALUATION_URL}/detail/${appraisalId}/${year}`
                );
          }}
          onOk={() => {
            isManager
              ? navigate(-1)
              : navigate(
                  `/${n.PERFORMANCE_URL}/${n.GOAL_EVALUATION_URL}/detail/${appraisalId}/${year}`
                );
          }}
          onCancel={() => {
            isManager
              ? navigate(-1)
              : navigate(
                  `/${n.PERFORMANCE_URL}/${n.GOAL_EVALUATION_URL}/detail/${appraisalId}/${year}`
                );
          }}
          modalStyles={"backpopup delete-milestone-popup compotency-popup"}
          maskClosable={false}
        /> */}
        <ModalDialog
          openModal={cancelPopup}
          DialogHeading={ASP_CANCEL_DRAFT_TITLE}
          closeIcon={false}
          modalImageStyles={{ display: "none" }}
          modalHeadingStyles={{ display: "none" }}
          popupDivider={{ display: "none" }}
          indexScroll={{ display: "none" }}
          modalCenterImgStyles={{ display: "none" }}
          msgContent={ASP_CANCEL_DRAFT_DESC}
          okTxt={SAVE}
          cancelTxt={DISCARD_BUTTON}
          cancelBtn={goBackToASP}
          okBtn={onClickSaveDraft}
          onOk={onClickSaveDraft}
          onCancel={goBackToASP}
          modalStyles={"backpopup delete-milestone-popup compotency-popup"}
          maskClosable={false}
        />
        {/* <ModalDialog
          openModal={isModalOpen}
          DialogHeading={CONFIRMATION_TITLE}
          closeIcon={false}
          modalImageStyles={{ display: "none" }}
          modalHeadingStyles={{ display: "none" }}
          popupDivider={{ display: "none" }}
          indexScroll={{ display: "none" }}
          modalCenterImgStyles={{ display: "none" }}
          msgContent={
            SUBMITTING_YEAR_END_EVALUATION +
            " " +
            managerGetDetails?.managerName
          }
          okTxt={SUBMIT}
          cancelTxt={CANCEL}
          cancelBtn={handleCancel}
          okBtn={clickHandler}
          onOk={clickHandler}
          onCancel={handleCancel}
          modalStyles={"backpopup delete-milestone-popup compotency-popup"}
          maskClosable={false}
        /> */}
      </div>
    </>
  );
};

export default CompetencyEvaluation;
