import { useEffect, useState } from "react";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../redux/store/redux-hooks";
import NotificationComponent from "../../common/notifications/notifications";
import { notificationAction } from "../../../redux/reducers/details";
import { WARNING } from "../../../constants";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import ContentTable from "./table";
import "./SeparationLanding.css";
import { getLanding } from "../../../redux/actions/landing";
import { nodatafoundIcon } from "../../common/svg/svg";

function SeparationScreen() {
  const [currentActivetab, setCurrentActivetab] = useState("NOT_COMPLETED");
  const separationLanding = useAppSelector((state: any) => state.landing);
  const { sectionList, pageTitle, message = "" } = separationLanding?.landing;
  const clearance = useAppSelector((state: any) => state.cardDetail);
  const { configuration } = clearance;
  const onCloseHandler = () => {
    dispatch(
      notificationAction({
        type: WARNING,
        message: "",
        isShowNotification: false,
      })
    );
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getLanding({ sectionId: currentActivetab }));
  }, [currentActivetab]);

  const tabsList = sectionList?.map((section: any) => {
    const { count = "", id = "", label = "" } = section;
    return {
      key: id,
      label: `${label} - ${count}`,
      children: <ContentTable currentActivetab={currentActivetab} />,
    };
  });

  const items: TabsProps["items"] = tabsList;

  if (message?.length > 0) {
    return (
      <>
        {configuration.isShowNotification && (
          <NotificationComponent
            type={configuration.alertMessage.type}
            message={configuration.alertMessage.message}
            onClose={onCloseHandler}
          />
        )}
        <div className="unauthorised_user_msg">
          <p>{nodatafoundIcon()}</p>
          <p>No employees found for the user to take action</p>
        </div>
      </>
    );
  }

  return (
    <div className="separation-module-section">
      {configuration.isShowNotification && (
        <NotificationComponent
          type={configuration.alertMessage.type}
          message={configuration.alertMessage.message}
          onClose={onCloseHandler}
        />
      )}
      <div className="Seperation-page-title">{pageTitle}</div>
      <div>
        {pageTitle && (
          <Tabs
            defaultActiveKey="1"
            type="card"
            items={items || []}
            onChange={(key) => setCurrentActivetab(key)}
            className="tabs-styles"
          />
        )}
      </div>
    </div>
  );
}

export default SeparationScreen;
