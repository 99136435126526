import { useState, useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store/redux-hooks";
import {
  getUserBadges,
  getBadgesDetailFilter,
} from "../../../../../redux/actions/gamification";
import "../LeaderBoardLanding/profile.css";
import { Select, Modal, Skeleton } from "antd";
import AccountDropdown from "../LeaderBoardLanding/Dropdown";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { setActiveMyBadgesFilters } from "../../../../../redux/reducers/gamification";
import PolygonPlaceholder from "../../../../../themes/assets/images/svg/Polygon_placeholder.svg";
import { formatBadgesDate } from "../../../../utils/util";
import BadgesEmpty from "../../../../../themes/assets/images/svg/badges-nothing.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const EmptyEarnedBadges = ({ message }) => {
  return (
    <div className="my-empty-badges">
      <img src={BadgesEmpty} alt="badgesempty" />
      <h3>No Badges</h3>
      <p>{message}</p>
    </div>
  );
};

export const BadgesDetailSkeleton = ({ loading }) => {
  const skeletonTop = Array.from({ length: 2 });
  const skeletonBottom = Array.from({ lenght: 5 });

  return (
    <div className="badges-detail-skeleton">
      <div className="detail-skeleton-top-badges">
        {skeletonTop?.map((item) => {
          return (
            <Skeleton.Button
              active={loading}
              className="detail-skeleton-viewall"
            />
          );
        })}
      </div>
      <div className="skeleton-detail-badges">
        {skeletonBottom?.map((item) => {
          return (
            <Skeleton.Button
              active={loading}
              className="detail-skeleton-badges"
            />
          );
        })}
      </div>
    </div>
  );
};

const ActiveIconDetail = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [openD2D, setOpenD2D] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const dispatch = useAppDispatch();
  const {
    userBadges,
    lockedBadgesFilter,
    loadingProfile,
    loading,
    error,
    myBadgesFilterData,
  } = useAppSelector((state) => state.gamification);

  const fetchLockedFilters = () => {
    dispatch(getBadgesDetailFilter({}));
  };
  const fetchUserBadges = () => {
    dispatch(getUserBadges({}));
  };

  useEffect(() => {
    fetchLockedFilters();
    fetchUserBadges();
  }, []);

  const lockedfilterData = lockedBadgesFilter?.data;
  const activeBadgesFilters = useAppSelector(
    (state) => state?.gamification?.myBadgesFilterData
  );
  const moduleItems = lockedfilterData?.map((item) => {
    return { key: item, label: item };
  });
  const lockedBadges = userBadges?.data?.earnedBadges;

  const filteredBadges = lockedBadges?.filter((item) =>
    myBadgesFilterData.includes(item?.gameId)
  );
  const earnedBadges = () => {
    return lockedBadges?.length > 0;
  };

  const handleDropdownClick = (dropdownName, event) => {
    if (event) {
      event.stopPropagation();
    }
    setActiveDropdown(activeDropdown === dropdownName ? null : dropdownName);
  };
  const onClickClearAll = () => {
    dispatch(setActiveMyBadgesFilters([]));
  };
  const showModal = (item) => {
    setIsModalOpen(true);
    setModalData(item);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setModalData(null);
  };
  const modalOpen = () => {
    setOpenD2D(true);
  };
  const modalClose = () => {
    setOpenD2D(false);
  };

  if (loading) {
    return <BadgesDetailSkeleton loading={loading} />;
  }

  return (
    <div className="badges_lock-otr badges_active-otr">
      <div className="lock_badge-filter">
        <h1>My Badges</h1>

        <div className="filter_btn">
          <div
            onClick={(e) => handleDropdownClick("account", e)}
            className={`account_leaderboard ${
              activeBadgesFilters?.length > 0 ? "clicked" : ""
            }`}
          >
            <a className="module_title">
              Module{" "}
              {activeBadgesFilters?.length > 0
                ? `(${activeBadgesFilters?.length})`
                : null}
              {activeDropdown === "account" ? <UpOutlined /> : <DownOutlined />}
              <div className="dropdown_game">
                {activeDropdown === "account" && (
                  <AccountDropdown
                    data={moduleItems}
                    module={"Active"}
                    onClick={(e) => handleDropdownClick("account", e)}
                  />
                )}
              </div>
            </a>
          </div>
          <button
            className={
              activeBadgesFilters?.length > 0
                ? "locked_clear_game_active"
                : "locked_clear_game"
            }
            onClick={onClickClearAll}
          >
            Clear All
          </button>
        </div>
      </div>
      <div className="badges_active-inn">
        {filteredBadges?.length > 0 && myBadgesFilterData?.length > 0 ? (
          filteredBadges?.map((item) => {
            return (
              <div
                className="badges-lock_list"
                key={item?.badgeId}
                type="primary"
                onClick={() => showModal(item)}
              >
                <div className="badge-otr_image">
                  <LazyLoadImage src={item?.badgeurl} alt="" effect="blur" />
                </div>
                <span>{item?.badgeName}</span>
                <h4>{formatBadgesDate(item?.acquiredDate)}</h4>
              </div>
            );
          })
        ) : myBadgesFilterData?.length > 0 ? (
          <EmptyEarnedBadges
            message={userBadges?.data?.headerInfo?.noBadgeMsg}
          />
        ) : lockedBadges?.length > 0 ? (
          lockedBadges?.map((item) => (
            <div
              className="badges-lock_list"
              key={item?.badgeId}
              type="primary"
              onClick={() => showModal(item)}
            >
              <div className="badge-otr_image">
                <img src={item?.badgeurl} alt="" />
              </div>
              <span>{item?.badgeName}</span>
              <h4>{formatBadgesDate(item?.acquiredDate)}</h4>
            </div>
          ))
        ) : (
          <EmptyEarnedBadges
            message={userBadges?.data?.headerInfo?.noBadgeMsg}
          />
        )}
        <Modal
          className="badges_popup-cont"
          title={modalData?.badgeName}
          open={isModalOpen}
          onOk={handleOk}
          okText={"Got it"}
          centered={true}
          width={380}
        >
          <div className="placeholder_img">
            <img
              className="placeholder"
              src={PolygonPlaceholder}
              alt="placeholder"
            />
            <LazyLoadImage
              src={modalData?.badgeurl}
              alt="gameBadge"
              effect="blur"
            />
          </div>
          <p>{modalData?.badgeDsc}</p>
        </Modal>
      </div>
    </div>
  );
};

export default ActiveIconDetail;
