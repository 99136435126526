import React, { useEffect, useState } from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";

const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];

export default function PieChartComponent({ pieChartData }) {
  const [colorValue, setColorValue] = useState<any>([]);
  const apiData = pieChartData?.Game_Summary;
  useEffect(() => {
    const dataPoints = apiData
      ? apiData?.map((item: any, index: any) => {
          //console.log(item?.gameName);
          return {
            name: item?.gameName,
            value: item?.points,
            colors: item?.colourCode,
          };
        })
      : null;
    //console.log("datapoints", dataPoints);
    if (apiData !== undefined) {
      setColorValue(dataPoints);
    }
    //console.log("test", colorValue);
  }, [pieChartData]);
  // const newDataValue = colorValue?.map((newItem: any, newIndex: any) => {
  //   return newItem?.value;
  // });
  // console.log("test", newDataValue);
  function sum(obj) {
    var sum = 0;
    for (var el in obj) {
      if (obj.hasOwnProperty(el)) {
        sum += parseFloat(obj[el]);
      }
    }
    return sum;
  }
  const totalPoints = sum(pieChartData?.Points_Chart).toFixed();

  return (
    <PieChart width={420} height={140} barGap={"none"}>
      <text
        className="piechart-data-points-value"
        x={225}
        y={70}
        dy={8}
        textAnchor="middle"
      >
        {totalPoints}
      </text>
      <text
        className="piechart-data-points"
        x={225}
        y={90}
        dy={8}
        textAnchor="middle"
      >
        Points
      </text>
      <Pie
        strokeWidth={0}
        data={colorValue}
        cx={220}
        cy={70}
        innerRadius={50}
        outerRadius={60}
        fill="#8884d8"
        paddingAngle={0}
        dataKey="value"
        stroke="none"
      >
        {colorValue.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.colors} stroke="none" />
        ))}
      </Pie>
    </PieChart>
  );
}
