import React from "react";
import "./GoalEvalution.css";

const EvaluationCard = ({ title, value }) => {
  return (
    <div className="goal-evalution-cont" style={{ whiteSpace: "pre-wrap" }}>
      <span className="title">{title}</span>
      <span>{value}</span>
    </div>
  );
};
export default EvaluationCard;
