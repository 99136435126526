import React from "react";
import "../../../Performance/components/QuarterReview.css";
import EmptySubmittedReview from "../../components/EmptySubmittedReview";
import CommentCard from "../../components/CommentCard";
import WaitingResponseCard from "../../components/WaitingResponseCard";

const QuarterlyReviewManager = (props: any) => {
  const { employeeData, managerData } = props;
  return (
    <div className="quarterReview">
      <div className="quarterTitle">{props?.data?.quarter}</div>
      <div
        className={
          managerData
            ? "commentCardContainer commentCardContainerManager"
            : "commentCardContainer"
        }
      >
        {(
          props?.manager
            ? props?.data?.manager?.comment === "" ||
              props?.data?.manager?.comment === undefined
            : props?.data?.employee?.comment === "" ||
              props?.data?.employee?.comment === undefined
        ) ? (
          <EmptySubmittedReview />
        ) : (
          <CommentCard
            comment={
              props?.manager
                ? props?.data?.manager?.comment
                : props?.data?.employee?.comment
            }
            userData={props?.manager ? managerData : employeeData}
          />
        )}
        {/* TODO : check condition when user submit empty response */}
        {!props?.manager && props?.data?.manager === null ? (
          props?.data?.allowManagerComment ? (
            <WaitingResponseCard />
          ) : (
            <EmptySubmittedReview />
          )
        ) : (
            !props?.manager
              ? props?.data?.manager?.comment === "" ||
                props?.data?.manager?.comment === undefined
              : props?.data?.employee?.comment === "" ||
                props?.data?.employee?.comment === undefined
          ) ? (
          <EmptySubmittedReview />
        ) : (
          <CommentCard
            comment={
              !props?.manager
                ? props?.data?.manager?.comment
                : props?.data?.employee?.comment
            }
            userData={props?.manager ? employeeData : managerData}
          />
        )}
      </div>
    </div>
  );
};

export default QuarterlyReviewManager;
