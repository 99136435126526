import { createSlice } from "@reduxjs/toolkit";
import { getHoliday } from "../actions/my_space";

type landingState = {
  holiday: any,
  loading:boolean
};

const initialLandingState: landingState = {
  holiday: [],
  loading:false
};

export const holiday = createSlice({
  name: "getHoliday",
  initialState: initialLandingState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getHoliday.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getHoliday.fulfilled, (state: any, action) => {
      state.loading = false;
      state.holiday = action.payload;
    });
    builder.addCase(getHoliday.rejected, (state: any, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  }
});

export default holiday.reducer;

// export const { deductionAction } = landing.actions;