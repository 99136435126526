import { createAsyncThunk } from "@reduxjs/toolkit";
import paybooks from "../services/paybooks";

//get detail action
export const getCalendar = createAsyncThunk(
  "getCalendar",
  async (args: any, { rejectWithValue }) => {
    const url = `api/v1/calendar`;
    try {
      const response = await paybooks.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get getPaySlips action
export const getPaySlips = createAsyncThunk(
  "getPaySlips",
  async (args: any, { rejectWithValue }) => {
    const url = `api/v1/payslips?calendarId=${args.calendarId}`;
    try {
      const response = await paybooks.post(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get getPaySlips action
export const getPaySlip_Detail = createAsyncThunk(
  "getPaySlip_Detail",
  async (args: any, { rejectWithValue }) => {
    const url = `api/v1/payslip-details?slipMonth=${args.slipMonth}`;
    try {
      const response = await paybooks.post(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//download attachment action
export const download = createAsyncThunk(
  "download",
  async (args: any, { rejectWithValue }) => {
    const { type, payslipMonth, isMailRequest, filename, calendarId } = args;
    let url;
    if (type === "payslip") {
      url = `api/v1/download/payslip?payslipMonth=${payslipMonth}&isMailRequest=${isMailRequest}`;
    } else if (type === "it") {
      url = `api/v1/download/it?calendarId=${calendarId}&isReport=false`;
    } else if (type === "form16") {
      url = `api/v1/download/form16?calendarId=${calendarId}`;
    }
    try {
      const response = await paybooks.post(url, {}, { responseType: "blob" });
      const result = response.data;
      return { result, filename, isMailRequest };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
