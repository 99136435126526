import { createAsyncThunk } from "@reduxjs/toolkit";
import separation from "../services/separation.js";

//read action
export const getLanding = createAsyncThunk(
  "getLanding",
  async (params: any = {}, { rejectWithValue }) => {
    const url = `api/v1/clearance/landing-page`;
    const response = await separation.get(url, {
      params,
    });
    try {
      const result = response.data;

      return { ...result, params };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
