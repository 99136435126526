import { createSlice } from "@reduxjs/toolkit";
import { getLanding } from '../actions/landing';

type landingState = {
  landing: any,
  employeeInfo: any,
  loading: boolean,
  error: null,
  selectedIndex: number | string,
  selectedValue: number | string,
  sectionId: string,
};

const initialLandingState: landingState = {
  landing: {},
  employeeInfo: [],
  loading: false,
  error: null,
  selectedIndex: "",
  selectedValue: "",
  sectionId: "",
};

export const landing = createSlice({
  name: "landing",
  initialState: initialLandingState,
  reducers: {
    deductionAction: (state, action) => {
      const { index, value } = action.payload
      state.selectedIndex = index;
      state.selectedValue = value
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getLanding.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLanding.fulfilled, (state: any, action) => {
      state.loading = false;
      state.landing = action.payload;
      const { employeeDetailList, params } = action.payload;
      if ((!state?.employeeInfo?.length || params.sectionId !== state?.sectionId) && !params.hasOwnProperty('searchText')) {
        state.employeeInfo = [];
        (employeeDetailList || []).forEach((item: any) => {
          const { employeeName, employeeId, employeeLocation } = item
          state.employeeInfo.push({ employeeName, employeeId, employeeLocation })
        });
        state.employeeInfo = employeeDetailList;
      }
      state.sectionId = params.sectionId;
    });
    builder.addCase(getLanding.rejected, (state: any, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  }
});

export default landing.reducer;

export const { deductionAction } = landing.actions;