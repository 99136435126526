import React, { useEffect } from "react";
import "./PointsDashboard.css";
import { POINTS_DASHBOARD } from "../../../../../constants";
import ActivityHistory from "../ActivityHistory";
import PointsSummaryDetails from "../PointsSummary/PointsSummary";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store/redux-hooks";
import {
  getPointsActivityHistoryFilter,
  getPointsLineChartData,
  getPointsSummaryData,
} from "../../../../../redux/actions/gamification";
import { convertDateToMMYY } from "../../../../utils/util";

const PointsDashboard = () => {
  const dispatch = useAppDispatch();
  const { activeModulesPoints, activeMonthPoints } = useAppSelector(
    (state: any) => state?.gamification
  );

  useEffect(() => {
    if (activeModulesPoints?.length > 0 || activeMonthPoints)
      dispatch(
        getPointsActivityHistoryFilter({
          month: activeMonthPoints ? convertDateToMMYY(activeMonthPoints) : "",
          game: activeModulesPoints,
        })
      );
    else dispatch(getPointsLineChartData({}));
  }, [activeModulesPoints, activeMonthPoints]);
  useEffect(() => {
    dispatch(getPointsSummaryData({}));
  }, []);

  return (
    <div className="point-dashboard">
      <div className="point-dashboard-title">{POINTS_DASHBOARD}</div>
      <div className="point-dashboad-content">
        <div className="">
          <PointsSummaryDetails />
        </div>
        <ActivityHistory />
      </div>
    </div>
  );
};

export default PointsDashboard;
