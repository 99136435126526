import { createSlice } from "@reduxjs/toolkit";

import moment from "moment";
import { getBartleTestResults } from "../actions/bartle_test";

type cleranceDetailState = {
  loading: boolean;
  error: boolean;
  results: Object;
};

const initialDetailState: cleranceDetailState = {
  loading: false,
  error: false,
  results: {},
};

export const bartle_test: any = createSlice({
  name: "bartle_test",
  initialState: initialDetailState,
  reducers: {
    // setActivePayslip: (state, action) => {
    //   state.activePayslip = action?.payload;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(getBartleTestResults.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getBartleTestResults.fulfilled, (state: any, action) => {
      state.loading = false;
      state.results = action.payload;
    });
    builder.addCase(getBartleTestResults.rejected, (state: any, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default bartle_test.reducer;
export const {} = bartle_test.actions;
