import { Button } from "antd";
import ColorPaletteIcon from "../../../themes/assets/images/svg/goals/compotency_icon1.svg";
import BoldIcon from "../../../themes/assets/images/svg/goals/compotency_icon2.svg";
import UnderlineIcon from "../../../themes/assets/images/svg/goals/compotency_icon3.svg";
import OrderedListIcon from "../../../themes/assets/images/svg/goals/compotency_icon4.svg";
import NumericListIcon from "../../../themes/assets/images/svg/goals/compotency_icon5.svg";

const TextStylesChange = () => {
  return (
    <>
      <div className="texteditor-section">
        <Button>
          <img src={ColorPaletteIcon} alt="Icon" />
        </Button>
        <Button>
          <img src={BoldIcon} alt="Icon" />
        </Button>
        <Button>
          <img src={UnderlineIcon} alt="Icon" />
        </Button>
        <Button>
          <img src={OrderedListIcon} alt="Icon" />
        </Button>
        <Button>
          <img src={NumericListIcon} alt="Icon" />
        </Button>
      </div>
    </>
  );
};

export default TextStylesChange;
