import React, { useEffect } from "react";
import { useAppDispatch,useAppSelector } from "../../redux/store/redux-hooks";

import { PlusOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Form,
  Input,
  TimePicker,
  Select,
  Row,
  Upload,
  Col,
} from "antd";

import "./eventPage.css";
import { calendarDetails } from "../../redux/actions/calender_events";

const config = {
  rules: [{ type: "object", required: true, message: "Please select time!" }],
};

export default function EventPage() {
  const dispatch = useAppDispatch();

  const calenderDetails = useAppSelector((state) => state.calender_events)
  console.log("calenderDetails",calenderDetails)

  const imgname = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
    const handleChange = (value) => {
      console.log(`selected ${value}`);
    };
  

  const onFinish = (fieldsValue) => {
    const values = {
      ...fieldsValue,
      "time-picker": fieldsValue["time-picker"].format("HH:mm:ss"),
    };
    console.log("Received values of form: ", values);
  };

  useEffect(() => {
    dispatch(calendarDetails())
    .unwrap()
        .then((res) => {
          console.log("calendarDetails", res);
        });
  }, []);

  return (
    <div className="container eventpage-popup">
      <Form
        onFinish={onFinish}
      >
       
          <Row>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="form-group">
                <Form.Item
                  name="user group"
                  label="User Group"
                  colon={false}
                  rules={[{ required: true, message: "usergroup is required" }]}
                >
                  <Select  onChange={handleChange}
                   placeholder="Select a event"
                  defaultValue="demo"
                options={[
                  { value: 'demo', label: 'demo' },
                  { value: 'meeting', label: 'meeting' },
                  { value: 'conference', label: 'conference' },
                ]}/>
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="form-group">
                <Form.Item
                  name="location"
                  label="Location"
                  colon={false}
                  rules={[{ required: true, message: "type is required" }]}
                >
                    <Select  onChange={handleChange}
                   placeholder="Select a Location"
                  defaultValue="demo"
                options={[
                  { value: 'demo', label: 'demo' },
                  { value: 'meeting', label: 'meeting' },
                  { value: 'conference', label: 'conference' },
                ]}/>
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="form-group">
                <Form.Item
                  name="event title"
                  label="Event Type"
                  colon={false}
                  rules={[{ required: true, message: "type is required" }]}
                >
                    <Select  onChange={handleChange}
                   placeholder="Select a type"
                  defaultValue="demo"
                options={[
                  { value: 'demo', label: 'demo' },
                  { value: 'meeting', label: 'meeting' },
                  { value: 'conference', label: 'conference' },
                ]}/>
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="form-group">
                <Form.Item
                  name="Event Title"
                  label="Event Title"
                  colon={false}
                  rules={[{ required: true, message: "title is required" }]}
                >
                  <Input />
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="form-group">
                <Form.Item
                  name="Event Desc"
                  label="Event Description"
                  colon={false}
                  rules={[{ required: true, message: "desc is required" }]}
                >
                  <Input />
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="form-group">
                <Form.Item
                  name="image"
                  label="Image"
                  valuePropName="fileList"
                  getValueFromEvent={imgname}
                  rules={[{ required: true }]}
                >
                  <Upload action="/upload.do" listType="picture-card">
                    <div>
                      <PlusOutlined />
                      <div>Upload</div>
                    </div>
                  </Upload>
                </Form.Item>
              </div>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="form-group">
                <Form.Item
                  name="date"
                  label="Date"
                  colon={false}
                  rules={[{ required: true, message: "please select date" }]}
                >
                  <DatePicker />
                </Form.Item>
              </div>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="form-group">
                <Form.Item
                  name="time-picker"
                  colon={false}
                  rules={[{ required: true, message: "select start time" }]}
                  label="Start Time"
                  {...config}
                >
                  <TimePicker />
                </Form.Item>
              </div>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="form-group">
                <Form.Item
                  name="time-picker2"
                  label="End Time"
                  {...config}
                  colon={false}
                  rules={[{ required: true, message: "select end time" }]}
                >
                  <TimePicker />
                </Form.Item>
              </div>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="form-group">
                <Form.Item
                  name="zoom link"
                  label="Zoom Link"
                  colon={false}
                  rules={[{ required: true, message: "link is required" }]}
                >
                  <Input />
                </Form.Item>
              </div>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="form-group">
                <Form.Item
                  name="team link"
                  label="Teams Link"
                  colon={false}
                  rules={[{ required: true, message: "desc is required" }]}
                >
                  <Input />
                </Form.Item>
              </div>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="form-group">
                <Form.Item
                  name="repeat"
                  label="Repeat"
                  colon={false}
                  rules={[{ required: true, message: "desc is required" }]}
                >
                  <Select>
                    <Select.Option value="demo">Demo</Select.Option>
                    <Select.Option value="demo">Demo</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Form.Item
              wrapperCol={{
                xs: { span: 24, offset: 0 },
                sm: { span: 16, offset: 8 },
              }}
            >
              <Button type="primary" htmlType="Create">
                Create
              </Button>
            </Form.Item>
          </Row> */}
      </Form>
    </div>
  );
}
