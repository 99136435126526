import { createAsyncThunk } from "@reduxjs/toolkit";
import leavemanagement from "../services/leavemanagement";

//get leavemanagement landingpage action
export const getLMLandingPage = createAsyncThunk(
  "getLMLandingPage",
  async (args: any, { rejectWithValue }) => {
    const url = `api/v1/dex/leave/landing-page`;
    try {
      const response = await leavemanagement.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getLeaveTypes = createAsyncThunk(
  "getLeaveTypes",
  async (args: any, { rejectWithValue }) => {
    const url = `api/v1/dex/leave/types`;
    try {
      const response = await leavemanagement.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getLeaveTemplate = createAsyncThunk(
  "getLeaveTemplate",
  async (args: any, { rejectWithValue }) => {
    console.log("args", args);
    const url = `api/v1/dex/leave/leave_template?categoryName=${args.templateId}`;
    try {
      const response = await leavemanagement.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUpcomingLeaves = createAsyncThunk(
  "getUpcomingLeaves",
  async (args: any, { rejectWithValue }) => {
    console.log("args", args);
    const url = `api/v1/dex/leave/holiday-calendar/v2?locationCode=IND_BLR&tag=UPCOMING&isWEB=TRUE&countryCode=IND`;
    try {
      const response = await leavemanagement.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)
