import React from "react";
import { Modal } from "antd";
import "./modal.css";
import Basket from "../../../themes/assets/images/svg/leave_management/Basket.svg";
import Calendar from "react-calendar";
import Left from "../../../themes/assets/images/svg/leave_management/ChevronLeft.svg";
import Right from "../../../themes/assets/images/svg/leave_management/ChevronRight.svg";
export const CustomPopupItemCard = ({ onClick, data }) => {
  return (
    <div onClick={onClick} className="custom-modal-card-container">
      <img height={24} width={24} src={data?.value} />
      <div className="modal-card-title">{data?.displayName}</div>
    </div>
  );
};
export const LeaveTypePopup = ({
  isModalOpen,
  setModalOpen,
  data,
  setLeaveType,
}) => {
  const onClickClose = () => {
    setModalOpen(false);
  };
  const onClickItem = (data: any) => {
    console.log("onclick", data);
    setLeaveType(data);
    setModalOpen(false);
  };

  return (
    <Modal
      className="modal-container leave-type-modal"
      title="Leave Type"
      open={isModalOpen}
      onCancel={onClickClose}
    >
      <div className="modal-body-container leave-type-body-container">
        {data.map((item: any, index: number) => (
          <CustomPopupItemCard
            key={index}
            data={item}
            onClick={() => onClickItem(item)}
          />
        ))}
      </div>
    </Modal>
  );
};
export const ReasonTypePopup = ({ isModalOpen, setModalOpen }) => {
  const onClickClose = () => {
    setModalOpen(false);
  };

  const data: { icon: React.ReactNode; title: string }[] = [
    {
      icon: Basket,
      title: "Leave Basket",
    },
    {
      icon: Basket,
      title: "Leave Basket",
    },
    {
      icon: Basket,
      title: "Leave Basket",
    },
    {
      icon: Basket,
      title: "Leave Basket",
    },
  ];
  return (
    <Modal
      className="modal-container reason-type-modal"
      title="Reason Type"
      open={isModalOpen}
      onCancel={onClickClose}
    >
      <div className="modal-body-container reason-type-body-container">
        {data.map((item) => (
          <CustomPopupItemCard data={item} onClick={onClickClose} />
        ))}
      </div>
    </Modal>
  );
};
export const SelectDatesPopup = ({ isModalOpen, setModalOpen }) => {
  const onClickClose = () => {
    setModalOpen(false);
  };
  return (
    <Modal
      className="modal-container select-dates-modal"
      title="Select Dates"
      open={isModalOpen}
      onCancel={onClickClose}
    >
      <Calendar
        maxDetail="month"
        selectRange
        minDetail="month"
        next2Label={null}
        prev2Label={null}
        nextLabel={<img src={Right} />}
        prevLabel={<img src={Left} />}
        className="select-range-calendar"
        formatShortWeekday={(locale, date) =>
          ["S", "M", "T", "W", "T", "F", "S"][date.getDay()]
        }
        showNeighboringMonth={false}
      />
      <div className="select-dates-modal-footer">
        <div className="select-dates-modal-footer-divider"></div>
        <div className="select-dates-modal-dates-text">
          23 Mar 2024 - 27 Mar 2024
          <span className="select-dates-modal-no-of-dates">(5 days)</span>
        </div>
        <div className="select-dates-modal-button">Select Dates</div>
      </div>
    </Modal>
  );
};
export const DropDownPopup = (props: any) => {
  const { isModalOpen, setModalOpen, setSelectedItem, data, title } = props;
  function onClickClose() {
    setModalOpen(false);
  }
  function onClickItem(item: any) {
    console.log("check selected item", item);
    setSelectedItem(item);
    setModalOpen(false);
  }
  return (
    <Modal
      className="modal-container leave-type-modal"
      title={title}
      open={isModalOpen}
      onCancel={onClickClose}
    >
      <div className="modal-body-container leave-type-body-container">
        {data.map((item: any, index: number) => (
          <CustomPopupItemCard
            key={index}
            data={item}
            onClick={() => onClickItem(item)}
          />
        ))}
      </div>
    </Modal>
  );
};
