import { Button } from "antd";
import React from "react";


function EditButton(props:any) {
    return(
        <>
        <Button type="primary" onClick={props.editBtnAction}><img src={props.editImage} /></Button>
        </>
    )
}


export default EditButton;