import { useState, useEffect } from "react";
import { Table, Popconfirm, Modal } from "antd";
import "../../common/table/table.css";
import FilterDropdown from "./filterDropdown";
import { sortingIcon } from "../svg/sortingIcon";
import { isEmpty } from "lodash";
import { headerList, ContentHeaderList } from "./data";
import { getAdmin, getAllSurveyDetails } from "../../../redux/actions/admin";
import {
  SURVEY_TABLE_EDIT_BTN,
  SURVEY_TABLE_DELETE_BTN,
  PUBLISH_TO_PROD,
  PUBLISHED,
  NOTIFICATIONS_DETAILS,
  CANCEL,
} from "../../../constants";
// import tableContentData from "./content.json";
// import surveyQuestion from "./original_survery.json";
import Pagination from "../../../components/common/pagination/pagination";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import { getLoggedInUserData } from "../../utils/util";
import ModalDialog from "../modal/modal";
import NotificationModal from "../../MyWork/NotificationModal";
import Content from "../../my_space/Content";
import {
  setData,
  setSelectedFilter,
  setAppliedFilter,
} from "../../../redux/reducers/tableSlice";

const RenderColumns = ({ record, headerKey }: any) => {
  const editItem = () => console.log("edit");
  const [modal, contextHolder] = Modal.useModal();
  const getLoggedInUserDetails = getLoggedInUserData();
  const [popupOpen, setPopupOpen] = useState(false);

  const contentEdit = () => {
    modal.confirm({
      title: "Edit",
      icon: null,
      closable: true,
      content: <Content />,
      okText: "Save",
      cancelText: "Cancel",
    });
  };
  const handlePublishToPROD = () => {
    setPopupOpen(true);
  };

  const handleCancel = () => {
    setPopupOpen(false);
  };
  const handleOk = () => {};

  if (popupOpen) {
    return (
      <ModalDialog
        openModal={popupOpen}
        DialogHeading={
          <div className="notification_Details">{NOTIFICATIONS_DETAILS}</div>
        }
        closeIcon={true}
        modalImageStyles={{ display: "none" }}
        modalHeadingStyles={{ display: "none" }}
        msgContent={<NotificationModal />}
        okTxt={<div className="publish_To_Prod">{PUBLISH_TO_PROD}</div>}
        cancelTxt={CANCEL}
        cancelBtn={handleCancel}
        okBtn={handleOk}
        onOk={handleOk}
        onCancel={handleCancel}
        modalStyles={"contentStyle"}
        maskClosable={false}
      />
    );
  }

  if (headerKey === "actionType") {
    return (
      <>
        {record?.actionType === "PROD_MOVE" ? (
          <button className="publish-to-prod-btn">{PUBLISHED}</button>
        ) : getLoggedInUserDetails?.userGroups?.includes("CONSOLE_ADMIN") ? (
          record?.type === "SUCCESS_STORY" ||
          record?.type === "STORY_OF_THE_DAY" ? (
            <button className="publishedbutton" onClick={handlePublishToPROD}>
              {PUBLISH_TO_PROD}
            </button>
          ) : (
            <button className="publishedbutton"> {PUBLISH_TO_PROD} </button>
          )
        ) : (
          ""
        )}
      </>
    );
  }

  if (headerKey === "status") {
    return (
      <div className="edit-delete-icons-parent">
        <span className="table-edit-icon" onClick={editItem}>
          <button className="edit-button" onClick={contentEdit}>
            {SURVEY_TABLE_EDIT_BTN}
          </button>
        </span>
        <span>
          <Popconfirm title="Are you sure？" okText="Yes" cancelText="No">
            <button className="delete-button">{SURVEY_TABLE_DELETE_BTN}</button>
          </Popconfirm>
        </span>
        {contextHolder}
      </div>
    );
  }
  return <b>{record[headerKey]}</b>;
};

const Tablecard = (props: any) => {
  const dispatch = useAppDispatch();
  const { data, allData, selectedFilter, appliedFilter } =
    useAppSelector((state: any) => state.tableDetails) || {};

  const initialSelectedFilters = {
    order: [],
    type: "",
    content: "",
    _id: "",
    question: "",
    questionId: "",
    date: "",
    publish: "",
    title: "",
  };

  const headerListInfo =
    props?.currentTab === "contents" ? ContentHeaderList : headerList;
  // const [dataSource, setDataSource] = useState<any>(
  //   props?.currentTab === "contents"
  //     ? displayedData?.contents
  //     : displayedData?.surveyQuestions
  // );
  // const completeData: any[] =
  //   props?.currentTab === "contents"
  //     ? displayedData?.contents
  //     : displayedData?.surveyQuestions;

  useEffect(() => {
    dispatch(
      props.currentTab === "contents" ? getAdmin({}) : getAllSurveyDetails({})
    );
  }, []);

  const handleFilterApply = (headerId: any) => {
    const restValue = selectedFilter[headerId];
    const updateSelectedFilters = {
      ...initialSelectedFilters,
      [headerId]: selectedFilter[headerId],
    };
    dispatch(setSelectedFilter(updateSelectedFilters));

    if (restValue) {
      const updateData = (allData || [])?.filter(
        (ele: any) => ele?.[headerId] === restValue
      );
      dispatch(setData(updateData));
    }

    dispatch(
      setAppliedFilter({
        ...initialSelectedFilters,
        [headerId]: selectedFilter[headerId],
      })
    );
  };
  const columns: any = headerListInfo?.map((header: any) => {
    const { isFiltered = false, isSort = false } = header;
    return {
      title: () => {
        if (header.id === "employeeAction") {
          return header.label;
        }
        return (
          <div className="table_title_sorticon">
            {header?.title} {header?.isSort && sortingIcon()}
          </div>
        );
      },
      sorter: (a: any, b: any) =>
        isSort ? a[header.key].localeCompare(b[header.key]) : false,
      filterDropdown: (props: any) => {
        return (
          headerListInfo?.length && (
            <FilterDropdown
              filterProps={props}
              filterId={header.id}
              tableData={allData}
              onApply={() => {
                handleFilterApply(header.id);
              }}
              onClear={() => {
                setAppliedFilter(initialSelectedFilters);
                dispatch(setData(allData));
              }}
              setSelectedFilters={(value: any) =>
                dispatch(setSelectedFilter(value))
              }
              selectedFilter={selectedFilter}
            />
          )
        );
      },
      filterIcon: (filtered: any, filterProps: any) => {
        return (
          <svg
            width="18"
            height="18"
            className={`${
              isEmpty(appliedFilter[header.id]) ? "" : "filterBlue-svg"
            }`}
            viewBox="0 0 20 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13 13.0996C13 13.3648 12.8946 13.6192 12.7071 13.8067C12.5196 13.9943 12.2652 14.0996 12 14.0996H8C7.73478 14.0996 7.48043 13.9943 7.29289 13.8067C7.10536 13.6192 7 13.3648 7 13.0996C7 12.8344 7.10536 12.58 7.29289 12.3925C7.48043 12.205 7.73478 12.0996 8 12.0996H12C12.2652 12.0996 12.5196 12.205 12.7071 12.3925C12.8946 12.58 13 12.8344 13 13.0996ZM17 7.09961C17 7.36483 16.8946 7.61918 16.7071 7.80672C16.5196 7.99425 16.2652 8.09961 16 8.09961H4C3.73478 8.09961 3.48043 7.99425 3.29289 7.80672C3.10536 7.61918 3 7.36483 3 7.09961C3 6.83439 3.10536 6.58004 3.29289 6.3925C3.48043 6.20497 3.73478 6.09961 4 6.09961H16C16.2652 6.09961 16.5196 6.20497 16.7071 6.3925C16.8946 6.58004 17 6.83439 17 7.09961ZM20 1.09961C20 1.36483 19.8946 1.61918 19.7071 1.80672C19.5196 1.99425 19.2652 2.09961 19 2.09961H1C0.734784 2.09961 0.48043 1.99425 0.292893 1.80672C0.105357 1.61918 0 1.36483 0 1.09961C0 0.834393 0.105357 0.580039 0.292893 0.392503C0.48043 0.204966 0.734784 0.0996094 1 0.0996094H19C19.2652 0.0996094 19.5196 0.204966 19.7071 0.392503C19.8946 0.580039 20 0.834393 20 1.09961Z"
              fill="black"
            />
          </svg>
        );
      },

      className: !isFiltered
        ? `hide-filter-icon ${!isSort ? "hide-filter-sorter" : ""}`
        : "",
      render: (text: any, record: any, index: any) => (
        <RenderColumns
          text={text}
          record={record}
          headerKey={header.key}
          index={index}
        />
      ),
    };
  });

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        className="table_structure"
        pagination={false}
      />
      <Pagination />
    </>
  );
};

export default Tablecard;
