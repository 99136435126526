const tempMyWorkData = {
  webIconInfos: {
    navigationTab: [
      {
        iconId: "Approvals",
        iconLabel: "Approvals",
        activeImageUrl: "",
        inactiveImageUrl: "",
        url: "approvals",
      },
      {
        iconId: "Competency",
        iconLabel: "Competency",
        activeImageUrl: "",
        inactiveImageUrl: "",
        url: "competency",
        redirectUrl: "https://intranet.altimetrik.com/competency",
      },
      {
        iconId: "CREO",
        iconLabel: "CREO",
        activeImageUrl: "",
        inactiveImageUrl: "",
        url: "creo",
        redirectUrl: "https://intranet.altimetrik.com/innovation_creo",
      },
      {
        iconId: "Facility_Admin",
        iconLabel: "Facility & Admin",
        activeImageUrl: "",
        inactiveImageUrl: "",
        url: "facility-admin",
        redirectUrl: "https://intranet.altimetrik.com/facilities",
      },
      {
        iconId: "Innovation",
        iconLabel: "Innovation",
        activeImageUrl: "",
        inactiveImageUrl: "",
        url: "innovation",
        redirectUrl: "https://intranet.altimetrik.com/innovation_creo",
      },
      {
        iconId: "Leadership_Dashboard",
        iconLabel: "Leadership Dashboard",
        activeImageUrl: "",
        inactiveImageUrl: "",
        url: "leadership-dashboard",
        redirectUrl: "https://dashboard.altimetrik.com/",
      },
      {
        iconId: "Learning_Development",
        iconLabel: "Learning & Development",
        activeImageUrl: "",
        inactiveImageUrl: "",
        url: "learning-development",
        redirectUrl: "https://intranet.altimetrik.com/ld",
      },
      {
        iconId: "Performance",
        iconLabel: "My Performance",
        activeImageUrl: "",
        inactiveImageUrl: "",
        url: "performance",
      },

      {
        iconId: "team_performance",
        iconLabel: "Team Performance",
        activeImageUrl: "",
        inactiveImageUrl: "",
        url: "performance",
      },
      {
        iconId: "Playground",
        iconLabel: "Playground",
        activeImageUrl: "",
        inactiveImageUrl: "",
        url: "playground",
        redirectUrl:
          "https://playground.altimetrik.com/#/talent-transformation/landing/employee",
      },
      {
        iconId: "PRISM",
        iconLabel: "PRISM",
        activeImageUrl: "",
        inactiveImageUrl: "",
        url: "prism",
        redirectUrl:
          "https://hcm44.sapsf.com/sf/start?_s.crb=bLfHImFj1wxix60YYC%252fRoJywdmtUYlPbrE9pmGFSAIo%253d",
      },
      {
        iconId: "Refferals",
        iconLabel: "Refferals",
        activeImageUrl: "",
        inactiveImageUrl: "",
        url: "refferals",
        redirectUrl: "https://intranet.altimetrik.com/internaljobposting",
      },
      {
        iconId: "Sodexo",
        iconLabel: "Sodexo",
        activeImageUrl: "",
        inactiveImageUrl: "",
        url: "sodexo",
        redirectUrl:
          "https://forms.office.com/Pages/ResponsePage.aspx?id=wtaJoh87xEuPoGhm_zAAUrcjZshJHJBMvUX8qvjXo8JUREdTQklBS1I1UU1JQTRaWVg0VDVQVFZCWS4u",
      },
      {
        iconId: "Travel_Expense",
        iconLabel: "Travel & Expense",
        activeImageUrl: "",
        inactiveImageUrl: "",
        url: "travel-expense",
        redirectUrl: "https://us2.concursolutions.com/home.asp",
      },
      {
        iconId: "Timesheet",
        iconLabel: "Timesheet",
        activeImageUrl: "",
        inactiveImageUrl: "",
        url: "timesheet",
        redirectUrl: "https://intranet.altimetrik.com/timesheethome",
      },
      {
        iconId: "WINGS",
        iconLabel: "WINGS",
        activeImageUrl: "",
        inactiveImageUrl: "",
        url: "wings",
        redirectUrl: "https://intranet.altimetrik.com/wings",
      },
      {
        iconId: "Admin_Panel",
        iconLabel: "Admin Panel",
        activeImageUrl: "",
        inactiveImageUrl: "",
        url: "admin-panel",
      },
      {
        iconId: "Calendar",
        iconLabel: "Calendar",
        activeImageUrl: "",
        inactiveImageUrl: "",
        url: "calendar",
      },
      {
        iconId: "Separation",
        iconLabel: "Separation",
        activeImageUrl: "",
        inactiveImageUrl: "",
        url: "separation",
      },
      {
        iconId: "Goals",
        iconLabel: "Goals",
        activeImageUrl: "",
        inactiveImageUrl: "",
        url: "goals",
      },
    ],
  },
};
export default tempMyWorkData;
