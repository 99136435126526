import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface newGoalInterface {
  newGoal: any;
  isValidated: boolean;
}

const initialState: newGoalInterface = {
  newGoal: null,
  isValidated: false,
};

const newGaolSlice = createSlice({
  name: "newGaol",
  initialState,
  reducers: {
    setNewGoal: (state, action: PayloadAction<number>) => {
      state.newGoal = action.payload;
    },
    setIsValidated: (state, action) => {
      state.isValidated = action.payload;
    },
  },
});

export const { setNewGoal, setIsValidated } = newGaolSlice.actions;

export default newGaolSlice.reducer;
