import { createAsyncThunk } from "@reduxjs/toolkit";
import notification from "../services/notification";

//All Downtime notification
export const getDownTimeNotification = createAsyncThunk(
  "getDownTimeNotification",
  async (data: any, { rejectWithValue }) => {
    const url = `api/v1/downTimeNotification`;
    try {
      const response = await notification.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
