import AccountDropdown from "../../LeaderBoard/LeaderBoardLanding/Dropdown";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { CLEAR_ALL, POINTS_MONTH_FORMAT } from "../../../../../constants";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store/redux-hooks";
import { getFilters } from "../../../../../redux/actions/gamification";
import { DatePicker } from "antd";
import moment from "moment";
import {
  setActiveModulePoints,
  setActiveMonthPoints,
} from "../../../../../redux/reducers/gamification";

const LeaderSearchMonth = () => {
  const dispath = useAppDispatch();

  const filters = useAppSelector(
    (state: any) =>
      state?.gamification?.pointSummaryData?.data?.Filters?.gameNames
  );
  const activeFilters = useAppSelector(
    (state: any) => state?.gamification?.activeModulesPoints
  );

  const activeMonthPoints = useAppSelector(
    (state: any) => state?.gamification?.activeMonthPoints
  );

  const moduleItems = filters?.map((item) => {
    return { key: item, label: item };
  });
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const [activeMonth, setActiveMonth] = useState<any>("");

  useEffect(() => {
    const closeDropdown = () => {
      setActiveDropdown(null);
    };
    document.body.addEventListener("click", closeDropdown);
    return () => {
      document.body.removeEventListener("click", closeDropdown);
    };
  }, []);

  const handleDropdownClick = (
    dropdownName: string,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setActiveDropdown(activeDropdown === dropdownName ? null : dropdownName);
  };

  const disabledFutureMonths = (current) => {
    // Disable if the current date is after the start of the next month
    return current && current >= moment().startOf("month").add(1, "month");
  };

  const onClickClearAll = () => {
    dispath(setActiveModulePoints([]));
    dispath(setActiveMonthPoints());
    setActiveMonth("");
  };

  const onClickMonth = (e) => {
    setActiveMonth(e);
    dispath(setActiveMonthPoints(e));
  };

  useEffect(() => {
    setActiveMonth(activeMonthPoints);
  }, [activeMonthPoints]);

  return (
    <div className="month_search-pointer">
      <div className="leadersearch">
        <div className="leader_buttons">
          <div
            onClick={(e) => handleDropdownClick("account", e)}
            className={`account_leaderboard ${
              activeDropdown === "account" ? "clicked" : ""
            }${activeFilters?.length > 0 ? "clicked" : ""}`}
          >
            <a className="account_title">
              Module{" "}
              {activeFilters?.length > 0 ? `(${activeFilters?.length})` : null}
              {activeDropdown === "account" ? (
                <UpOutlined style={{ height: "20px", width: "20px" }} />
              ) : (
                <DownOutlined />
              )}
              <div className="dropdown_game">
                {activeDropdown === "account" && (
                  <AccountDropdown
                    data={moduleItems}
                    onClick={(e) => handleDropdownClick("account", e)}
                    module={"Points"}
                  />
                )}
              </div>
            </a>
          </div>
          <DatePicker
            picker="month"
            placeholder="Month"
            format={POINTS_MONTH_FORMAT}
            inputReadOnly={true}
            suffixIcon={<DownOutlined />}
            disabledDate={disabledFutureMonths}
            className={activeMonth ? "points-active" : ""}
            //dropdownAlign={dropdownAlign}
            value={activeMonth}
            onChange={onClickMonth}
            dropdownClassName="point-histroy-mnt"
          />
          <button
            disabled={!(activeFilters?.length > 0) && !activeMonth}
            onClick={onClickClearAll}
            className={
              activeFilters?.length > 0 || activeMonth
                ? "clear_game_active"
                : "clear_game"
            }
          >
            {CLEAR_ALL}
          </button>
        </div>
      </div>
    </div>
  );
};
export default LeaderSearchMonth;
