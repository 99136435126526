import { createSlice } from "@reduxjs/toolkit";
import {
  getLMLandingPage,
  getLeaveTemplate,
  getLeaveTypes,
  getUpcomingLeaves,
} from "../actions/leavemanagement";

type leavemanagementLandingPageData = {
  error: boolean;
  loading: boolean;
  loadingList: boolean;
  loadingUpcomingLeaves: boolean;
  landingpageData: any;
  leaveTemplate: any;
  leaveTypesData: any;
  upcomingLeaves: any;
};

const initialDetailState: leavemanagementLandingPageData = {
  error: false,
  loading: false,
  loadingList: false,
  loadingUpcomingLeaves: false,
  landingpageData: [],
  leaveTemplate: [],
  leaveTypesData: [],
  upcomingLeaves: [],
};

export const leavemanagement: any = createSlice({
  name: "leavemanagement",
  initialState: initialDetailState,
  reducers: {
    // setActivePayslip: (state, action) => {
    //   state.activePayslip = action?.payload;
    // },
  },
  extraReducers: (builder) => {
    // getLMLandingPage
    builder.addCase(getLMLandingPage.pending, (state) => {
      state.loadingList = true;
      state.error = false;
    });
    builder.addCase(getLMLandingPage.fulfilled, (state: any, action) => {
      state.loadingList = false;
      state.landingpageData = action?.payload;
    });
    builder.addCase(getLMLandingPage.rejected, (state: any, action) => {
      state.loadingList = false;
      state.error = true;
    });

    //getLeaveTypes
    builder.addCase(getLeaveTypes.pending, (state) => {
      state.loadingList = true;
      state.error = false;
    });
    builder.addCase(getLeaveTypes.fulfilled, (state: any, action) => {
      state.loadingList = false;
      state.leaveTypesData = action?.payload;
    });
    builder.addCase(getLeaveTypes.rejected, (state: any, action) => {
      state.loadingList = false;
      state.error = true;
    });

    //getUpcomingLeaves
    builder.addCase(getUpcomingLeaves.pending, (state) => {
      state.loadingUpcomingLeaves = true;
      state.error = false;
    });
    builder.addCase(getUpcomingLeaves.fulfilled, (state: any, action) => {
      state.loadingUpcomingLeaves = false;
      state.upcomingLeaves = action?.payload;
    });
    builder.addCase(getUpcomingLeaves.rejected, (state: any, action) => {
      state.loadingUpcomingLeaves = false;
      state.error = true;
    });

    //  getLeaveTemplateAPI
    // builder.addCase(getLeaveTemplate.pending, (state) => {
    //   state.loadingList = true;
    //   state.error = false;
    // });
    // builder.addCase(getLeaveTemplate.fulfilled, (state: any, action) => {
    //   state.loadingList = false;
    //   state.leaveTemplate = action?.payload;
    // });
    // builder.addCase(getLeaveTemplate.rejected, (state: any, action) => {
    //   state.loadingList = false;
    //   state.error = true;
    // });
  },
});

export default leavemanagement.reducer;
export const {} = leavemanagement.actions;
