import React from "react";
import "./SubmittedGoal.css";
import AvatarProfile from "../../Avatar/AvatarProfile";
import { EmptyReview } from "./SubmittedGoal";
import { Rate } from "antd";

const OverAllCommentCardManager = ({
  primaryData,
  secondaryData = false,
  profileData,
  otherProfileData,
  hideManagerCard = false,
  title = "",
  description = "",
  showRating = false,
}) => {
  return (
    <div className="submitted-goal-asp-form">
      <div className="goal-title">
        {title ? title : primaryData?.assessmentComment?.title}
      </div>
      {description && <div className="goal-title-desc">{description}</div>}
      <div className="feedback-asp-view">
       { primaryData?<div className="review-comment-card">
          <div
            className="comment-desc"
            dangerouslySetInnerHTML={{
              __html: primaryData?.assessmentComment?.comment,
            }}
          />
          {showRating && (
            <Rate value={primaryData?.rateScale?.rating} disabled />
          )}
          <div className="profile-checkin">
            <div className="avatar-container">
              <AvatarProfile
                name={profileData?.userName}
                size={32}
                profilePic={
                  profileData?.profilePictureImage &&
                  `data:image/png;base64,${profileData?.profilePictureImage}`
                }
              />
            </div>

            <div className="userData-checkin">
              <div className="name-checkin">{profileData?.userName}</div>
              <div className="designation-checkin">
                {profileData?.designation}
              </div>
            </div>
          </div>
        </div>: (
          !hideManagerCard && <EmptyReview />
        )}
        {secondaryData ? (
          <div className="review-comment-card">
            <div
              className="comment-desc"
              dangerouslySetInnerHTML={{
                __html: secondaryData?.assessmentComment?.comment,
              }}
            />
            <div className="profile-checkin">
              <div className="avatar-container">
                <AvatarProfile
                  name={otherProfileData?.userName}
                  size={32}
                  profilePic={
                    otherProfileData?.profilePictureImage &&
                    `data:image/png;base64,${otherProfileData?.profilePictureImage}`
                  }
                />
              </div>

              <div className="userData-checkin">
                <div className="name-checkin">{otherProfileData?.userName}</div>
                <div className="designation-checkin">
                  {otherProfileData?.designation}
                </div>
              </div>
            </div>
          </div>
        ) : (
          !hideManagerCard && <EmptyReview />
        )}
      </div>
    </div>
  );
};

export default OverAllCommentCardManager;
