import React, { useEffect, useState } from "react";
import "./index.css";
import BreadcrumbComponent from "../../../common/breadcrumb/breadcrumb";
import { notification, Spin, Steps } from "antd";
import Slider from "react-slick";
import SubmittedGoal from "../../components/SubmittedGoal";
import CompetancyCard from "../../components/CompetancyCard";
import SignatureCard from "../../components/SignatureCard";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../aspform.css";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store/redux-hooks";
import {
  getAspFormEmployeeGoalSignature,
  getAspFormSteps,
  postAspFormEmployeeGoalSign,
} from "../../../../redux/actions/performance";
import CommonTable from "../../../common/CommonTable";
import ErrorComponent from "../../../errorComponent/ErrorComponent";
import {
  ASP_FORM,
  ASP_FORM_TEXT,
  CANCEL,
  CONFIRMATION_TITLE_CANCEL,
  DONE,
  IN_PROGRESS,
  MY_SPACE,
  NO,
  NOTIFICATION_DURATION,
  SIGN_AND_COMPLETE,
  SLIDER_SPLIT_COUNT,
  START,
  WARNING,
  YEAR_END_EVALUATION,
  YES,
} from "../../../../constants";
import ModalDialog from "../../../common/modal/modal";
import { notificationAction } from "../../../../redux/reducers/performance";
import { ToastSuccessIcon } from "../../../common/svg/svg";
import OverAllCommentCardManager from "../../components/OverAllCommentCardManager";
import amplitude from "amplitude-js";

const EmployeeSignature = () => {
  const StickyFooter = ({ sign, handleClick }) => {
    return (
      <div className="footer-sticky-asp-end">
        {sign ? (
          <div className="footer-sticky-asp-done">
            <div
              onClick={() => navigate(-1)}
              className="button-footer-sticky-done"
            >
              {DONE}
            </div>
          </div>
        ) : (
          <div className="footer-sticky-asp-submit">
            <div
              onClick={() => {
                setCancelPopup(true);
              }}
              className="button-footer-sticky-cancel"
            >
              {CANCEL}
            </div>
            <div onClick={handleClick} className="button-footer-sticky-sign">
              {SIGN_AND_COMPLETE}
            </div>
          </div>
        )}
      </div>
    );
  };

  const [api, contextHolder] = notification.useNotification({
    top: 0,
    maxCount: 1,
  });

  const navigate = useNavigate();
  const [settings, setSettings] = useState<any>({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
  });

  const params = useParams();
  const { year = "" } = params;

  const {
    getAspFormStepsList,
    isLoading,
    viewGoalRawData,
    getAspFormEmployeeGoalSignatureLoading,
    getAspFormEmployeeGoalSignatureError,
    configuration,
  } = useAppSelector((state: any) => state.performance) || {};

  const { error = false } = configuration;

  const onCloseHandler = () => {
    dispatch(
      notificationAction({
        type: WARNING,
        message: "",
        isShowNotification: false,
      })
    );
  };
  useEffect(() => {
    if (configuration.isShowNotification) {
      const type = configuration.alertMessage.type;
      api[type]({
        message: ``,
        description: configuration.alertMessage.message,
        placement: "topRight",
        duration: NOTIFICATION_DURATION,
        icon: <ToastSuccessIcon />,
        className: "custom-toast-area custom-success-toast",
        onClose: onCloseHandler,
      });
    }
  }, [configuration.isShowNotification]);

  const dispatch = useAppDispatch();

  const [initialStepsValue, setInitialStepsValue] = useState<any>([]);
  const [cancelPopup, setCancelPopup] = useState<any>(false);
  const [sign, setSign] = useState(false);
  const handleClick = () => {
    amplitude.getInstance().logEvent("Completed Employee Signature");
    dispatch(
      postAspFormEmployeeGoalSign({
        appraisalId: params?.appraisalId,
        year: year,
      })
    )
      .unwrap()
      .then(() => navigate(`/${START}/${MY_SPACE}/${ASP_FORM}`));
  };
  const [current, setCurrent] = useState(0);

  const onChange = (value: number) => {
    setCurrent(value);
  };

  const progresStatus = initialStepsValue?.filter(
    (item: any) => item.subTitle === "In Progress"
  );

  const currentStep = initialStepsValue
    ?.map((item: any) => item.current)
    .indexOf(true);

  const firstSet =
    initialStepsValue !== undefined
      ? initialStepsValue.slice(0, SLIDER_SPLIT_COUNT)
      : [];
  const secondSet =
    initialStepsValue !== undefined
      ? initialStepsValue.slice(SLIDER_SPLIT_COUNT)
      : [];

  const breadbrumbItems = [
    {
      title: (
        <Link to={`/web/my_space/AspForm`} className="link-highlight">
          {ASP_FORM_TEXT}
        </Link>
      ),
    },
    {
      title: YEAR_END_EVALUATION,
    },
  ];

  useEffect(() => {
    dispatch(
      getAspFormSteps({
        appraisalId: params?.appraisalId,
        year: year,
      })
    );
    dispatch(
      getAspFormEmployeeGoalSignature({
        appraisalId: params?.appraisalId,
        year: year,
      })
    );
  }, []);
  useEffect(() => {
    const stepsList = getAspFormStepsList?.stepsList || [];
    setInitialStepsValue(getAspFormStepsList?.stepsList);
    const progresStatus = stepsList?.findIndex(
      (item: any) => item.subTitle === IN_PROGRESS
    );
    setSettings({
      ...settings,
      initialSlide: progresStatus > SLIDER_SPLIT_COUNT - 1 ? 1 : 0,
    });
  }, [getAspFormStepsList]);

  if (error || getAspFormEmployeeGoalSignatureError) {
    return (
      <div className="p2p-container">
        <BreadcrumbComponent items={breadbrumbItems} />
        <div className="loadingP2P">
          <ErrorComponent
            image={require("../../../../themes/assets/images/something-went-wrong.png")}
            title={"Something Went Wrong"}
            description={
              "We are looking to fix it. Please try again in few minutes."
            }
          />
        </div>
      </div>
    );
  }

  if (isLoading || getAspFormEmployeeGoalSignatureLoading) {
    return (
      <div className="layoutContainer">
        <BreadcrumbComponent items={breadbrumbItems} />
        <div className="spinner-asp">
          <Spin
            spinning={isLoading || getAspFormEmployeeGoalSignatureLoading}
          />
        </div>
      </div>
    );
  }

  const hideCancelPopup = () => setCancelPopup(false);

  const goBack = () => {
    navigate(`/${START}/${MY_SPACE}/${ASP_FORM}`);
  };

  return (
    <Spin spinning={isLoading}>
      <div className="view-form-asp-employee layoutContainer">
        <BreadcrumbComponent items={breadbrumbItems} />
        <div className="title-view-asp">{`${year} Year End Evaluation `}</div>

        <Slider className="stepper-process" {...settings}>
          <div className="stepper-section">
            <Steps
              status="process"
              current={currentStep}
              items={firstSet}
              labelPlacement="vertical"
              className="steps"
            />
          </div>
          <div className="stepper-section">
            <Steps
              status="process"
              current={currentStep}
              items={secondSet}
              labelPlacement="vertical"
              className="steps"
            />
          </div>
        </Slider>

        <div className="section-container-asp-form">
          <div className="form-view-header">
            {
              viewGoalRawData?.overallRating?.managerRatingAndComment
                ?.goalDetails?.[0]?.pageTitle
            }
          </div>
          {viewGoalRawData?.overallRating?.managerRatingAndComment?.goalDetails?.map(
            (item: any, index: any) => {
              return (
                <SubmittedGoal
                  profileData={viewGoalRawData?.userInfo}
                  otherProfileData={viewGoalRawData?.reporteeInfo}
                  data={item}
                  key={index}
                />
              );
            }
          )}

          {/* over all comments on Goal */}
          <OverAllCommentCardManager
            profileData={viewGoalRawData?.userInfo}
            otherProfileData={viewGoalRawData?.reporteeInfo}
            primaryData={
              viewGoalRawData?.overallRating?.managerRatingAndComment
                ?.overAllSelfAssessment
            }
            secondaryData={
              viewGoalRawData?.overallRating?.managerRatingAndComment
                ?.overAllOtherAssessment
            }
            title={
              viewGoalRawData?.overallRating?.managerRatingAndComment
                ?.overAllSelfAssessment?.title ||
              viewGoalRawData?.overallRating?.managerRatingAndComment
                ?.overAllOtherAssessment?.title
            }
          />
        </div>

        <div className="section-container-asp-form">
          <div className="form-view-header">
            {
              viewGoalRawData?.overallRating?.managerRatingAndComment
                ?.competencySectionTitle
            }
          </div>
          {viewGoalRawData?.overallRating?.managerRatingAndComment?.evaluations?.map(
            (item: any, index: any) => {
              return (
                <CompetancyCard
                  profileData={viewGoalRawData?.userInfo}
                  otherProfileData={viewGoalRawData?.reporteeInfo}
                  key={index}
                  data={item}
                />
              );
            }
          )}
        </div>

        {/* OverAll comment and Raiting */}
        {viewGoalRawData?.overallRating?.managerRatingAndComment
          ?.overallFormAssessment && (
          <OverAllCommentCardManager
            profileData={viewGoalRawData?.reporteeInfo}
            otherProfileData={viewGoalRawData?.userInfo}
            primaryData={
              viewGoalRawData?.overallRating?.managerRatingAndComment
                ?.overallFormAssessment
            }
            title={
              viewGoalRawData?.overallRating?.managerRatingAndComment?.title
            }
            description={
              viewGoalRawData?.overallRating?.managerRatingAndComment
                ?.description
            }
            hideManagerCard={true}
            showRating={true}
          />
        )}

        <div className="rating-calculated-container">
          {viewGoalRawData?.overallRating?.managerRatingAndComment
            ?.calculatedRateScale && (
            <div className="rating-calculated-title">
              <span className="calculated-title">
                {
                  viewGoalRawData?.overallRating?.managerRatingAndComment
                    ?.calculatedRateScale?.title
                }
              </span>
              <span className="calculated-rating-btn">
                {
                  viewGoalRawData?.overallRating?.managerRatingAndComment
                    ?.calculatedRateScale?.rating
                }
              </span>
            </div>
          )}
          <div>
            <CommonTable
              goalDetails={
                viewGoalRawData?.overallRating?.managerRatingAndComment
                  ?.goalDetails
              }
              evaluations={
                viewGoalRawData?.overallRating?.managerRatingAndComment
                  ?.evaluations
              }
            />
          </div>
        </div>

        <div>
          <SignatureCard
            // key={index}
            data={{
              signstatus: false
                ? "review-comment-card-signed"
                : "review-comment-card-signature",
              description: viewGoalRawData?.webSignatureSection?.description,
              title: viewGoalRawData?.webSignatureSection?.signatureTitle,
              userSignatureStatus:
                viewGoalRawData?.webSignatureSection?.signatureInfos?.[0],
              otherUserSignatureStatus:
                viewGoalRawData?.webSignatureSection?.signatureInfos?.[1],
              userInfo:
                viewGoalRawData?.webSignatureSection?.employeeInfo || {},
              otherUserInfo:
                viewGoalRawData?.webSignatureSection?.managerInfo || {},
            }}
          />
        </div>
      </div>
      {/* Will be required later */}
      <StickyFooter sign={sign} handleClick={handleClick} />
      {/* Cancel Modal */}
      <ModalDialog
        openModal={cancelPopup}
        DialogHeading={CONFIRMATION_TITLE_CANCEL}
        closeIcon={false}
        modalImageStyles={{ display: "none" }}
        modalHeadingStyles={{ display: "none" }}
        popupDivider={{ display: "none" }}
        indexScroll={{ display: "none" }}
        modalCenterImgStyles={{ display: "none" }}
        msgContent={`Cancelling ${year} Year End Evaluation `}
        okTxt={YES}
        cancelTxt={NO}
        cancelBtn={hideCancelPopup}
        okBtn={goBack}
        onOk={goBack}
        onCancel={hideCancelPopup}
        modalStyles={"backpopup delete-milestone-popup compotency-popup"}
        maskClosable={false}
      />
    </Spin>
  );
};

export default EmployeeSignature;
