import { useEffect, useState } from "react";
import { Card, MenuProps } from "antd";
import "./index.css";
import EmployeeList from "../EmployeeList";
import PendingReview from "../../ManagerView/PendingReview/index";
import { TabView, TabPanel } from "primereact/tabview";
import "primereact/resources/primereact.css";
import {
  EMPLOYEE_LIST,
  PENDING_REVIEWS,
  TEAM_YEAR_END_REVIEW_TITLE,
  TEAMS_ASP,
} from "../../../../constants";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store/redux-hooks";
import { setActiveTeamGoalYear } from "../../../../redux/reducers/performance";
import { getDropDownYears } from "../../../../redux/actions/performance";
import { useParams } from "react-router-dom";

const ManagerASP = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { dropDownYears } = useAppSelector((state: any) => state?.performance);
  const dispatch = useAppDispatch();
  const items: MenuProps["items"] = dropDownYears;
  const year = useAppSelector(
    (state: any) => state?.performance?.teamGoalActiveYear
  );

  const handleYearClick: MenuProps["onClick"] = (e) => {
    dispatch(setActiveTeamGoalYear(e.key));
  };
  useEffect(() => {
    dispatch(getDropDownYears({}));
  }, []);

  return (
    <div className="team-performanceContainer">
      <div className="performance-header">
        <div className="left-side">
          <h5>{TEAM_YEAR_END_REVIEW_TITLE}</h5>
        </div>
      </div>

      <div className="performance-body">
        <Card style={{ width: "100%" }}>
          <TabView activeIndex={activeIndex} className="manager-tabs">
            <TabPanel header={PENDING_REVIEWS}>
              <PendingReview flow={TEAMS_ASP} goalsTabKey={activeIndex} />
            </TabPanel>
            <TabPanel header={EMPLOYEE_LIST}>
              <EmployeeList flow={TEAMS_ASP} goalsTabKey={activeIndex} />
            </TabPanel>
          </TabView>
        </Card>
      </div>
    </div>
  );
};

export default ManagerASP;
