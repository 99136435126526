import { useNavigate } from "react-router-dom";
import { ASP_START_NEXT } from "../../../../../constants";
import FillButton from "../../../../common/button/FillButton";
import { Card, Form, Rate, Input } from "antd";
import EvaluationCard from "../../../ASPForm/GoalEvaluationCard";
import { useState } from "react";
import {
  ASP_COMPETENCY_EVALUATION_MANAGER_LABEL,
  ASP_COMPETENCY_EVALUATION_RATING_LABEL,
  GOAL_ACHIEVEMENT_TITLE,
  MIN_ROWS,
  MAX_ROWS,
  SAVE,
  CANCEL,
  PLACEHOLDER_ADD_COMMENT,
  GOAL_EVALUATION,
  GOAL_ACTIVITY_SUB_TITLE,
  GOAL_EVALUATION_SPRINT,
  GOAL_EVALUATION_QUALITY,
  GOAL_MAIN_PAGE_SUBTITLE1,
  GOAL_EVALUATION_BVR,
} from "../../../../../constants";
import ManagerRatingCard from "../../../../Performance/components/ManagerRatingCard";
import OutlineButton from "../../../../common/button/OutlineButton";
import TextStylesChange from "../../../../Performance/components/TextStylesChange";
import "./ManagerCompotencyEvaluation.css";

const { TextArea } = Input;

const GoalEvaluationList = [
  {
    id: 1,
    EvalutionLabel: "Goal Name",
    EvalutionDescrption: "Sprint Quality Management",
  },
  {
    id: 2,
    EvalutionLabel: "Metric",
    EvalutionDescrption:
      "1. 90 to 100% accurate loading of engineering team 2.1. # feature de-comits due to poor planning and execution are minimal 2.2. Less defect density (2%)",
  },
  {
    id: 3,
    EvalutionLabel: "Start Date",
    EvalutionDescrption: "01/01/2023",
  },
  {
    id: 4,
    EvalutionLabel: "Due Date",
    EvalutionDescrption: "12/31/2023",
  },
  {
    id: 5,
    EvalutionLabel: "Status",
    EvalutionDescrption: "On Track",
  },
  {
    id: 6,
    EvalutionLabel: "Weight",
    EvalutionDescrption: "25%",
  },
  {
    id: 7,
    EvalutionLabel: "Goal Description",
    EvalutionDescrption:
      "Achieve high sprint velocity and on-time product releases so that strategic timelines are met.Ensure best practices followed within the team to produce high quality product and features",
  },
];
const GoalEvaluationListTwo = [
  {
    id: 1,
    EvalutionLabel: "Goal Name",
    EvalutionDescrption: "Quality of Individual Work",
  },
  {
    id: 2,
    EvalutionLabel: "Metric",
    EvalutionDescrption: "Metric 1",
  },
  {
    id: 3,
    EvalutionLabel: "Start Date",
    EvalutionDescrption: "01/01/2023",
  },
  {
    id: 4,
    EvalutionLabel: "Due Date",
    EvalutionDescrption: "12/31/2023",
  },
  {
    id: 5,
    EvalutionLabel: "Status",
    EvalutionDescrption: "On Track",
  },
  {
    id: 6,
    EvalutionLabel: "Weight",
    EvalutionDescrption: "25%",
  },
  {
    id: 7,
    EvalutionLabel: "Goal Description",
    EvalutionDescrption:
      "Achieve high sprint velocity and on-time product releases so that strategic timelines are met.Ensure best practices followed within the team to produce high quality product and features. igh sprint velocity and on-time product releases so that strategic timelines are met.Ensure best practices followed within the team to produce high quality product and feat.",
  },
];
const GoalEvaluationListThree = [
  {
    id: 1,
    EvalutionLabel: "Goal Name",
    EvalutionDescrption: "Backlog Visibility & Readiness",
  },
  {
    id: 2,
    EvalutionLabel: "Metric",
    EvalutionDescrption:
      "1. 90 to 100% accurate loading of engineering team 2.1. # feature de-comits due to poor planning and execution ",
  },
  {
    id: 3,
    EvalutionLabel: "Start Date",
    EvalutionDescrption: "01/01/2023",
  },
  {
    id: 4,
    EvalutionLabel: "Due Date",
    EvalutionDescrption: "12/31/2023",
  },
  {
    id: 5,
    EvalutionLabel: "Status",
    EvalutionDescrption: "On Track",
  },
  {
    id: 6,
    EvalutionLabel: "Weight",
    EvalutionDescrption: "15%",
  },
  {
    id: 7,
    EvalutionLabel: "Goal Description",
    EvalutionDescrption:
      "Achieve high sprint velocity and on-time product releases so that strategic timelines are met.Ensure.",
  },
];
const GoalEvaluation = () => {
  const [value, setValue] = useState("");
  let navigate = useNavigate();
  return (
    <div className="goal-evalution-eval">
      <div className="layoutContainer">
        <div className="goal-evalution-head">
          <h1>
            {GOAL_EVALUATION}
            <span>100.0%</span>
          </h1>
          <Card className="card_list-evalution" style={{ width: "100%" }}>
            <span className="badge_card">{GOAL_ACTIVITY_SUB_TITLE}</span>
            <div className="goal-evalution-title">
              <h2>{GOAL_EVALUATION_SPRINT}</h2>
            </div>
            <div className="compotency-main-section">
              <div className="compotency-inner-section">
                <Form.Item
                  label={ASP_COMPETENCY_EVALUATION_RATING_LABEL}
                  required
                >
                  <div>
                    <Rate
                      className={"custom-rating"}
                      allowHalf
                      defaultValue={0}
                    />
                  </div>
                </Form.Item>
              </div>
              <div className="compotency-inner-section">
                <Form.Item
                  label={ASP_COMPETENCY_EVALUATION_MANAGER_LABEL}
                  required
                >
                  <div>
                    <TextArea
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      placeholder={PLACEHOLDER_ADD_COMMENT}
                      autoSize={{ minRows: MIN_ROWS, maxRows: MAX_ROWS }}
                    />
                  </div>
                </Form.Item>
                <div>
                  <TextStylesChange />
                </div>
              </div>
              <div className="commentCardContainer-manager">
                <ManagerRatingCard />
              </div>
            </div>
            <div className="goal-evalution-list">
              <EvaluationCard title={"Due Date"} value={""} />
            </div>
          </Card>
          <Card className="card_list-evalution">
            <span className="badge_card">{GOAL_ACTIVITY_SUB_TITLE}</span>
            <div className="goal-evalution-title">
              <h2>{GOAL_EVALUATION_QUALITY}</h2>
            </div>
            <div className="compotency-main-section">
              <div className="compotency-inner-section">
                <Form.Item
                  label={ASP_COMPETENCY_EVALUATION_RATING_LABEL}
                  required
                >
                  <div>
                    <Rate
                      className={"custom-rating"}
                      allowHalf
                      defaultValue={0}
                    />
                  </div>
                </Form.Item>
              </div>
              <div className="compotency-inner-section">
                <Form.Item
                  label={ASP_COMPETENCY_EVALUATION_MANAGER_LABEL}
                  required
                >
                  <div>
                    <TextArea
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      placeholder={PLACEHOLDER_ADD_COMMENT}
                      autoSize={{ minRows: MIN_ROWS, maxRows: MAX_ROWS }}
                    />
                  </div>
                </Form.Item>
                <div>
                  <TextStylesChange />
                </div>
              </div>
              <div className="commentCardContainer-manager">
                <ManagerRatingCard />
              </div>
            </div>
            <div className="goal-evalution-list">
              <EvaluationCard title={"Due Date"} value={""} />
            </div>
          </Card>
          <Card className="card_list-evalution" style={{ width: "100%" }}>
            <span className="badge_card">{GOAL_MAIN_PAGE_SUBTITLE1}</span>
            <div className="goal-evalution-title">
              <h2>{GOAL_EVALUATION_BVR}</h2>
            </div>
            <div className="compotency-main-section">
              <div className="compotency-inner-section">
                <Form.Item
                  label={ASP_COMPETENCY_EVALUATION_RATING_LABEL}
                  required
                >
                  <div>
                    <Rate
                      className={"custom-rating"}
                      allowHalf
                      defaultValue={0}
                    />
                  </div>
                </Form.Item>
              </div>
              <div className="compotency-inner-section">
                <Form.Item
                  label={ASP_COMPETENCY_EVALUATION_MANAGER_LABEL}
                  required
                >
                  <div>
                    <TextArea
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      placeholder={PLACEHOLDER_ADD_COMMENT}
                      autoSize={{ minRows: MIN_ROWS, maxRows: MAX_ROWS }}
                    />
                  </div>
                </Form.Item>
                <div>
                  <TextStylesChange />
                </div>
              </div>
              <div className="commentCardContainer-manager">
                <ManagerRatingCard />
              </div>
            </div>
            <div className="goal-evalution-list">
              <EvaluationCard title={"Due Date"} value={""} />
            </div>
          </Card>
          <Card
            className="card_list-evalution field_label"
            style={{ width: "100%" }}
          >
            <div className="compotency-inner-section">
              <Form.Item label={GOAL_ACHIEVEMENT_TITLE}>
                <div>
                  <TextArea
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder={PLACEHOLDER_ADD_COMMENT}
                    autoSize={{ minRows: MIN_ROWS, maxRows: MAX_ROWS }}
                  />
                </div>
              </Form.Item>
              <div>
                <TextStylesChange />
              </div>
            </div>
            <div className="commentCardContainer-manager manager-rating">
              <ManagerRatingCard />
            </div>
          </Card>
        </div>
        <div className="footer-btn-section">
          <div className="footer-btn-left">
            <OutlineButton
              outlineBtnStyles={"cancel-outline-btn"}
              outlineBtnAction={() => {}}
              backText={CANCEL}
            />
          </div>
          <div className="footer-btn-right">
            <FillButton
              fillBtnStyles={"manager-submit-fill-btn"}
              fillBtnAction={() => {
                navigate(
                  `/web/my_work/${"team_performance"}/manager_competency_evaluation`
                );
              }}
              fillBtnTxt={ASP_START_NEXT}
            />
            <OutlineButton
              outlineBtnStyles={"previous-outline-btn"}
              outlineBtnAction={() => {}}
              backText={SAVE}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoalEvaluation;
