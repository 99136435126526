import { createSlice } from "@reduxjs/toolkit";
import {
  download,
  getCalendar,
  getPaySlip_Detail,
  getPaySlips,
} from "../actions/paybooks";
import moment from "moment";

type cleranceDetailState = {
  payslips: any;
  payslip_detail: any;
  calendar: any;
  addedCalendarStrings: any;
  message: any;
  loading: boolean;
  loadingPayslips: boolean;
  loadingPayslipDetails: boolean;
  error: boolean;
  payslipError: boolean;
  payslipDetailError: boolean;
  activePayslip: any;
  downloading: boolean;
  downloadError: boolean;
  downloadSuccess: boolean;
};

const initialDetailState: cleranceDetailState = {
  payslips: [],
  payslip_detail: {},
  calendar: [],
  message: {},
  loading: true,
  error: false,
  loadingPayslips: false,
  loadingPayslipDetails: false,
  payslipError: false,
  payslipDetailError: false,
  activePayslip: null,
  addedCalendarStrings: null,
  downloading: false,
  downloadError: false,
  downloadSuccess: false,
};

export const paybooks: any = createSlice({
  name: "paybooks",
  initialState: initialDetailState,
  reducers: {
    setActivePayslip: (state, action) => {
      state.activePayslip = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCalendar.pending, (state) => {
      state.loading = true;
      state.loadingPayslips = true;
      state.loadingPayslipDetails = true;
      state.calendar = [];
      state.payslips = [];
      state.error = false;
      state.downloadError = false;
      state.downloadSuccess = false;
    });
    builder.addCase(getCalendar.fulfilled, (state: any, action) => {
      state.loading = false;
      const { calendarDetailsList } = action.payload;
      const newdata = (calendarDetailsList || []).map((item: any) => ({
        label: item.calendarYear,
        key: item.calendarId.toString(),
      }));
      state.addedCalendarStrings = action?.payload?.messages;
      state.calendar = newdata;
    });
    builder.addCase(getCalendar.rejected, (state: any, action) => {
      state.loading = false;
      state.error = action.payload;
      state.calendar = [];
      state.error = true;
    });
    builder.addCase(getPaySlips.pending, (state) => {
      state.loadingPayslips = true;
      state.loadingPayslipDetails = true;
      state.payslipError = false;
    });
    builder.addCase(getPaySlips.fulfilled, (state: any, action) => {
      state.loadingPayslips = false;
      const { paySlipInfoList, message } = action.payload;
      const newdata = (paySlipInfoList || []).map((item: any) => ({
        ...item,
        payslipMonthText: moment(item.payslipMonth).format("MMMM YYYY"),
      }));
      state.payslips = newdata;
      state.message = message;
    });
    builder.addCase(getPaySlips.rejected, (state: any, action) => {
      state.loadingPayslips = false;
      // state.loadingPayslipDetails = false;
      state.payslips = [];
      state.payslipError = true;
    });
    builder.addCase(getPaySlip_Detail.pending, (state) => {
      state.loadingPayslipDetails = true;
      state.payslipDetailError = false;
    });
    builder.addCase(getPaySlip_Detail.fulfilled, (state: any, action) => {
      state.loadingPayslipDetails = false;
      //const {paySlipInfoList} = action.payload
      state.payslip_detail = action.payload;
    });
    builder.addCase(getPaySlip_Detail.rejected, (state: any, action) => {
      state.loadingPayslipDetails = false;
      state.payslip_detail = {};
      state.payslipDetailError = true;
    });
    builder.addCase(download.pending, (state) => {
      state.downloading = true;
      state.downloadError = false;
      state.downloadSuccess = false;
    });
    builder.addCase(download.fulfilled, (state, action) => {
      state.downloading = false;
      const { isMailRequest, result, filename } = action?.payload;
      // state.details = action.payload;
      if (isMailRequest) return;
      try {
        var url = window.URL.createObjectURL(result);
        var a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      } catch (e) {
        console.log(e);
        state.downloadError = true;
        return;
      }
      state.downloadSuccess = true;
    });
    builder.addCase(download.rejected, (state: any, action) => {
      state.downloading = false;
      state.downloadError = true;
      // state.error = action.payload;
    });
  },
});

export default paybooks.reducer;
export const { setActivePayslip } = paybooks.actions;

//export const { typeChangeAction,deductionAction,remarksChangeAction,notificationAction,beforeUploadAction,onChangeAction,onRemoveAction } = paybooks.actions;
