import React, { useEffect, useState } from "react";
import "./quarterly-checkin-manager.css";
import "../../components/QuarterReview.css";
import CommentTextArea from "../../components/CommentsTextArea";
import ClosePopUp from "../../components/ClosePopUp";
import EmptyReviewComments from "../../components/EmptyReviewComments";
import ToastMessage from "../../../common/ToastMsg/toastMessage";
import ToastInfo from "../../../../themes/assets/images/performance/svg/ToastInfo.svg";
import ToastClose from "../../../../themes/assets/images/performance/svg/ToastClose.svg";
import ToastError from "../../../../themes/assets/images/performance/svg/ToastError.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store/redux-hooks";
import ExpandMore from "../../../../themes/assets/images/svg/Expand_more.svg";
import {
  getDropDownYears,
  getQuarterlyCheckinComments,
  saveQuarterlyCheckinComments,
} from "../../../../redux/actions/performance";
import { Link, useNavigate, useParams } from "react-router-dom";
import QuarterlyReviewManager from "./QuarterlyReviewManager";
import { Dropdown, MenuProps, Space, Spin, TabsProps } from "antd";
import somethingWrong from "../../../../themes/assets/images/something-went-wrong.png";
import {
  COMMENT_AREA_MANAGER_PLACEHOLDER,
  COMMENT_AREA_PLACEHOLDER,
  CONNECT_DIFFERENT_NETWORK,
  COULD_NOT_SUBMIT,
  MANAGER_QUARTELY_CHECKIN_CLOSE_SUBTITLE,
  MANAGER_QUARTELY_CHECKIN_CLOSE_TITLE,
  NAVIGATION_TEXT_QUARTERLY_MANAGER,
  QUARTERLY_CHECKIN_INCOMPLETED,
  QUARTERLY_SUCCESSFULLY_COMPLETED,
  SOMETHING_WENT_WRONG,
  SUBMIT_QUATERLY_CHECKIN_MANAGER_BTN,
  TEAMS_QUARTERLY_CHECKIN_TITLE,
  UNABLE_TO_SUBMIT,
} from "../../../../constants";
import BreadcrumbComponent from "../../../common/breadcrumb/breadcrumb";
import { setActiveTeamGoalYear } from "../../../../redux/reducers/performance";
import AvatarProfile from "../../../Avatar/AvatarProfile";
import Iicon from "../../../../themes/assets/images/info_grey.png";
import amplitude from "amplitude-js";

const ManagerCommentCard = ({ data, employeeData, managerData }) => {
  return (
    <div className="manager-comment-card-container">
      <div className="manager-sub-container">
        <div className="manager-employee-comment">
          {data?.employee?.comment}
        </div>
        <div className="profile-checkin">
          {employeeData?.profilePictureImage ? (
            <img
              src={`data:image/format;base64,${employeeData?.profilePictureImage}`}
              alt=""
              className="profilepic-checkin"
            />
          ) : (
            <div className="avatar-container">
              <AvatarProfile
                name={employeeData?.userName}
                size={32}
                profilePic={""}
              />
            </div>
          )}
          <div className="userData-checkin">
            <div className="name-checkin">{employeeData?.userName}</div>
            <div className="designation-checkin">
              {employeeData?.designation}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const QuarterlyCheckInManager = (props: any) => {
  const [modalState, setModalState] = useState(false);
  const [visible, setVisible] = useState(false);
  const [comment, setComment] = useState("");
  const [activeData, setActiveData] = useState<any>({});
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const param = useParams();
  let toStopMapping = false;
  const {
    allQuarterlyCheckinComments,
    allQuarterlyCheckinCommentsLoading,
    saveQuarterlyCheckinCommentsLoading,
    saveQuarterlyCheckinCommentsError,
    saveQuarterlyCheckin,
    saveQuarterlyCheckinCommentsSuccess,
    allQuarterlyCheckinCommentsError,
    configuration,
    isLoading,
  } = useAppSelector((state: any) => state.performance) || [];
  const { dropDownYears, teamGoalActiveYear } = useAppSelector(
    (state: any) => state?.performance
  );

  useEffect(() => {
    toStopMapping = false;

    dispatch(
      getQuarterlyCheckinComments({
        year: teamGoalActiveYear,
        reporteeId: param?.reporteeId,
      })
    );
  }, [param?.reporteeId, teamGoalActiveYear]);

  useEffect(() => {
    dispatch(getDropDownYears({ reporteeId: param?.reporteeId }));
  }, []);

  useEffect(() => {
    if (
      saveQuarterlyCheckinCommentsError ||
      saveQuarterlyCheckinCommentsSuccess
    ) {
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      }, 3000);
    }
  }, [saveQuarterlyCheckinCommentsError, saveQuarterlyCheckinCommentsSuccess]);

  const employeeData = allQuarterlyCheckinComments?.employee;
  const managerData = allQuarterlyCheckinComments?.manager;
  const commentsData = allQuarterlyCheckinComments?.comments ?? [];
  const reverseCommentsData = [...commentsData].reverse();

  const onClickSubmit = (year, quarter, comment) => {
    setActiveData({
      year,
      quarter,
      comment,
    });
    setModalState(true);
  };

  const onClickPopUpSubmit = () => {
    dispatch(
      saveQuarterlyCheckinComments({
        year: activeData?.year,
        quarter: activeData?.quarter,
        reporteeId: param?.reporteeId,
        comment: activeData?.comment,
      })
    )
      .unwrap()
      .then((success) => {
        setModalState(false);
        setVisible(true);
        dispatch(
          getQuarterlyCheckinComments({
            year: activeData?.year,
            reporteeId: param?.reporteeId,
          })
        );
      });
    amplitude.getInstance().logEvent("Manager Quaterly Check-in");
  };

  const breadbrumbItems = [
    {
      title: (
        <Link
          to={`/web/my_work/team_quarterly_checkin`}
          className="link-highlight"
        >
          {TEAMS_QUARTERLY_CHECKIN_TITLE}
        </Link>
      ),
    },
    {
      title: employeeData?.userName,
    },
  ];

  const items: MenuProps["items"] = dropDownYears;

  const handleYearClick: MenuProps["onClick"] = (e) => {
    dispatch(setActiveTeamGoalYear(e.key));
  };

  const menuYear = {
    items,
    onClick: handleYearClick,
    selectable: true,
    defaultSelectedKeys: [teamGoalActiveYear],
  };

  const onClickTeamsGoal = () => {
    navigate("/web/my_work/team_performance");
  };

  const onClickTeamYearEnd = () => {
    navigate("/web/my_work/team_performance/asp");
  };

  if (allQuarterlyCheckinCommentsError || configuration?.error) {
    return (
      <div className="error-popup-checkin">
        {employeeData?.userName && (
          <div className="empDetialBreadcrumb">
            <BreadcrumbComponent items={breadbrumbItems} />
          </div>
        )}
        {teamGoalActiveYear && (
          <div className="performance-header-empDetail">
            <div className="left-side">
              <div className="detail-username">{employeeData?.userName}</div>
              <Dropdown menu={menuYear} trigger={["click"]}>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Space className="year-selection">
                    {teamGoalActiveYear}
                    <img src={ExpandMore} alt="" />
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>
        )}
        <div className="quarterly-checkinEmployee centerItems error-pop-checkin">
          <img src={somethingWrong} className="quarterly-checkin-error-main" />
          <div className="something-went-wrong-text-quarterly-checkin">
            {SOMETHING_WENT_WRONG}
          </div>
          <div className="something-went-wrong-subHeder">
            {CONNECT_DIFFERENT_NETWORK}
          </div>
        </div>
      </div>
    );
  }

  if (allQuarterlyCheckinCommentsLoading || isLoading) {
    return (
      <div className="error-popup-checkin">
        {employeeData?.userName && (
          <div className="empDetialBreadcrumb">
            <BreadcrumbComponent items={breadbrumbItems} />
          </div>
        )}
        {teamGoalActiveYear && employeeData?.userName && (
          <div className="performance-header-empDetail">
            <div className="left-side">
              <div className="detail-username">{employeeData?.userName}</div>
              <Dropdown menu={menuYear} trigger={["click"]}>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Space className="year-selection">
                    {teamGoalActiveYear}
                    <img src={ExpandMore} alt="" />
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>
        )}
        <div className="quarterly-checkinEmployee centerItems error-pop-checkin">
          <Spin spinning={allQuarterlyCheckinCommentsLoading || isLoading} />
        </div>
      </div>
    );
  }

  return (
    <Spin spinning={saveQuarterlyCheckinCommentsLoading}>
      <div className="breadcrumb-container-manager">
        <div className="empDetialBreadcrumb">
          <BreadcrumbComponent items={breadbrumbItems} />
        </div>
        <div className="performance-header-empDetail">
          <div className="left-side">
            <div className="detail-username">{employeeData?.userName}</div>
            <Dropdown menu={menuYear} trigger={["click"]}>
              <a
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <Space className="year-selection">
                  {teamGoalActiveYear}
                  <img src={ExpandMore} alt="" />
                </Space>
              </a>
            </Dropdown>
          </div>
        </div>
        <div className="navigation-text-icon icon-margin">
          <img src={Iicon} alt="" className="icon-navigation" />
          <div className="navigation-text">
            {`If you want to view the Employee's Goals, please go to “My Work > `}
            <span className="highlighted-link" onClick={onClickTeamsGoal}>
              Team’s Goals
            </span>
            {` > Employee Goals”.\nTo view the employee’s Year End Review, please go to “My Work > `}
            <span className="highlighted-link" onClick={onClickTeamYearEnd}>
              Team’s Year End Review{" "}
            </span>
            {`> Employee’s Year End Review”.`}
          </div>
        </div>
        <div className="quarterly-checkin-manager-container quarterly-checkinEmployee">
          {commentsData.length > 0 ? (
            <>
              {commentsData.map((item: any, index: any) => {
                if (item?.allowManagerComment) {
                  return (
                    <>
                      <CommentTextArea
                        key={index?.toString()}
                        title={item?.quarter}
                        placeholder={COMMENT_AREA_MANAGER_PLACEHOLDER}
                        submitButtonText={SUBMIT_QUATERLY_CHECKIN_MANAGER_BTN}
                        onClickSubmit={() =>
                          onClickSubmit(teamGoalActiveYear, index + 1, comment)
                        }
                        comment={comment}
                        setComment={setComment}
                      />
                      <ManagerCommentCard
                        data={item}
                        employeeData={employeeData}
                        managerData={managerData}
                      />
                    </>
                  );
                }
                return null;
              })}
              {reverseCommentsData?.map((item: any, index: any) => {
                if (toStopMapping) return;
                if (item?.allowManagerComment) {
                  //toStopMapping = true;
                  return null;
                }
                return (
                  <>
                    {/* <div className="all-commentsText">{ALL_COMMENTS}</div> */}
                    <QuarterlyReviewManager
                      data={item}
                      manager={false}
                      key={index}
                      employeeData={employeeData}
                      managerData={managerData}
                    />
                  </>
                );
              })}
            </>
          ) : (
            <EmptyReviewComments />
          )}
          <ClosePopUp
            modalState={modalState}
            setModalState={setModalState}
            onHandleSubmit={() => {
              onClickPopUpSubmit();
            }}
            title={MANAGER_QUARTELY_CHECKIN_CLOSE_TITLE}
            subTitle={MANAGER_QUARTELY_CHECKIN_CLOSE_SUBTITLE}
          />
          <div className="z-index-outer-div">
            {visible && (
              <ToastMessage
                closeAction={() => setVisible(false)}
                customToastArea={
                  saveQuarterlyCheckin
                    ? "custom-toast-area skills-extracted"
                    : "custom-toast-area no-skills-extracted"
                }
                customToastLeftArea={"custom-left-area"}
                icon={saveQuarterlyCheckin ? ToastInfo : ToastError}
                toastMsgTitle={
                  saveQuarterlyCheckin
                    ? QUARTERLY_CHECKIN_INCOMPLETED
                    : COULD_NOT_SUBMIT
                }
                toastMsgPara={
                  saveQuarterlyCheckin
                    ? QUARTERLY_SUCCESSFULLY_COMPLETED
                    : UNABLE_TO_SUBMIT
                }
                customToastRightArea={"close-btn-area"}
                closeIcon={ToastClose}
              />
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default QuarterlyCheckInManager;
