import { useEffect, useState } from "react";
import { Button, Input } from "antd";
import "./Dropdown.css";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store/redux-hooks";
import { getFilters } from "../../../../../redux/actions/gamification";
import {
  setActiveAccountFilters,
  setActiveCountryFilters,
  setActiveClubFilters,
  setActiveModulePoints,
  setActiveLockedBadgesFilters,
  setActiveMyBadgesFilters,
} from "../../../../../redux/reducers/gamification";

const AccountDropdown = ({ data, onClick, module = "" }) => {
  const dispatch = useAppDispatch();
  const [checkedKeys, setCheckedKeys] = useState(new Set());
  const [search, setSearch] = useState("");

  const {
    activeFiltersPoints,
    activeModulesPoints,
    activeLockedBadges,
    activeFilterAccount,
    activeCountryFilters,
    activeClubFilters,
    myBadgesFilterData: activeMyBadgesFilter,
    badgesFilterData,
  } = useAppSelector((state: any) => state?.gamification);

  useEffect(() => {
    if (module === "Points") {
      setCheckedKeys(new Set(activeModulesPoints));
    }
    if (module === "Account") {
      setCheckedKeys(new Set(activeFilterAccount));
    }
    if (module === "Country") {
      setCheckedKeys(new Set(activeCountryFilters));
    }
    if (module === "Club") {
      setCheckedKeys(new Set(activeClubFilters));
    }
    if (module === "Locked Badges") {
      setCheckedKeys(new Set(badgesFilterData));
    }
    if (module === "Active") {
      setCheckedKeys(new Set(activeMyBadgesFilter));
    }
  }, [
    module,
    activeModulesPoints,
    activeFilterAccount,
    activeLockedBadges,
    activeClubFilters,
    activeCountryFilters,
    activeMyBadgesFilter,
    badgesFilterData,
  ]);

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  const onCheckboxChange = (itemKey: string) => {
    setCheckedKeys((prevCheckedKeys) => {
      const newCheckedKeys = new Set(prevCheckedKeys);
      if (newCheckedKeys.has(itemKey)) {
        newCheckedKeys.delete(itemKey);
      } else {
        newCheckedKeys.add(itemKey);
      }
      return newCheckedKeys;
    });
  };

  const moduleCheck = () => {
    return (
      module === "Account" ||
      module === "Points" ||
      module === "Locked Badges" ||
      module === "Active"
    );
  };

  const reset = (e) => {
    setCheckedKeys(new Set());
    if (module === "Points") dispatch(setActiveModulePoints([]));
    if (module === "Account") dispatch(setActiveAccountFilters([]));
    if (module === "Country") dispatch(setActiveCountryFilters([]));
    if (module === "Club") dispatch(setActiveClubFilters([]));
    if (module === "Locked Badges") dispatch(setActiveLockedBadgesFilters([]));
    if (module === "Active") dispatch(setActiveMyBadgesFilters([]));
    e.stopPropagation();
  };

  const apply = (e) => {
    if (module === "Points")
      dispatch(setActiveModulePoints(Array.from(checkedKeys)));
    if (module === "Account")
      dispatch(setActiveAccountFilters(Array.from(checkedKeys)));
    if (module === "Country")
      dispatch(setActiveCountryFilters(Array.from(checkedKeys)));
    if (module === "Club")
      dispatch(setActiveClubFilters(Array.from(checkedKeys)));
    if (module === "Locked Badges")
      dispatch(setActiveLockedBadgesFilters(Array.from(checkedKeys)));
    if (module === "Active")
      dispatch(setActiveMyBadgesFilters(Array.from(checkedKeys)));
  };

  return (
    <div onClick={onClick} className="dropdown_menu">
      {moduleCheck() && (
        <div
          className="search_game"
          key="search"
          onClickCapture={handleContentClick}
        >
          <Input.Search
            value={search}
            onChange={(e) => setSearch(e?.target?.value)}
            placeholder="Search"
            style={{ marginBottom: "10px" }}
          />
        </div>
      )}
      <div className="dropdown_list_items">
        <div className="dropdown_list">
          {data?.map((item) => {
            if (search?.length > 0) {
              if (item?.key?.toLowerCase()?.includes(search?.toLowerCase()))
                return (
                  <div className="dropdown_items" key={item.key}>
                    <label
                      onClick={(e) => e.stopPropagation()}
                      className="labelcheckbox"
                    >
                      <input
                        className="filtercheckbox"
                        type="checkbox"
                        onChange={(e) => {
                          onCheckboxChange(item.key);
                        }}
                        checked={checkedKeys?.has(item.key)}
                      />
                      {item.label}
                    </label>
                  </div>
                );
              else return null;
            }
            return (
              <div className="dropdown_items" key={item.key}>
                <label
                  onClick={(e) => e.stopPropagation()}
                  className="labelcheckbox"
                >
                  <input
                    className="filtercheckbox"
                    type="checkbox"
                    onChange={(e) => {
                      onCheckboxChange(item.key);
                    }}
                    checked={checkedKeys?.has(item.key)}
                  />
                  {item.label}
                </label>
              </div>
            );
          })}
        </div>
      </div>
      <div key="actions" className="dropdown-footer">
        <Button
          className="reset_button_game"
          onClick={(e) => reset(e)}
          style={{ marginRight: "8px" }}
        >
          Reset
        </Button>
        <Button
          className="apply_button_game"
          type="primary"
          onClick={(e) => apply(e)}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default AccountDropdown;
