import React from "react";

export const paginationLeftIcon = () => {
  return (
    <svg
      width="8"
      height="10"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.91645 0.332436C8.07166 0.488571 8.15878 0.699781 8.15878 0.919936C8.15878 1.14009 8.07166 1.3513 7.91645 1.50744L2.43312 6.9991L7.99979 12.5491C8.155 12.7052 8.24211 12.9164 8.24211 13.1366C8.24211 13.3568 8.155 13.568 7.99979 13.7241C7.92232 13.8022 7.83015 13.8642 7.7286 13.9065C7.62705 13.9488 7.51813 13.9706 7.40812 13.9706C7.29811 13.9706 7.18919 13.9488 7.08764 13.9065C6.98609 13.8642 6.89392 13.8022 6.81645 13.7241L0.65812 7.59077C0.580013 7.5133 0.518018 7.42113 0.47571 7.31958C0.433403 7.21803 0.411621 7.10911 0.411621 6.9991C0.411621 6.88909 0.433403 6.78017 0.47571 6.67862C0.518018 6.57707 0.580013 6.48491 0.65812 6.40744L6.73312 0.332436C6.81059 0.254329 6.90276 0.192334 7.00431 0.150027C7.10586 0.107719 7.21478 0.0859375 7.32479 0.0859375C7.4348 0.0859375 7.54372 0.107719 7.64527 0.150027C7.74682 0.192334 7.83898 0.254329 7.91645 0.332436Z"
        fill="black"
      />
    </svg>
  );
};

export const paginationRightIcon = () => {
  return (
    <svg
      width="8"
      height="10"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.86664 0.333147L7.94164 6.40815C8.01975 6.48562 8.08175 6.57778 8.12405 6.67933C8.16636 6.78088 8.18814 6.8898 8.18814 6.99981C8.18814 7.10982 8.16636 7.21875 8.12405 7.32029C8.08175 7.42184 8.01975 7.51401 7.94164 7.59148L1.80831 13.7248C1.73084 13.8029 1.63867 13.8649 1.53712 13.9072C1.43557 13.9495 1.32665 13.9713 1.21664 13.9713C1.10663 13.9713 0.997712 13.9495 0.896163 13.9072C0.794613 13.8649 0.702446 13.8029 0.624977 13.7248H0.666643C0.511434 13.5687 0.424316 13.3575 0.424316 13.1373C0.424316 12.9172 0.511434 12.7059 0.666643 12.5498L6.17498 6.99981L0.666643 1.50815C0.511434 1.35201 0.424316 1.1408 0.424316 0.920647C0.424316 0.700492 0.511434 0.489282 0.666643 0.333147C0.74438 0.252495 0.837579 0.188343 0.94067 0.144526C1.04376 0.100709 1.15463 0.078125 1.26664 0.078125C1.37866 0.078125 1.48953 0.100709 1.59262 0.144526C1.69571 0.188343 1.78891 0.252495 1.86664 0.333147Z"
        fill="black"
      />
    </svg>
  );
};
