import React, { useState } from "react";
import "./Preview.css";
import { useDispatch } from "react-redux";
import {
  useNavigate,
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useEffect } from "react";
import BreadcrumbComponent from "../common/breadcrumb/breadcrumb";
import FillButton from "../common/button/FillButton";
import {
  ADD_GOAL,
  CANCEL,
  CATEGORY_VIEW,
  CONTRIBUTE_CARD_GOAL_TXT,
  EDIT_CASCADEDGOAL_TXT,
  EDIT_GOAL,
  EDIT_GOAL_URL,
  GOAL_DESCRIPTION_VIEW,
  GOAL_METRIC_VIEW,
  GOAL_NAME_VIEW,
  GOAL_OUTCOME_VIEW,
  GOAL_START_AND_END_DATE_VIEW,
  GOAL_VISIBILITY_VIEW,
  GOAL_WEIGHT_VIEW,
  PERFORMANCE,
  PERFORMANCE_BREADCRUMB,
  QUESTION_DTO,
  SEND,
  VIEW_GOAL,
} from "../../constants";
import OutlineButton from "../common/button/OutlineButton";
import {
  PREVIEW_MAIN_TITLE,
  COMMENTS_MANAGER_TITLE,
  REMAINING_TXT,
} from "../../constants";
import SubHeader from "../SubHeader/SubHeader";
import tempMyWorkData from "../MyWork/MyworkSubheaderData";
import { Button, Card, Spin } from "antd";
import n from "../navigation/Route.Names";
import CommentDialog from "./components/CommentDialog";
import { Input } from "antd";
import {
  CLOSE,
  COMMENTS_MANAGER,
  END_DATE,
  PREVIEW_COMMENT_TITLE,
  VIEW_COMMENT_TITLE,
  NEW_GOAL_BREAD_TXT,
} from "../../constants";

import { useAppDispatch, useAppSelector } from "../../redux/store/redux-hooks";
import GoalMilestoneCompoenent from "./form_component/GoalMilestoneComponent";
import {
  ViewGoalAction,
  getGoalTemplate,
  saveGoal,
  saveGoalComment,
} from "../../redux/actions/performance";
import { camelCase, cloneDeep, isEmpty, startCase } from "lodash";
import AccessTime from "../../themes/assets/images/svg/front/active_access_time.svg";
import Success from "../../themes/assets/images/svg/front/Success.svg";
import AccessTimeGrey from "../../themes/assets/images/svg/front/access_time.svg";
import Milestones from "./Milestones";
import moment from "moment";
import { getLoggedInUserData, goalVisibility, goalWeight } from "../utils/util";
import amplitude from "amplitude-js";

const { TextArea } = Input;
const ViewGoal = () => {
  const navigate = useNavigate();
  const param = useParams();
  const dispatch = useAppDispatch();
  const {
    goalValues,
    viewGoal: goalData,
    isLoading,
    viewCascade,
  } = useAppSelector((state: any) => state?.performance);
  const [comment, setComment] = useState(goalValues?.commentInfo || null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const paramGoalId = param.goalId;
  const year = param.year;
  const isVisibleViewGoal = !paramGoalId?.includes("public");
  const [searchParams] = useSearchParams();
  const cascadeStatus = searchParams.get("cascadedGoal") === "true";

  useEffect(() => {
    dispatch(ViewGoalAction({ year: year, goalId: paramGoalId }));
  }, [paramGoalId, year]);

  const fillBtnAction = () => {
    amplitude.getInstance().logEvent("Clicked Add Goal");
    const baseUrl = `/${n.PERFORMANCE_URL}/${EDIT_GOAL_URL}/${year}/${paramGoalId}`;
    const url = cascadeStatus ? `${baseUrl}?cascadedGoal=true` : baseUrl;
    navigate(url);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const cancelBtn = (value: object) => {
    if (paramGoalId) {
      navigate(`/${n.PERFORMANCE_URL}/${n.CHALLENG_GOAL_URL}`);
    } else {
      navigate(`/${n.PERFORMANCE_URL}/${n.NEW_GOAL_URL}/${year}`);
    }
  };
  const handleSaveComment = () => {
    setIsModalOpen(false);
  };
  const items = isVisibleViewGoal
    ? [
        {
          title: (
            <Link to={`/${n.PERFORMANCE_URL}`} className="link-highlight">
              {PERFORMANCE_BREADCRUMB}
            </Link>
          ),
        },
        {
          title: "View Goal",
        },
      ]
    : [
        {
          title: (
            <Link to={`/${n.PERFORMANCE_URL}`} className="link-highlight">
              {PERFORMANCE_BREADCRUMB}
            </Link>
          ),
        },
        {
          title: (
            <Link
              to={`/${n.PERFORMANCE_URL}/${n.CHALLENG_GOAL_URL}`}
              className="link-highlight"
            >
              {CONTRIBUTE_CARD_GOAL_TXT}
            </Link>
          ),
        },
        {
          title: "View Goal",
        },
      ];
  const CascadeItems = [
    {
      title: (
        <Link to={`/${n.PERFORMANCE_URL}`} className="link-highlight">
          {PERFORMANCE_BREADCRUMB}
        </Link>
      ),
    },
    {
      title: (
        <Link
          to={`/${n.PERFORMANCE_URL}/${EDIT_GOAL_URL}/${year}/${paramGoalId}?cascadedGoal=true`}
          className="link-highlight"
        >
          {EDIT_CASCADEDGOAL_TXT}
        </Link>
      ),
    },
    {
      title: "View Goal",
    },
  ];

  const commentView = (goal) => {
    setIsModalOpen(true);
    amplitude.getInstance().logEvent("View Comment Employee");
  };
  return (
    <>
      <Spin spinning={isLoading}>
        <div className="contribute_view-otr">
          <div className="container">
            <div className="breadcrumb_preview">
              <BreadcrumbComponent items={viewCascade ? CascadeItems : items} />
            </div>
            <div className="goal-preview-header">
              <div className="goal-preview-title">
                <h3 className="preview-main-title">{VIEW_GOAL}</h3>
              </div>
              {goalData?.commentInfo && (
                <Button
                  className="viewcomment view-page-comment-btn"
                  onClick={commentView}
                >
                  {VIEW_COMMENT_TITLE}
                </Button>
              )}
            </div>

            <div className="preview-goal-main">
              <div className="preview-goal-sub">
                <div className="row-section">
                  <div className="view-status-row">
                    <div className="row-section">
                      <h5>{CATEGORY_VIEW}</h5>
                      <p>{goalData?.category || goalData?.GOAL_QUESTION_1}</p>
                    </div>
                    <div
                      className={"status-section completed-bg"}
                      style={{
                        backgroundColor: goalData?.goalStatusColor,
                      }}
                    >
                      <p
                        style={{
                          color: goalData?.goalTextColor,
                        }}
                      >
                        {goalData?.goalStatus || goalData?.GOAL_QUESTION_11}
                      </p>
                    </div>
                  </div>
                  <div className="row-section">
                    <h5>{GOAL_NAME_VIEW}</h5>
                    <p>{goalData?.goalName || goalData?.GOAL_QUESTION_2}</p>
                  </div>
                  <div className="row-section">
                    <h5>{GOAL_DESCRIPTION_VIEW}</h5>
                    <p>
                      {goalData?.goalDescription || goalData?.GOAL_QUESTION_3}
                    </p>
                  </div>
                  <div className="row-section">
                    <h5>{GOAL_OUTCOME_VIEW}</h5>
                    <p>{goalData?.outcome || goalData?.QUESTION_4}</p>
                  </div>
                  <div className="row-section">
                    <h5>{GOAL_METRIC_VIEW}</h5>
                    <p>{goalData?.metric || goalData?.GOAL_QUESTION_5}</p>
                  </div>
                  <div className="row-section">
                    <h5>{GOAL_WEIGHT_VIEW}</h5>
                    <p>{goalWeight(goalData) || "-"}</p>
                  </div>
                  <div className="row-section">
                    <h5>{GOAL_VISIBILITY_VIEW}</h5>
                    <p>{goalVisibility(goalData)}</p>
                  </div>
                  <div className="row-section">
                    <h5>{GOAL_START_AND_END_DATE_VIEW}</h5>
                    <p>
                      {goalData?.startDate || goalData?.GOAL_QUESTION_7} -{" "}
                      {goalData?.endDate || goalData?.GOAL_QUESTION_8}
                    </p>
                  </div>
                  {isVisibleViewGoal ? (
                    <Milestones
                      milestones={
                        goalData?.milestones || goalData?.GOAL_QUESTION_10
                      }
                      isEdit={false}
                      questionDTO={QUESTION_DTO}
                    />
                  ) : (
                    <Milestones isEdit={false} questionDTO={QUESTION_DTO} />
                  )}
                  <div
                    className={
                      isVisibleViewGoal
                        ? "button-area edit-btn-view single-btn"
                        : "button-area edit-btn-view"
                    }
                  >
                    {!isVisibleViewGoal && (
                      <OutlineButton
                        outlineBtnStyles="outlineBtnStyles"
                        backText={CANCEL}
                        outlineBtnAction={cancelBtn}
                      />
                    )}
                    <FillButton
                      fillBtnStyles="fillBtnStyles"
                      fillBtnTxt={EDIT_GOAL}
                      fillBtnAction={fillBtnAction}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
      <CommentDialog
        openModal={isModalOpen}
        successHeading={PERFORMANCE}
        DialogHeading={VIEW_COMMENT_TITLE}
        closeIcon={true}
        goalValues={goalData}
        modalImageStyles={{ display: "none" }}
        msgContent={[
          <React.Fragment key="comment">
            <div>{COMMENTS_MANAGER_TITLE}</div>
            <TextArea
              rows={4}
              disabled={false} //viewer
              placeholder={goalData?.commentInfo?.comment}
              readOnly
              value={goalData?.commentInfo?.comment}
              onChange={(e) => {
                e.target.value?.length <= 300 &&
                  setComment(e.target.value.replace(/\n/g, ""));
              }}
            />
          </React.Fragment>,
        ]}
        okTxt={CLOSE}
        okBtn={handleSaveComment}
        onOk={handleSaveComment}
        onCancel={handleCancel}
        modalSubHeading={"commentheading"}
        modalStyles={"commentdialog"}
        modalContent={"commentview"}
        maskClosable={false}
      />
    </>
  );
};

export default ViewGoal;
