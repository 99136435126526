import React, { useEffect, useState } from "react";
import "./LeaderSearch.css";
import searchIcon from "../../../../../themes/assets/images/searchIcon.png";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store/redux-hooks";
import { getFilters } from "../../../../../redux/actions/gamification";
import upArrow from "../../../../../themes/assets/images/upArrow.png";
import downArrow from "../../../../../themes/assets/images/downArrow.png";
import searchBarIcon from "../../../../../themes/assets/images/searchBarIcon.png";
import checkedBox from "../../../../../themes/assets/images/checkedBox.png";
import unCheckedBox from "../../../../../themes/assets/images/unCheckedBox.png";

import { Popover } from "antd";
import { setLeaderBoardFilter } from "../../../../../redux/reducers/gamification";
import { debounce } from "lodash";
import {
  ACCOUNT,
  APPLY_BUTTON,
  CLEAR_ALL,
  CLUB,
  COUNTRY,
  DEBOUNCE_TIME,
  LOCATION,
  RESET,
  SEARCH,
  SELECT_ALL,
} from "../../../../../constants";

const FilterItemChild = ({
  data,
  onClick,
  location,
  selectAllActive = false,
}) => {
  const [checkedItem, setCheckedItem] = useState(false);
  const { leaderBoardFilterBody } = useAppSelector(
    (state: any) => state?.gamification
  );

  const onClickChildItem = () => {
    setCheckedItem(!checkedItem);
    onClick(data, checkedItem);
  };
  const checkIfSelected = (item: any) => {
    return leaderBoardFilterBody?.location?.includes(item);
  };

  useEffect(() => {
    setCheckedItem(checkIfSelected(data));
  }, [leaderBoardFilterBody]);

  useEffect(() => {
    data !== SELECT_ALL && setCheckedItem(location?.includes(data));
    data === SELECT_ALL && setCheckedItem(selectAllActive);
  }, [location, selectAllActive]);

  return (
    <div className="filter-item">
      <img
        className="check-box"
        onClick={onClickChildItem}
        src={checkedItem ? checkedBox : unCheckedBox}
      />
      <div className="check-box-text">{data}</div>
    </div>
  );
};

const FilterItem = ({
  data,
  onClickCheckBox,
  account,
  childItems,
  onClickChildItem,
  location,
  setLocation,
  accounts,
  clubs,
  country,
}) => {
  const { leaderBoardFilterBody } = useAppSelector(
    (state: any) => state?.gamification
  );
  const [checked, setChecked] = useState(false);
  const [expand, setExpand] = useState(false);
  const [selectAll, setSelectAll] = useState<any>(false);

  const checkIfSelected = (item: any) => {
    if (account === ACCOUNT) {
      return accounts?.includes(item);
    } else if (account === COUNTRY) {
      return country?.includes(item);
    } else if (account === CLUB) {
      return clubs?.includes(item);
    }
  };

  const onClickSelectAll = () => {
    if (childItems?.length > 0) {
      let tempData: any = [...location, ...childItems];
      tempData = new Set(tempData);
      setLocation(Array.from(tempData));
    }
    setSelectAll(!selectAll);
  };

  const onClickItem = () => {
    setChecked(!checked);
    onClickCheckBox(data, checked);
    !expand && !checked && onClickExpand();
  };

  const onClickExpand = () => {
    setExpand(!expand);
  };

  useEffect(() => {
    setChecked(checkIfSelected(data));
  }, [accounts, country, clubs]);

  return (
    <>
      <div className="filter-item">
        <img
          className="check-box"
          onClick={onClickItem}
          src={checked ? checkedBox : unCheckedBox}
        />
        <div className="check-box-text">{data}</div>
        {childItems?.length > 0 && (
          <div className="expand-holder">
            <img
              onClick={onClickExpand}
              src={expand ? downArrow : upArrow}
              className="expand-filter"
            />
          </div>
        )}
      </div>
      {expand && childItems?.length > 0 && (
        <div className="filterChildItems">
          <FilterItemChild
            data={SELECT_ALL}
            onClick={onClickSelectAll}
            location={location}
            selectAllActive={selectAll}
          />

          {childItems?.map((item: any, index: any) => {
            return (
              <FilterItemChild
                data={item}
                key={index}
                onClick={onClickChildItem}
                location={location}
                selectAllActive={selectAll}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

const FilterButton = ({ data }) => {
  const dispatch = useAppDispatch();

  const { leaderBoardFilterBody } = useAppSelector(
    (state: any) => state?.gamification
  );

  const [showFilter, setShowFilter] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [applyActive, setApplyActive] = useState(false);

  const [accounts, setAccounts] = useState<any>([]);
  const [country, setCountry] = useState<any>([]);
  const [clubs, setClubs] = useState<any>([]);
  const [location, setLocation] = useState<any>([]);

  const onClickFilter = () => {
    setShowFilter(!showFilter);
  };
  const handleOpenChange = (newOpen: boolean) => {
    setShowFilter(newOpen);
  };

  const onClickReset = () => {
    if (data?.code === ACCOUNT) {
      dispatch(
        setLeaderBoardFilter({
          account: [],
          country: leaderBoardFilterBody?.country,
          location: leaderBoardFilterBody?.location,
          clubs: leaderBoardFilterBody?.clubs,
          key: leaderBoardFilterBody?.key,
        })
      );
    } else if (data?.code === COUNTRY) {
      dispatch(
        setLeaderBoardFilter({
          account: leaderBoardFilterBody?.account,
          country: [],
          location: [],
          clubs: leaderBoardFilterBody?.clubs,
          key: leaderBoardFilterBody?.key,
        })
      );
    } else if (data?.code === CLUB) {
      dispatch(
        setLeaderBoardFilter({
          account: leaderBoardFilterBody?.account,
          country: leaderBoardFilterBody?.country,
          location: leaderBoardFilterBody?.location,
          clubs: [],
          key: leaderBoardFilterBody?.key,
        })
      );
    }
  };

  const onClickApply = () => {
    if (data?.code === ACCOUNT) {
      dispatch(
        setLeaderBoardFilter({
          account: [...accounts],
          country: leaderBoardFilterBody?.country,
          location: leaderBoardFilterBody?.location,
          clubs: leaderBoardFilterBody?.clubs,
          key: leaderBoardFilterBody?.key,
        })
      );
    } else if (data?.code === COUNTRY) {
      dispatch(
        setLeaderBoardFilter({
          country: [...country],
          location: [...location],
          account: leaderBoardFilterBody?.account,
          clubs: leaderBoardFilterBody?.clubs,
          key: leaderBoardFilterBody?.key,
        })
      );
    } else if (data?.code === CLUB) {
      dispatch(
        setLeaderBoardFilter({
          clubs: [...clubs],
          account: leaderBoardFilterBody?.account,
          country: leaderBoardFilterBody?.country,
          location: leaderBoardFilterBody?.location,
          key: leaderBoardFilterBody?.key,
        })
      );
    }
    setShowFilter(false);
  };

  const onClickChildItem = (item, checkedItem) => {
    let tempData: any = [...location, item];
    if (checkedItem) {
      tempData = tempData?.filter((el: any) => item !== el);
    }
    tempData = new Set(tempData);
    setLocation(Array.from(tempData));
  };

  const removeElementFromArray = (el: any, array: any) => {
    let tempArray = array?.filter((item: any) => {
      return el !== item;
    });
    return tempArray;
  };

  const onClickCheckBox = (item: any, check: boolean) => {
    if (data?.code === ACCOUNT) {
      if (!check) setAccounts([...accounts, item]);
      else setAccounts(removeElementFromArray(item, accounts));
    } else if (data?.code === COUNTRY) {
      if (!check) setCountry([...country, item]);
      else setCountry(removeElementFromArray(item, country));
    } else if (data?.code === CLUB) {
      if (!check) setClubs([...clubs, item]);
      else setClubs(removeElementFromArray(item, clubs));
    }
  };

  const getActiveFilter = () => {
    if (data?.code === ACCOUNT) {
      return leaderBoardFilterBody?.account?.length;
    } else if (data?.code === COUNTRY) {
      if (leaderBoardFilterBody?.country?.length > 0)
        return leaderBoardFilterBody?.country?.length;
      else return leaderBoardFilterBody?.location?.length;
    } else if (data?.code === CLUB) {
      return leaderBoardFilterBody?.clubs?.length;
    }
  };

  useEffect(() => {
    setAccounts(leaderBoardFilterBody?.account);
    setClubs(leaderBoardFilterBody?.clubs);
    setLocation(leaderBoardFilterBody?.location);
    setCountry(leaderBoardFilterBody?.country);
  }, [leaderBoardFilterBody, showFilter]);

  useEffect(() => {
    if (
      data?.code === ACCOUNT &&
      (leaderBoardFilterBody?.account?.length > 0 || accounts?.length > 0)
    ) {
      setApplyActive(true);
    } else if (
      data?.code === COUNTRY &&
      (leaderBoardFilterBody?.country?.length > 0 || country?.length > 0)
    ) {
      setApplyActive(true);
    } else if (
      data?.code === COUNTRY &&
      (leaderBoardFilterBody?.location?.length > 0 || location?.length > 0)
    ) {
      setApplyActive(true);
    } else if (
      data?.code === CLUB &&
      (leaderBoardFilterBody?.clubs?.length > 0 || clubs?.length > 0)
    ) {
      setApplyActive(true);
    } else {
      setApplyActive(false);
    }
  }, [data?.code, leaderBoardFilterBody, accounts, location, clubs, country]);

  const content = (
    <div className="filter-data-container-leader">
      {data?.code === ACCOUNT && (
        <div className="filter-data-title">{data?.code}</div>
      )}
      {data?.code === ACCOUNT && (
        <div className="search-bar-filter">
          <input
            value={searchData}
            onChange={(e) => setSearchData(e?.target?.value)}
            placeholder={SEARCH}
            className="input-search-filter"
          />
          <img src={searchBarIcon} className="searchBar-icon" />
        </div>
      )}
      <div className="filter-items-container">
        {data?.options?.map((item, index) => {
          if (!searchData)
            return (
              <FilterItem
                account={data?.code}
                data={item}
                onClickCheckBox={onClickCheckBox}
                childItems={
                  data?.optionsMap?.[item] ? data?.optionsMap?.[item] : []
                }
                onClickChildItem={onClickChildItem}
                location={location}
                setLocation={setLocation}
                accounts={accounts}
                clubs={clubs}
                country={country}
              />
            );
          else if (item?.includes(searchData))
            return (
              <FilterItem
                account={data?.code}
                data={item}
                onClickCheckBox={onClickCheckBox}
                childItems={
                  data?.optionsMap?.[item] ? data?.optionsMap?.[item] : []
                }
                onClickChildItem={onClickChildItem}
                location={location}
                setLocation={setLocation}
                accounts={accounts}
                clubs={clubs}
                country={country}
              />
            );
          else return null;
        })}
      </div>
      <div className="filter-buttons">
        <div
          onClick={applyActive ? onClickReset : () => {}}
          className={applyActive ? "reset-btn" : "reset-btn reset-btn-inactive"}
        >
          {data?.code === ACCOUNT ? RESET : CLEAR_ALL}
        </div>
        <div
          onClick={
            applyActive
              ? onClickApply
              : () => {
                  setShowFilter(false);
                }
          }
          className={applyActive ? "apply-btn apply-btn-active" : "apply-btn"}
        >
          {APPLY_BUTTON}
        </div>
      </div>
    </div>
  );

  return (
    <div className="filter-button-container">
      <Popover
        open={showFilter}
        arrow={false}
        content={content}
        placement="bottomRight"
        trigger={"click"}
        onOpenChange={handleOpenChange}
      >
        <div
          onClick={onClickFilter}
          className={
            showFilter
              ? "filter-button filter-opened"
              : getActiveFilter() > 0
              ? "filter-button filter-active-color"
              : "filter-button"
          }
        >
          {data?.code}
          {getActiveFilter() > 0 && `  (${getActiveFilter()})`}
          <img src={showFilter ? upArrow : downArrow} className="arrow" />
        </div>
      </Popover>
    </div>
  );
};

const Leadersearch = () => {
  const dispatch = useAppDispatch();
  const [activeFilters, setActiveFilters] = useState(false);
  const { filterData, leaderBoardFilterBody } = useAppSelector(
    (state: any) => state?.gamification
  );

  const onClickClearAll = () => {
    dispatch(
      setLeaderBoardFilter({
        account: [],
        country: [],
        location: [],
        clubs: [],
        key: "",
      })
    );
  };

  const debouncedSearch = (e) => {
    dispatch(
      setLeaderBoardFilter({
        account: leaderBoardFilterBody?.account,
        country: leaderBoardFilterBody?.country,
        location: leaderBoardFilterBody?.location,
        clubs: leaderBoardFilterBody?.clubs,
        key: e?.target?.value,
      })
    );
  };

  const searchFunc: any = debounce(debouncedSearch, DEBOUNCE_TIME);

  useEffect(() => {
    if (
      leaderBoardFilterBody?.key?.length > 0 ||
      leaderBoardFilterBody?.account?.length > 0 ||
      leaderBoardFilterBody?.location?.length > 0 ||
      leaderBoardFilterBody?.country?.length > 0 ||
      leaderBoardFilterBody?.clubs?.length > 0
    ) {
      onClickClearAll();
    }
  }, []);

  useEffect(() => {
    if (
      leaderBoardFilterBody?.account?.length > 0 ||
      leaderBoardFilterBody?.country?.length ||
      leaderBoardFilterBody?.clubs?.length > 0 ||
      leaderBoardFilterBody?.location?.length > 0 ||
      leaderBoardFilterBody?.key?.length > 0
    ) {
      setActiveFilters(true);
    }
  }, [leaderBoardFilterBody]);

  return (
    <div className="leader-filter-container">
      <div className="searchBarContainer">
        <img src={searchIcon} className="search-icon" />
        <input
          className="search-input-leader"
          onChange={searchFunc}
          placeholder={SEARCH}
        />
      </div>
      <div className="filter-container">
        {filterData?.data?.map((item: any, index: any) => {
          return <FilterButton data={item} key={index} />;
        })}
        <div
          onClick={activeFilters ? onClickClearAll : () => {}}
          className={
            activeFilters
              ? "clear-all-btn clear-all-btn-active"
              : "clear-all-btn"
          }
        >
          {CLEAR_ALL}
        </div>
      </div>
    </div>
  );
};

export default Leadersearch;
